import { AxiosResponse } from 'axios'
import { $api } from './authApi'

export function getCharacters(): Promise<AxiosResponse<any>> {
    return $api.get('/characters/')
}
export function postLikeDislike(character_id: string, action: string): Promise<AxiosResponse<any>> {
    return $api.post(`/characters/${character_id}/${action}`)
}
export function getChatsApi(): Promise<AxiosResponse<any>> {
    return $api.get('/chats/')
}
export function getMessageApi(character_id: string): Promise<AxiosResponse<any>> {
    return $api.get(`/chats/${character_id}/messages/`)
}
export function postMessageApi(character_id: string, message_id: string): Promise<AxiosResponse<any>> {
    return $api.post(`/chats/${character_id}/messages/`, { message_id })
}
export function postMessageResourcesApi(
    character_id: string,
    message_id: string,
    resource_id: string,
): Promise<AxiosResponse<any>> {
    return $api.post(`/chats/${character_id}/messages/${message_id}/resources/`, { resource_id })
}
export function postMessageDebugApi(character_id: string, message_id: string): Promise<AxiosResponse<any>> {
    return $api.post(`/debug/${character_id}/set-chat-message`, { message_id })
}
export function postMessageDateCreateApi(character_id: string): Promise<AxiosResponse<any>> {
    return $api.post(`/dates/`, { character_id })
}
export function getMessagesDateApi(character_id: string): Promise<AxiosResponse<any>> {
    return $api.get(`/dates/${character_id}/messages/`)
}
export function postMessageDateApi(character_id: string, message_id: string): Promise<AxiosResponse<any>> {
    return $api.post(`/dates/${character_id}/messages/`, { message_id })
}
export function getMessageDatePriceApi(character_id: string): Promise<AxiosResponse<any>> {
    return $api.get(`/dates/${character_id}/price/`)
}
export function getImageApi(character_id: string): Promise<AxiosResponse<any>> {
    return $api.get(`/chats/${character_id}/resources/`)
}
export function getChatPrice(character_id: string): Promise<AxiosResponse<any>> {
    return $api.get(`/chats/${character_id}/price`)
}
export function getSkipTimeOut(character_id: string): Promise<AxiosResponse<any>> {
    return $api.post(`/chats/${character_id}/skip-timeout`)
}
export function getItemsXsolla(): Promise<AxiosResponse<any>> {
    return $api.get(`/xsolla/items/`)
}
export function getItemsSubscriptions(): Promise<AxiosResponse<any>> {
    return $api.get(`/xsolla/subscriptions/`)
}

export function getOrderPay(order_id: string): Promise<AxiosResponse<any>> {
    return $api.get(`/xsolla/orders/${order_id}`)
}
export function postSkip(skip: string): Promise<AxiosResponse<any>> {
    return $api.post(`/characters/skip-timeout`, { skip: skip })
}
export function postItemsXsolla(id: string): Promise<AxiosResponse<any>> {
    return $api.post(`/xsolla/orders/`, {
        sku: id,
        currency: 'USD',
        language: 'en',
        sandbox: window.location.origin === 'https://loverzgame.com' ? false : true,
        ui: {
            size: 'small',
            version: 'mobile',
            theme: 'default_dark',
        },
    })
}
export function postItemsXsollaSub(id: string): Promise<AxiosResponse<any>> {
    return $api.post(`/xsolla/subscriptions/`, {
        plan_id: id,
        currency: 'USD',
        language: 'en',
        sandbox: window.location.origin === 'https://loverzgame.com' ? false : true,
        ui: {
            size: 'small',
            version: 'mobile',
            theme: 'default_dark',
        },
    })
}
