import styled from 'styled-components/macro'
import '@fontsource/roboto/900.css'

export const Button = styled.div`
    width: 100%;
    border: none;
    outline: none;
    position: relative;
    padding: 0;
    :hover {
        transform: scale(1.05);
    }
    cursor: pointer;
    background: none;
    img {
        max-width: 100%;
    }

    span {
        flex: 1;
        display: flex;
    }
    span.text {
        justify-content: center;
        color: #fff !important;
        padding-right: 22px;
        text-shadow:
            -0 -2px 0 #55a440,
            0 -2px 0 #55a440,
            -0 2px 0 #55a440,
            0 2px 0 #55a440,
            -2px -0 0 #55a440,
            2px -0 0 #55a440,
            -2px 0 0 #55a440,
            2px 0 0 #55a440,
            -1px -2px 0 #55a440,
            1px -2px 0 #55a440,
            -1px 2px 0 #55a440,
            1px 2px 0 #55a440,
            -2px -1px 0 #55a440,
            2px -1px 0 #55a440,
            -2px 1px 0 #55a440,
            2px 1px 0 #55a440,
            -2px -2px 0 #55a440,
            2px -2px 0 #55a440,
            -2px 2px 0 #55a440,
            2px 2px 0 #55a440,
            -2px -2px 0 #55a440,
            2px -2px 0 #55a440,
            -2px 2px 0 #55a440,
            2px 2px 0 #55a440;
        @media (max-width: 768px) {
            padding-right: 10px;
            text-shadow:
                -0 -1px 0 #55a440,
                0 -1px 0 #55a440,
                -0 1px 0 #55a440,
                0 1px 0 #55a440,
                -1px -0 0 #55a440,
                1px -0 0 #55a440,
                -1px 0 0 #55a440,
                1px 0 0 #55a440,
                -1px -1px 0 #55a440,
                1px -1px 0 #55a440,
                -1px 1px 0 #55a440,
                1px 1px 0 #55a440,
                -1px -1px 0 #55a440,
                1px -1px 0 #55a440,
                -1px 1px 0 #55a440,
                1px 1px 0 #55a440;
        }
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
    }
    span:first-child,
    span:first-child img {
        text-align: start;
        max-width: 40%;
    }

    span:last-child,
    span:last-child img {
        text-align: end;
        max-width: 24%;
        align-items: center;
        justify-content: right;
    }
    span.text:first-child {
        max-width: 100%;
        padding-right: 0;
        justify-content: center;
    }
    div {
        position: absolute;
        top: 0px;
        display: flex;
        flex: 1;
        height: 80%;
        padding: 0pt 16px;
        align-items: center;
        justify-content: space-between;
        left: 0;
        right: 0;
    }
`
