import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    getChatPrice,
    getChatsApi,
    getImageApi,
    getMessageApi,
    getMessagesDateApi,
    getMessageDatePriceApi,
    getSkipTimeOut,
    postMessageApi,
    postMessageDateApi,
    postMessageDateCreateApi,
    postMessageDebugApi,
    postMessageResourcesApi,
} from '../../api/swipeApi'
import { RootState } from '../store'

export interface ChatsState {
    chats: any
    chat: any
    chatStatus: any
    chatIdMessageNext: any
    dateMessages: any
    chatIdDateMessageNext: any
    chatImages: any
    status: boolean
    wait: number
    level: boolean
    levelNext: boolean
    loadApp: boolean
    macthStatusDate: boolean
    relationshipProgress: number
    prices: any
    datePrices: any
    skipTime: any
    chapterNumber: number
    resChat: any
    canvasOpen: boolean
    lastReadDateMessage: any
    date: any
}
export const getChats = createAsyncThunk('chat/getChats', async () => {
    const response = await getChatsApi()
    return response.data
})
export const getChat = createAsyncThunk('chat/getChat', async (id: string) => {
    const response = await getMessageApi(id)
    return response.data
})

export const postChatId = createAsyncThunk('chat/postChatId', async (data: any) => {
    const response = await postMessageApi(data.id, data.idMessage)
    return response.data
})

export const postChatResourcesId = createAsyncThunk('chat/postChatResourcesId', async (data: any) => {
    const response = await postMessageResourcesApi(data.id, data.message_id, data.idResources)
    let res = { ...response.data, url2: data.url }
    return res
})
export const postChatDebug = createAsyncThunk('chat/postChatDebug', async (data: any) => {
    const response = await postMessageDebugApi(data.id, data.idMessage)
    return response.data
})
export const postChatDateCreate = createAsyncThunk('chat/postChatDateCreate', async (data: any) => {
    const response = await postMessageDateCreateApi(data)
    return response.data
})

export const getChatPriceDate = createAsyncThunk('chat/getChatPriceDate', async (data: any) => {
    const response = await getChatPrice(data)
    return response.data
})
export const getMessageDatePrice = createAsyncThunk('chat/getChatMessageDatePriceApi', async (data: any) => {
    const response = await getMessageDatePriceApi(data)
    return response.data
})

export const getSkipTimeOutDate = createAsyncThunk('chat/getSkipTimeOutDate', async (data: any) => {
    const response = await getSkipTimeOut(data)
    return response.data
})
export const getMessagesDate = createAsyncThunk('chat/getChatDate', async (data: any) => {
    const response = await getMessagesDateApi(data)
    return response.data
})
export const postChatDate = createAsyncThunk('chat/postChatDate', async (data: any) => {
    const response = await postMessageDateApi(data.id, data.idMessage)
    return response.data
})

export const getChatImages = createAsyncThunk('chat/getChatImages', async (data: any) => {
    const response = await getImageApi(data)
    return response.data
})

const initialState: ChatsState = {
    chats: [],
    chat: null,
    chatStatus: null,
    chatIdMessageNext: null,
    dateMessages: null,
    macthStatusDate: false,
    chatIdDateMessageNext: null,
    chatImages: null,
    wait: 0,
    status: false,
    level: false,
    levelNext: false,
    prices: null,
    datePrices: null,
    loadApp: true,
    skipTime: null,
    relationshipProgress: 0,
    chapterNumber: 1,
    resChat: null,
    canvasOpen: false,
    lastReadDateMessage: null,
    date: null,
}

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setChat: (state, action) => {
            state.chat = action.payload
        },
        clearChat: (state) => {
            state.chats = []
            state.chatStatus = null
            state.chatIdMessageNext = null
        },
        setLevel: (state, action) => {
            state.level = action.payload
        },
        setLevelNext: (state, action) => {
            state.levelNext = action.payload
        },
        setRelationshipProgress: (state, action) => {
            state.relationshipProgress = action.payload.relationshipProgress
            state.chapterNumber = action.payload.chapterNumber
        },

        setCanvasOpen: (state, action) => {
            state.canvasOpen = action.payload
        },
        setChatIdDateMessageClear: (state) => {
            state.dateMessages = null
        },
        setImages: (state, action) => {
            state.chatImages = action.payload
        },
        setLastReadDateMessage: (state, action) => {
            state.lastReadDateMessage = action.payload
        },
    },
    extraReducers(builder) {
        builder.addCase(getChats.fulfilled, (state, action) => {
            state.chats = action.payload
        })
        builder.addCase(getChats.pending, () => {})
        builder.addCase(getChat.fulfilled, (state, action) => {
            state.chatStatus = action.payload
            state.wait = action.payload.wait
            state.chatIdMessageNext = null
        })
        builder.addCase(postChatId.fulfilled, (state, action) => {
            state.chatIdMessageNext = action.payload
            state.wait = action.payload.wait
        })
        builder.addCase(postChatResourcesId.fulfilled, (state, action) => {
            state.resChat = action.payload
        })
        builder.addCase(postChatDateCreate.fulfilled, (state, action) => {
            state.lastReadDateMessage = action.payload.state
            state.date = action.payload
        })
        builder.addCase(getMessagesDate.fulfilled, (state, action) => {
            state.dateMessages = action.payload
            state.chatIdDateMessageNext = null
            //state.macthStatusDate = true
        })
        builder.addCase(postChatDate.fulfilled, (state, action) => {
            state.dateMessages = action.payload
        })
        builder.addCase(getChatImages.fulfilled, (state, action) => {
            state.chatImages = action.payload
        })
        builder.addCase(getMessageDatePrice.fulfilled, (state, action) => {
            state.datePrices = action.payload
        })

        builder.addCase(getChatPriceDate.fulfilled, (state, action) => {
            state.prices = action.payload
        })
        builder.addCase(getSkipTimeOutDate.fulfilled, (state, action) => {
            state.skipTime = action.payload
        })
    },
})

export const {
    setChat,
    clearChat,
    setLevel,
    setLevelNext,
    setChatIdDateMessageClear,
    setLastReadDateMessage,
    setRelationshipProgress,
    setCanvasOpen,
    setImages,
} = chatSlice.actions

export const selectChats = (state: RootState) => state.chatSlice.chats
export const selectChatStatus = (state: RootState) => state.chatSlice.chatStatus
export const selectChat = (state: RootState) => state.chatSlice.chat
export const selectWait = (state: RootState) => state.chatSlice.wait
export const selectPrices = (state: RootState) => state.chatSlice.prices
export const selectDatePrices = (state: RootState) => state.chatSlice.datePrices
export const selectChatImages = (state: RootState) => state.chatSlice.chatImages
export const imageDateCanvas = (state: RootState) => state.chatSlice.resChat
export const selectCanvasOpen = (state: RootState) => state.chatSlice.canvasOpen
export const chatLoadApp = (state: RootState) => state.chatSlice.loadApp
export const selectDate = (state: RootState) => state.chatSlice.date
export const selectDateMessages = (state: RootState) => state.chatSlice.dateMessages
export const selectLastReadDateMessage = (state: RootState) => state.chatSlice.lastReadDateMessage
export const chatIdMessageDateNext = (state: RootState) => state.chatSlice.chatIdDateMessageNext
export const chatIdMessageNext = (state: RootState) => state.chatSlice.chatIdMessageNext
export const selectLevel = (state: RootState) => state.chatSlice.level
export const selectLevelNext = (state: RootState) => state.chatSlice.levelNext

export const selectRelationshipProgress = (state: RootState) => state.chatSlice.relationshipProgress
export const selectChapterNumber = (state: RootState) => state.chatSlice.chapterNumber
export default chatSlice.reducer
