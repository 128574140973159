import styled from 'styled-components/macro'

export const BBInfoCoin = styled.div`
    max-width: 533px;
    width: 100%;
    padding: 24px;
    pointer-events: none;
    position: absolute;
    top: 20%;
    box-sizing: border-box;
    z-index: 10000;
`
export const BlockInfoCoin = styled.div`
    position: absolute;
    right: 0px;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    background: rgb(0 16 89 / 51%);
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 23px;
    color: white;

    border: 2px solid rgb(255 181 0);
    border-radius: 24px;
    z-index: 1000;
    padding: 1px 18px 1px 42px;
`
export const BlockTextInfo = styled.div``
export const ImageInfo = styled.img`
    left: -10%;
    position: absolute;
    max-width: 40px;
    bottom: -5px;
    @media (max-width: 768px) {
        max-width: 40px;
    }
`
