import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { selectSubStore, setSubStore } from '../../store/page/pageSlice'
import back from './image/back@3x.png'
import {
    BlockButtonPrice,
    BlockPriceStoreCoin,
    BlockPriceStoreGift,
    BlockPriceStoreImg,
    BlockScroll,
    BlockStore,
    BlockStoreImg,
    BlockStoreItem,
    Space,
    Button,
    ButtonMoneyCoin,
    Container,
    GeneralContainer,
    ButtonMoneyGift,
    BlockButtonStoreImgB,
    ButtonMoneyAdd,
    TopButtons,
} from './style'
import { selectUserInfo } from '../../store/authToken/auth'
import coin1 from './image/Coins1.png'
import coin2 from './image/Coins2.png'
import coin3 from './image/Coins3.png'
import coin4 from './image/Coins4.png'
import coin5 from './image/Coins5.png'
import gift1 from './image/Gift1.png'
import gift2 from './image/Gift2.png'
import gift3 from './image/Gift3.png'
import gift4 from './image/Gift4.png'
import gift5 from './image/Gift5.png'
import coinIcon from './image/smallCoinIcon@3x.png'
import giftIcon from './image/giftSmallIcon@3x.png'
import buttonFon from './image/layer702@3x.png'
import add from './image/addButton@3x.png'
import { setChatNameSubscriptionModal, setShop } from '../../store/storeShop/shop'
import { AutoBundle } from '../../components/Bundles/AutoBundle'
import VipAllOffer from './image/vipAllOffer.png'
import { ButtonG } from '../../components/button/button'
import { useXsollaPayments } from '../../hooks/useXsollaPayments'

interface IObjectKeys {
    [key: string]: string
}
const coin: IObjectKeys = {
    mediumhandful: coin1,
    largehandful: coin2,
    smallbag: coin3,
    mediumbag: coin4,
    largebag: coin5,
}
const gift: IObjectKeys = {
    mediumgifthandful: gift1,
    largegifthandful: gift2,
    smallgiftbag: gift3,
    mediumgiftbag: gift4,
    largegiftbag: gift5,
}

export const Store = () => {
    const subStore = useAppSelector(selectSubStore)
    const userInfo = useAppSelector(selectUserInfo)
    const dispatch = useAppDispatch()
    const { purchaseXsollaItem, xsollaItems: itemsToBuy, boughtBundles } = useXsollaPayments()

    const setSubPage = (subStoreName: 'coin' | 'gift') => {
        dispatch(setSubStore(subStoreName))
    }

    return (
        <GeneralContainer>
            <Container>
                <TopButtons>
                    <Button onClick={() => dispatch(setShop(false))}>
                        <img src={back} />
                    </Button>
                    <Space />

                    <ButtonMoneyCoin onClick={() => setSubPage('coin')}>
                        <BlockButtonStoreImgB src={coinIcon} />
                        {subStore === 'gift' && <ButtonMoneyAdd src={add} />}
                        {userInfo !== null && userInfo.coin}
                    </ButtonMoneyCoin>
                    <ButtonMoneyGift onClick={() => setSubPage('gift')}>
                        <BlockButtonStoreImgB src={giftIcon} />
                        {subStore === 'coin' && <ButtonMoneyAdd src={add} />}
                        {userInfo !== null && userInfo.gift}
                    </ButtonMoneyGift>
                </TopButtons>
            </Container>

            {subStore === 'coin' && itemsToBuy !== null && (
                <BlockScroll>
                    <BlockStore>
                        {!boughtBundles.allPhotosSubscription && (
                            <div className="vip-offer-all">
                                <img className="vip-offer-all__image" src={VipAllOffer} />
                                <div className="vip-offer-all__text">
                                    <div>Get all PHOTOS OF ALL GIRLS</div>
                                    <div>FOR FREE!</div>
                                </div>
                                <ButtonG
                                    className="vip-offer-all__button"
                                    name="Continue"
                                    onClick={() => dispatch(setChatNameSubscriptionModal('blogger'))}
                                />
                            </div>
                        )}

                        <AutoBundle />
                        {itemsToBuy.map((val: any) => {
                            if (val.bundle_type === 'virtual_currency_package' && val.content[0].sku === 'coin') {
                                return (
                                    <BlockStoreItem key={val.item_id}>
                                        <BlockPriceStoreCoin>
                                            <BlockPriceStoreImg src={coinIcon} />
                                            {val.content[0].quantity}
                                        </BlockPriceStoreCoin>
                                        <BlockStoreImg src={coin[val.sku]} />
                                        <BlockButtonPrice onClick={() => purchaseXsollaItem(val.sku, val.price.amount)}>
                                            <img src={buttonFon} />
                                            <span>$ {val.price.amount}</span>
                                        </BlockButtonPrice>
                                    </BlockStoreItem>
                                )
                            } else {
                                return null
                            }
                        })}
                    </BlockStore>
                </BlockScroll>
            )}

            {subStore === 'gift' && itemsToBuy !== null && (
                <BlockScroll>
                    <BlockStore>
                        {!boughtBundles.allPhotosSubscription && (
                            <div className="vip-offer-all">
                                <img className="vip-offer-all__image" src={VipAllOffer} />
                                <div className="vip-offer-all__text">
                                    <div>Get all PHOTOS OF ALL GIRLS</div>
                                    <div>FOR FREE!</div>
                                </div>
                                <ButtonG
                                    className="vip-offer-all__button"
                                    name="Continue"
                                    onClick={() => dispatch(setChatNameSubscriptionModal('blogger'))}
                                />
                            </div>
                        )}
                        <AutoBundle />
                        {itemsToBuy.map((val: any) => {
                            if (val.bundle_type === 'virtual_currency_package' && val.content[0].sku === 'gift') {
                                return (
                                    <BlockStoreItem key={val.item_id}>
                                        <BlockPriceStoreGift>
                                            <BlockPriceStoreImg src={giftIcon} />
                                            {val.content[0].quantity}
                                        </BlockPriceStoreGift>
                                        <BlockStoreImg src={gift[val.sku]} />
                                        <BlockButtonPrice onClick={() => purchaseXsollaItem(val.sku, val.price.amount)}>
                                            <img src={buttonFon} />
                                            <span>$ {val.price.amount}</span>
                                        </BlockButtonPrice>
                                    </BlockStoreItem>
                                )
                            } else {
                                return null
                            }
                        })}
                    </BlockStore>
                </BlockScroll>
            )}
        </GeneralContainer>
    )
}
