import styled from 'styled-components/macro'

export const Bgg = styled.div`
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    z-index: 1000;
    left: 0;
    display: flex;
    justify-content: center;
    right: 0;
    background: #ffc8a4;
`
export const BgImg = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: 1000;
    left: 0;
    display: flex;
    justify-content: center;
    right: 0;
    background: #fff;
`
export const BgBlockImg = styled.button`
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    border-radius: 0 !important;
    position: absolute;
    z-index: 10000;
    bottom: 15%;
    :hover {
        transform: scale(1.1);
    }
    img {
        pointer-events: none;
        position: relative;
        max-width: 50%;
    }
    span {
        position: absolute;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin-top: -11px;
        font-size: 30px;
        text-shadow:
            0px 1px 0 rgb(35, 136, 0),
            0px -1px 0 rgb(35, 136, 0),
            1px 0px 0 rgb(35, 136, 0),
            -1px 0px 0 rgb(35, 136, 0),
            1px 1px 0 rgb(35, 136, 0),
            1px -1px 0 rgb(35, 136, 0),
            -1px 1px 0 rgb(35, 136, 0),
            -1px -1px 0 rgb(35, 136, 0);
        @media (max-width: 768px) {
            font-size: 20px;
            margin-top: -7px;
        }
    }
`
export const BgBlockImgPlay = styled.button`
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    border-radius: 0 !important;
    position: absolute;
    z-index: 10000;
    bottom: 15%;
    :hover {
        transform: scale(1.1);
    }
    img {
        pointer-events: none;
        position: relative;
        max-width: 50%;
    }
    span {
        position: absolute;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin-top: -11px;
        font-size: 34px;
        text-shadow:
            0px 1px 0 rgb(35, 136, 0),
            0px -1px 0 rgb(35, 136, 0),
            1px 0px 0 rgb(35, 136, 0),
            -1px 0px 0 rgb(35, 136, 0),
            1px 1px 0 rgb(35, 136, 0),
            1px -1px 0 rgb(35, 136, 0),
            -1px 1px 0 rgb(35, 136, 0),
            -1px -1px 0 rgb(35, 136, 0);
        @media (max-width: 768px) {
            font-size: 34px;
            margin-top: -12px;
        }
    }
`
export const BgBlockImgContariner = styled.div`
    top: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    z-index: 10000;
`
export const BgFull = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: 1;
    left: 0;
    display: flex;
    justify-content: center;
    right: 0;
    background: white;
`
export const Bg = styled.div`
    position: absolute;
    width: 100vw;
    top: 0;
    z-index: 20;
    left: 0;
    display: flex;
    justify-content: center;
    right: 0;
    background: #000000e8;
    height: 100%;
    @media (max-width: 768px) {
        height: '-webkit-fill-available';
    }
`
export const BgRand = styled.div`
    position: absolute;
    width: 100vw;
    top: 0;
    z-index: 20;
    left: 0;
    display: flex;
    justify-content: center;
    right: 0;
    background: #fff;
    height: 100%;
    @media (max-width: 768px) {
        height: '-webkit-fill-available';
    }
`
export const FadeBlock = styled.div`
    width: 100%;
    position: relative;
`
