import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { isFadePage, selectIsFadePage, selectPage } from '../store/page/pageSlice'
import { Profile } from './profile/profile'
import { Fade } from '@mui/material'
import { Bg, FadeBlock } from './style'
import { Chats } from './chats/chats'
import { Swiper } from './swiper/swiper'
import { selectSwipe } from '../store/swipe/swipeSlice'
import { ChatsItem } from './chatItem/chathItem'
// import { PaidCharacterPopup } from './swiper/PaidCharacterPopup'
import { Gallery } from './gallery/gallery'

export const Pages = () => {
    const page = useAppSelector(selectPage)
    const fadeMount = useAppSelector(selectIsFadePage)
    const characters = useAppSelector(selectSwipe)
    // const isLikeSwipe = useAppSelector(selectMatchStatus)
    // const isPaidCharacter = useAppSelector(selectPaidStatus)
    const dispatch = useAppDispatch()

    return (
        <>
            <Fade
                in={page === 'profile' && fadeMount}
                onExited={() => {
                    dispatch(isFadePage(true))
                }}
                mountOnEnter
                unmountOnExit
            >
                <FadeBlock>
                    <Profile />
                </FadeBlock>
            </Fade>
            <Fade
                in={page === 'slider' && fadeMount}
                onExited={() => {
                    dispatch(isFadePage(true))
                }}
                mountOnEnter
                unmountOnExit
            >
                <FadeBlock>
                    <Swiper />
                </FadeBlock>
            </Fade>
            <Fade
                in={page === 'chat' && fadeMount}
                onExited={() => {
                    dispatch(isFadePage(true))
                }}
                mountOnEnter
                unmountOnExit
            >
                <FadeBlock>
                    <Chats />
                </FadeBlock>
            </Fade>
            <Fade
                in={page === 'chatItem' && fadeMount}
                onExited={() => {
                    dispatch(isFadePage(true))
                }}
                mountOnEnter
                unmountOnExit
            >
                <FadeBlock>
                    <ChatsItem />
                </FadeBlock>
            </Fade>
            <Fade
                in={page === 'gallery' && fadeMount}
                onExited={() => {
                    dispatch(isFadePage(true))
                }}
                mountOnEnter
                unmountOnExit
            >
                <FadeBlock>
                    <Gallery />
                </FadeBlock>
            </Fade>
            {/* <Fade in={isLikeSwipe && isPaidCharacter} mountOnEnter unmountOnExit>
                <Bg>
                    <PaidCharacterPopup />
                </Bg>
            </Fade> */}
            <Fade
                in={characters.length !== 0 ? characters[characters.length - 1].like_required : false}
                mountOnEnter
                unmountOnExit
            >
                <Bg></Bg>
            </Fade>
        </>
    )
}
