import {
    BlockFlexColumn,
    BlockFlexItem,
    BlockFlexRow,
    Container2,
    Container3,
    Container4,
    Image5,
    NameMoney,
    Money,
    Image4,
    Image41,
    UserName,
    ItemButton,
} from './style'
import coins from './image/coinSmallIcon@3x.png'
import gift from './image/giftSmallIcon@3x.png'
import greenAdd from './image/greenButtonAdd.png'
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { setSubStore } from '../../../store/page/pageSlice'
import { selectUserInfo, selectName } from '../../../store/authToken/auth'
import { setShop } from '../../../store/storeShop/shop'
import { AutoBundle } from '../../../components/Bundles/AutoBundle'

export const General = () => {
    const name = useAppSelector(selectName)
    const userInfo = useAppSelector(selectUserInfo)
    const dispatch = useAppDispatch()

    const store = (name: string) => {
        dispatch(setSubStore(name))
        dispatch(setShop(true))
    }

    return (
        <>
            <Container2>
                <UserName>{name}</UserName>
            </Container2>
            <Container3>
                <BlockFlexRow>
                    <BlockFlexColumn>
                        <BlockFlexItem>
                            <Image41 src={coins} />
                            <NameMoney>Coins</NameMoney>
                        </BlockFlexItem>
                        <BlockFlexItem>
                            <Money>{userInfo !== null && userInfo.coin}</Money>
                        </BlockFlexItem>
                        <BlockFlexItem>
                            <ItemButton onClick={() => store('coin')}>
                                <Image5 src={greenAdd} />
                            </ItemButton>
                        </BlockFlexItem>
                        <BlockFlexItem></BlockFlexItem>
                    </BlockFlexColumn>
                    <BlockFlexColumn>
                        <BlockFlexItem>
                            <Image4 src={gift} />
                            <NameMoney>Gifts</NameMoney>
                        </BlockFlexItem>
                        <BlockFlexItem>
                            <Money> {userInfo !== null && userInfo.gift}</Money>
                        </BlockFlexItem>
                        <BlockFlexItem>
                            <ItemButton onClick={() => store('gift')}>
                                <Image5 src={greenAdd} />
                            </ItemButton>
                        </BlockFlexItem>
                        <BlockFlexItem></BlockFlexItem>
                    </BlockFlexColumn>
                </BlockFlexRow>
            </Container3>
            <Container4>
                <AutoBundle />
            </Container4>
        </>
    )
}
