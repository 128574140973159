import {
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    Slide,
} from '@mui/material'
import { BackgroundMenu, BlockMenu, Body } from './dashbord-layout-style'
import { DashboardMenu } from './menu/menu'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { isBigInfo, selectIsFadeBg, selectPage, setPage } from '../store/page/pageSlice'
import { selectMatchStatus, selectPaidStatus } from '../store/swipe/swipeSlice'
import { Bgg, Bg, BgRand } from '../page/style'
import { ItsAMatch } from '../page/swiper/itsAMatch'
import { DashboardMenuChat } from './menu/dashboardMenuChat'
import { Level } from '../page/chatItem/level'
import { selectCanvasOpen, selectLevel, selectLevelNext } from '../store/chat/chat'
import { Rendezvous } from '../page/chatItem/rendezvous'
import { LevelNext } from '../page/chatItem/levelNext'
import {
    TextHeaderRemove,
    TextRemove,
    BlockButtonCancel,
    BlockVideo,
    GifImage,
    BlockPreLoad,
    ImagePreLoad,
    ImagePreLoadClock,
    PreLoadText,
    TextWelcome,
    TextWelcome2,
    ImageCoinMoney,
    Price,
    CoinBlock,
    GiftBlock,
    BlockButtonCollect,
} from '../page/profile/settings/style'
import React, { useRef, useState } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import {
    selectTriggerAddingCoin,
    selectChoes,
    selectUserInfo,
    selectOpenPart,
    collectEvent,
    setFirstGift,
    setTriggerAddingCoin,
    selectFirstGift,
} from '../store/authToken/auth'
import buttonFon from '../page/store/image/layer702@3x.png'
import art from './menu/image/radarArt@3x.png'
import { InfoCoin } from './infoCoin'
import { BBInfoCoin } from './styleInfoCoin'
import { ImageCanvas } from './ImageCanvas'
import { selectShop, selectRandevu } from '../store/storeShop/shop'
import { Store } from '../page/store/store'
import { ChooseYourInterests } from '../components/interests/ChooseYourInterests'
import XsollaPayment from '../components/XsollaPayment'
import LoginForm from '../components/LoginForm'
import { getPlatform } from '../helper/getPlatform'
import BundleDialog from '../components/BundleDialog'

import coinMoney from './menu/image/CoinsAndGiftArt.png'
import coinIcon from '../page/store/image/smallCoinIcon@3x.png'
import giftIcon from '../page/store/image/giftSmallIcon@3x.png'
import SubscriptionModal from '../components/SubscriptionModal'

interface IDashboardLayout {
    children: React.JSX.Element[] | React.JSX.Element
}

const dialogTitle = {
    background: 'linear-gradient(90deg, rgba(172,229,255,1) 0%, rgba(255,214,237,1) 100%)',
    height: '30px',
}
const dialogContent = {
    padding: '0px 10px 13px 10px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
}

export let br: number
if (window.navigator.userAgent.indexOf('FBAN') > -1 || window.navigator.userAgent.indexOf('FBAV') > -1) {
    br = 1
} else if (window.navigator.userAgent.indexOf('Instagram') !== -1) {
    br = 2
} else if (window.navigator.userAgent.indexOf('Telegram') !== -1) {
    br = 3
} else {
    br = 0
}

export const DashboardLayout = (props: IDashboardLayout) => {
    const { children } = props
    const page = useAppSelector(selectPage)
    const fadeMountBg = useAppSelector(selectIsFadeBg)
    const like = useAppSelector(selectMatchStatus)
    const paid = useAppSelector(selectPaidStatus)
    const levelStatus = useAppSelector(selectLevel)
    const store = useAppSelector(selectShop)
    const userInfo = useAppSelector(selectUserInfo)
    const levelNextStatus = useAppSelector(selectLevelNext)
    const isFirstGift = useAppSelector(selectFirstGift)
    const triggerAddingCoin = useAppSelector(selectTriggerAddingCoin)
    const randevuStatus = useAppSelector(selectRandevu)
    const openPart = useAppSelector(selectOpenPart)
    const [gotoBrouser, setGotoBrouser] = useState(br > 0)
    const [videoBrouser, setvideoBrouser] = useState(0)
    const openChoes = useAppSelector(selectChoes)
    const windowSizeHeight = useRef(window.innerHeight)

    const ContainerStyle = {
        marginTop: '0px',
        marginBottom: '0px',
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
        height: windowSizeHeight.current + 'px',
        '@media (max-width: 780px)': {
            position: 'relative',
            overflow: 'hidden',
        },
    }

    const canvasOpen = useAppSelector(selectCanvasOpen)
    const dispatch = useAppDispatch()

    let openMatch = () => {
        setTimeout(() => {
            dispatch(isBigInfo(true))
            dispatch(
                setPage({
                    name: 'chatItem',
                    subPage: 'general',
                    isFadeSub: false,
                    isFadePage: false,
                    isFadeBg: true,
                    oldPage: page,
                }),
            )
        }, 500)
    }

    const okGoToBrouser = () => {
        setGotoBrouser(false)
        setvideoBrouser(br)
    }

    const closePopupFirst = () => {
        if (window.location.origin === 'https://loverzgame.com') {
            dispatch(collectEvent(userInfo))
        }
        dispatch(setTriggerAddingCoin(true))
        dispatch(setFirstGift(false))
    }

    return (
        <Container maxWidth="sm" sx={ContainerStyle}>
            <Fade in={page !== 'profile' && fadeMountBg} mountOnEnter unmountOnExit>
                <BackgroundMenu zIndex={page === 'chatItem' ? 15 : 1} />
            </Fade>
            <BlockMenu>
                <Fade in={page !== 'chatItem'} mountOnEnter unmountOnExit>
                    <div style={{ position: 'absolute', width: '100%' }}>
                        <DashboardMenu />
                    </div>
                </Fade>
                <Fade in={page === 'chatItem'} mountOnEnter unmountOnExit>
                    <div style={{ position: 'absolute', width: '100%' }}>
                        <DashboardMenuChat />
                    </div>
                </Fade>
            </BlockMenu>
            <Body zIndex={page === 'chatItem' && like ? 21 : 10}>{children}</Body>

            <Fade
                in={like && !paid}
                addEndListener={() => {
                    if (like) {
                        openMatch()
                    }
                }}
                mountOnEnter
                unmountOnExit
                timeout={500}
            >
                <Bgg>
                    <ItsAMatch />
                </Bgg>
            </Fade>
            <Fade in={levelStatus} mountOnEnter unmountOnExit timeout={500}>
                <Bg>
                    <Level />
                </Bg>
            </Fade>
            <Fade in={randevuStatus} mountOnEnter unmountOnExit timeout={500}>
                <BgRand>
                    <Rendezvous />
                </BgRand>
            </Fade>
            <Fade in={levelNextStatus} mountOnEnter unmountOnExit timeout={500}>
                <Bg>
                    <LevelNext />
                </Bg>
            </Fade>
            <Fade in={triggerAddingCoin} mountOnEnter unmountOnExit timeout={300}>
                <BBInfoCoin>
                    <InfoCoin />
                </BBInfoCoin>
            </Fade>
            <Fade in={canvasOpen} mountOnEnter unmountOnExit timeout={300}>
                <Bg>
                    <ImageCanvas />
                </Bg>
            </Fade>
            <Fade in={store} mountOnEnter unmountOnExit>
                <Bg>
                    <Store />
                </Bg>
            </Fade>

            {userInfo !== null && <BundleDialog />}

            <LoginForm />

            <Dialog
                open={isFirstGift}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth={'xs'}
                PaperProps={{
                    style: { borderRadius: '11.7pt' },
                }}
                onClose={closePopupFirst}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={dialogTitle}>
                    <TextWelcome>Welcome!</TextWelcome>
                </DialogTitle>
                <DialogContent sx={dialogContent}>
                    <TextWelcome2>Take your first bonus!</TextWelcome2>
                    <ImageCoinMoney src={coinMoney} />
                    <Price>
                        <CoinBlock>
                            <img src={coinIcon} />
                            300
                        </CoinBlock>
                        <GiftBlock>
                            <img src={giftIcon} />
                            55
                        </GiftBlock>
                    </Price>
                    <BlockButtonCollect onClick={closePopupFirst}>
                        <img src={buttonFon} />
                        <span>Collect</span>
                    </BlockButtonCollect>
                </DialogContent>
            </Dialog>

            <Dialog
                open={gotoBrouser}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth={'xs'}
                PaperProps={{
                    style: { borderRadius: '11.7pt' },
                }}
                onClose={okGoToBrouser}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={dialogTitle}>
                    <TextHeaderRemove>Welcome to Loverz! :)</TextHeaderRemove>
                </DialogTitle>
                <DialogContent sx={dialogContent}>
                    <TextRemove>
                        For the stable operation of the Loverz game and saving your progress, we recommend switching to
                        the default browser. <br />
                        You will be shown the instruction: "How to switch to the default browser" <br />
                        <br />
                        <br />
                        Thank you and enjoy the game :)
                    </TextRemove>
                </DialogContent>
                <DialogActions>
                    <BlockButtonCancel onClick={okGoToBrouser}>
                        <img src={buttonFon} />
                        <span>Ok</span>
                    </BlockButtonCancel>
                </DialogActions>
            </Dialog>

            <XsollaPayment />

            <SubscriptionModal />

            {openChoes && <ChooseYourInterests />}

            <Fade in={openPart} mountOnEnter unmountOnExit>
                <BlockPreLoad>
                    <PreLoadText>
                        The selection of suitable
                        <br />
                        partners is underway
                    </PreLoadText>
                    <ImagePreLoad src={art} />
                    <ImagePreLoadClock />
                    <CircularProgress />
                </BlockPreLoad>
            </Fade>

            <Fade
                in={
                    (videoBrouser > 0 && getPlatform() === 'Android') ||
                    (videoBrouser > 0 && getPlatform() === 'iPhone') ||
                    (videoBrouser > 0 && getPlatform() === 'iPad')
                }
                mountOnEnter
                unmountOnExit
            >
                <BlockVideo>
                    {videoBrouser === 1 && getPlatform() === 'Android' && (
                        <GifImage loop muted autoPlay playsInline controls={false}>
                            <source src="./android_facebook_open_system_browser.mp4" type="video/mp4" />
                        </GifImage>
                    )}
                    {videoBrouser === 2 && getPlatform() === 'Android' && (
                        <GifImage loop muted autoPlay playsInline controls={false}>
                            <source src="./android_instagram_open_system_browser.mp4" type="video/mp4" />
                            <source src="./android_instagram_open_system_browser.webm" type="video/webm" />
                            <source src="./android_instagram_open_system_browser.ogg" type="video/ogg" />
                        </GifImage>
                    )}
                    {videoBrouser === 3 && getPlatform() === 'Android' && (
                        <GifImage loop muted autoPlay playsInline controls={false}>
                            <source src="./android_telegram_open_system_browser.mp4" type="video/mp4" />
                        </GifImage>
                    )}
                    {videoBrouser === 1 && getPlatform() === 'iPhone' && (
                        <GifImage loop muted autoPlay playsInline controls={false}>
                            <source src="./ios_facebook_open_system_browser.mp4" type="video/mp4" />
                        </GifImage>
                    )}
                    {videoBrouser === 2 && getPlatform() === 'iPhone' && (
                        <GifImage loop muted autoPlay playsInline controls={false}>
                            <source src="./ios_instagram_open_system_browser.mp4" type="video/mp4" />
                        </GifImage>
                    )}
                    {videoBrouser === 3 && getPlatform() === 'iPhone' && (
                        <GifImage loop muted autoPlay playsInline controls={false}>
                            <source src="./ios_telegram_open_system_browser.mp4" type="video/mp4" />
                        </GifImage>
                    )}
                    {videoBrouser === 1 && getPlatform() === 'iPad' && (
                        <GifImage loop muted autoPlay playsInline controls={false}>
                            <source src="./ios_facebook_open_system_browser.mp4" type="video/mp4" />
                            <source src="./ios_facebook_open_system_browser.webm" type="video/webm" />
                        </GifImage>
                    )}
                    {videoBrouser === 2 && getPlatform() === 'iPad' && (
                        <GifImage loop muted autoPlay playsInline controls={false}>
                            <source src="./ios_telegram_open_system_browser.mp4" type="video/mp4" />
                        </GifImage>
                    )}
                    {videoBrouser === 3 && getPlatform() === 'iPad' && (
                        <GifImage loop muted autoPlay playsInline controls={false}>
                            <source src="./ios_instagram_open_system_browser.mp4" type="video/mp4" />
                        </GifImage>
                    )}
                </BlockVideo>
            </Fade>
        </Container>
    )
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction={'right'} ref={ref} {...props} />
})
