import {
    MenuChat,
    BackArrowChat,
    BlockFlexCol,
    ContainerBlockChatItemAvatar,
    ContainerBlockChatItemName,
    BlockFlexColCenter,
    ProgressBarFull,
    ProgressBarLoaderNow,
    ButtonPhotoChat,
    BlockFlexRowBet,
    ProgressBar,
    ProgressBarLoader,
    ProgressBarLoaderNext,
    HeadBlock,
    HeadBlockImage,
    HeadBlock2,
    HeadBlockText,
    BlockEvent,
    BlockEventCoor,
    BlockEventCoorImage,
    BlockEventCoorImageShar,
} from './style'
import emptyHeart from './image/emptyHeartIcon.png'
import fullHeart from './image/fullHeartIcon.png'
import coin from './image/smallCoinIcon@3x.png'
import shar from './image/usualDateIcon@3x.png'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import {
    isBigInfo,
    selectIsBigInfo,
    selectIsFadeSub,
    selectOldPage,
    selectPage,
    selectSubPage,
    setPage,
} from '../../store/page/pageSlice'
import { Fade } from '@mui/material'
import back from '../../page/store/image/back@3x.png'
import { AvatarBackgroundImage, ImgBlockLeftStatusOnline } from '../../page/chats/style'
import bgPhoto from '../../page/chats/image/directAvatarBase@3x.png'
import onlineStatus from '../../page/chats/image/onlineIndicatorCopy@3x.png'
import offlineStatus from '../../page/chats/image/offlineIndicator@3x.png'
import {
    clearChat,
    selectChapterNumber,
    selectChat,
    selectRelationshipProgress,
    selectWait,
    setRelationshipProgress,
} from '../../store/chat/chat'
import photoButton from '../../page/chats/image/photosButton@3x.png'
import { AvatarImage } from '../../page/chatItem/style'

export const DashboardMenuChat = () => {
    const page = useAppSelector(selectPage)
    const subPage = useAppSelector(selectSubPage)
    const fadeMount = useAppSelector(selectIsFadeSub)
    const chat = useAppSelector(selectChat)
    const chapterNumber = useAppSelector(selectChapterNumber)
    const wait = useAppSelector(selectWait)
    const bigInfoData = useAppSelector(selectIsBigInfo)
    const relationshipProgress = useAppSelector(selectRelationshipProgress)
    const oldPage = useAppSelector(selectOldPage)
    const dispatch = useAppDispatch()

    const BackProfile = () => {
        if (bigInfoData && page !== 'chatItem') {
            dispatch(isBigInfo(false))
        } else if (page === 'chatItem' && oldPage !== 'gallery') {
            dispatch(setRelationshipProgress({ relationshipProgress: 0, chapterNumber: 1 }))
            dispatch(isBigInfo(false))
            dispatch(clearChat())
            dispatch(
                setPage({
                    name: oldPage,
                    subPage: 'general',
                    isFadeSub: true,
                    isFadePage: false,
                    isFadeBg: true,
                    oldPage: page,
                }),
            )
        } else if (page === 'chatItem' && oldPage === 'gallery') {
            dispatch(setRelationshipProgress({ relationshipProgress: 0, chapterNumber: 1 }))
            dispatch(isBigInfo(false))
            dispatch(clearChat())
            dispatch(
                setPage({
                    name: 'chat',
                    subPage: 'general',
                    isFadeSub: true,
                    isFadePage: false,
                    isFadeBg: true,
                    oldPage: page,
                }),
            )
        } else {
            dispatch(
                setPage({
                    name: 'profile',
                    subPage: 'general',
                    isFadeSub: false,
                    isFadePage: true,
                    oldPage: page,
                }),
            )
        }
    }
    const openGallery = () => {
        dispatch(
            setPage({
                name: 'gallery',
                subPage: 'general',
                isFadeSub: false,
                isFadePage: false,
                isFadeBg: true,
                oldPage: page,
            }),
        )
    }

    return (
        <MenuChat zIndex={page === 'chatItem' ? 20 : 10}>
            <Fade in={(subPage === 'setting' && fadeMount) || bigInfoData}>
                <BlockFlexCol>
                    <BlockFlexRowBet>
                        <BackArrowChat onClick={BackProfile}>
                            <img src={back} />
                        </BackArrowChat>
                        {chat !== null && (
                            <>
                                <BlockFlexColCenter>
                                    <ContainerBlockChatItemAvatar>
                                        <AvatarBackgroundImage src={bgPhoto} />
                                        <AvatarImage src={chat.chat_pic_url} />
                                        {wait === null && <ImgBlockLeftStatusOnline src={onlineStatus} />}
                                        {wait !== null && <ImgBlockLeftStatusOnline src={offlineStatus} />}
                                    </ContainerBlockChatItemAvatar>
                                    <ContainerBlockChatItemName>{chat.profile_name.English}</ContainerBlockChatItemName>
                                </BlockFlexColCenter>
                                <ProgressBar>
                                    {chat !== null && (
                                        <ProgressBarFull>
                                            <ProgressBarLoader>
                                                <ProgressBarLoaderNow
                                                    relationshipProgress={relationshipProgress}
                                                ></ProgressBarLoaderNow>
                                                {chapterNumber in chat.progress_rewards && (
                                                    <>
                                                        {'SympathyReward' in chat.progress_rewards[chapterNumber] && (
                                                            <>
                                                                {chat.progress_rewards[
                                                                    chapterNumber
                                                                ].SympathyReward.map((val: any) => {
                                                                    return (
                                                                        <BlockEvent key={val} width={val}>
                                                                            <BlockEventCoor>
                                                                                <BlockEventCoorImage src={coin} />
                                                                            </BlockEventCoor>
                                                                        </BlockEvent>
                                                                    )
                                                                })}
                                                            </>
                                                        )}
                                                        {'Date' in chat.progress_rewards[chapterNumber] && (
                                                            <>
                                                                {chat.progress_rewards[chapterNumber].Date.map(
                                                                    (val: any) => {
                                                                        return (
                                                                            <BlockEvent key={val} width={val}>
                                                                                <BlockEventCoor>
                                                                                    <BlockEventCoorImageShar
                                                                                        src={shar}
                                                                                    />
                                                                                </BlockEventCoor>
                                                                            </BlockEvent>
                                                                        )
                                                                    },
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                {relationshipProgress !== 100 && (
                                                    <HeadBlock>
                                                        <HeadBlockImage src={emptyHeart} />
                                                        <HeadBlockText>{chapterNumber}</HeadBlockText>
                                                    </HeadBlock>
                                                )}
                                                {relationshipProgress === 100 && (
                                                    <HeadBlock>
                                                        <HeadBlockImage src={fullHeart} />
                                                        <HeadBlockText>{chapterNumber}</HeadBlockText>
                                                    </HeadBlock>
                                                )}
                                            </ProgressBarLoader>

                                            <ProgressBarLoaderNext>
                                                {Object.keys(chat.progress_rewards).includes(
                                                    String(chapterNumber + 1),
                                                ) && (
                                                    <>
                                                        {'SympathyReward' in
                                                            chat.progress_rewards[chapterNumber + 1] && (
                                                            <>
                                                                {chat.progress_rewards[
                                                                    chapterNumber + 1
                                                                ].SympathyReward.map((val: any) => {
                                                                    return (
                                                                        <BlockEvent key={val} width={val}>
                                                                            <BlockEventCoor>
                                                                                <BlockEventCoorImage src={coin} />
                                                                            </BlockEventCoor>
                                                                        </BlockEvent>
                                                                    )
                                                                })}
                                                            </>
                                                        )}
                                                        {'Date' in chat.progress_rewards[chapterNumber + 1] && (
                                                            <>
                                                                {chat.progress_rewards[chapterNumber + 1].Date.map(
                                                                    (val: any) => {
                                                                        return (
                                                                            <BlockEvent key={val} width={val}>
                                                                                <BlockEventCoor>
                                                                                    <BlockEventCoorImageShar
                                                                                        src={shar}
                                                                                    />
                                                                                </BlockEventCoor>
                                                                            </BlockEvent>
                                                                        )
                                                                    },
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                                <HeadBlock2>
                                                    <HeadBlockImage src={emptyHeart} />
                                                    <HeadBlockText>{Number(chapterNumber) + 1}</HeadBlockText>
                                                </HeadBlock2>
                                            </ProgressBarLoaderNext>
                                        </ProgressBarFull>
                                    )}
                                </ProgressBar>
                            </>
                        )}

                        <ButtonPhotoChat onClick={openGallery}>
                            <img src={photoButton} />
                        </ButtonPhotoChat>
                    </BlockFlexRowBet>
                </BlockFlexCol>
            </Fade>
        </MenuChat>
    )
}
