import styled from 'styled-components/macro'

export const Container = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
`
export const ContainerImage = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-bottom: 15px;
`
export const ContainerImageGen = styled.img`
    max-width: 100%;
    border-radius: 10px;
`
export const ContainerImageFon = styled.img`
    position: absolute;
    max-width: 100%;
    border-radius: 10px;
`
export const ContainerImages = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    gap: 2%;
    flex-wrap: wrap;
    overflow: auto;
`
export const BoxImage = styled.div`
    position: relative;
    cursor: pointer;
    width: 32%;
    flex-wrap: wrap;
    display: flex;
    border-radius: 10px;
    margin-bottom: 2%;
    :hover {
        transform: scale(1.03);
    }
`
export const BoxImageGen = styled.img`
    max-width: 100%;
    border-radius: 10px;
`
export const BoxImageFon = styled.img`
    max-width: 100%;
    border-radius: 10px;
    position: absolute;
`
