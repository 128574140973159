import { useEffect, useState } from 'react'
import { StarterPack } from '../Bundles/StarterPack'
import { UnlimitedProfiles } from '../Bundles/UnlimitedProfiles'
import { useXsollaPayments } from '../../hooks/useXsollaPayments'

export const AutoBundle = ({ isDialog }: { isDialog?: boolean }) => {
    const { xsollaItems, boughtBundles } = useXsollaPayments()
    const [bundleToOpen, setBundleToOpen] = useState<'starterpacks' | 'starterpackxl' | 'unlimitedprofiles' | null>()

    const bundles = {
        starterpacks: <StarterPack isDialog={isDialog} />,
        starterpackxl: <StarterPack isDialog={isDialog} isXLpack={true} />,
        unlimitedprofiles: <UnlimitedProfiles isDialog={isDialog} />,
    }

    useEffect(() => {
        if (!boughtBundles.unlimitedprofiles) {
            setBundleToOpen('unlimitedprofiles')
        } else if (boughtBundles.unlimitedprofiles && !boughtBundles.starterpacks) {
            setBundleToOpen('starterpacks')
        } else if (boughtBundles.unlimitedprofiles && boughtBundles.starterpacks && !boughtBundles.secondpackxl) {
            setBundleToOpen('starterpackxl')
        } else {
            setBundleToOpen(null)
        }
    }, [boughtBundles])

    return xsollaItems && bundleToOpen !== null && bundles[bundleToOpen!]
}
