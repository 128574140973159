export let userDeviceArray = [
    { device: 'Android', platform: /Android/ },
    { device: 'android', platform: /android/ },
    { device: 'iPhone', platform: /iPhone/ },
    { device: 'iPad', platform: /iPad/ },
    { device: 'Symbian', platform: /Symbian/ },
    { device: 'Windows Phone', platform: /Windows Phone/ },
    { device: 'Tablet OS', platform: /Tablet OS/ },
    { device: 'Linux', platform: /Linux/ },
    { device: 'Windows', platform: /Windows NT/ },
    { device: 'Macintosh', platform: /Macintosh/ },
]

export let platform = navigator.userAgent

export const getPlatform = () => {
    return userDeviceArray.find((elem) => elem.platform.test(platform))?.device || platform
}
