import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { Container, GeneralContaineritsAMAtch, BlockContainer, TextitsAMAtch, BlockImageCenter } from './style'
import Textfit from '@namhong2001/react-textfit'
import photo from './image/photo@3x.png'
import bigGreen from './image/bigGreenButton@3x.png'
import { isStatusMacth, selectMacthData } from '../../store/swipe/swipeSlice'

export const ItsAMatch = () => {
    const dispatch = useAppDispatch()

    const matchData = useAppSelector(selectMacthData)

    const closeItIsMatch = () => {
        dispatch(isStatusMacth(false))
    }

    return (
        <GeneralContaineritsAMAtch>
            <Container>
                <BlockContainer>
                    <TextitsAMAtch>
                        <Textfit mode="single">It’s a Match!</Textfit>
                    </TextitsAMAtch>
                    <BlockImageCenter>
                        <img src={photo} />
                        <img className={'avatar'} src={matchData.character.chat_pic_url} />
                        <button onClick={closeItIsMatch}>
                            <img className={'button'} src={bigGreen} />
                            <span>Chat Now</span>
                        </button>
                    </BlockImageCenter>
                </BlockContainer>
            </Container>
        </GeneralContaineritsAMAtch>
    )
}
