import styled from 'styled-components/macro'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/900.css'

export const SubscriptionModalContainer = styled.div`
    position: relative;
    width: 63%;
    display: flex;
    flex: 1;
    justify-content: start;
    flex-direction: column;
    align-items: center;
`
export const SubscriptionModalImage = styled.img`
    height: 100%;
`

export const SubscriptionModalCharacterName = styled.span``

export const BackButton = styled.div`
    top: 5%;
    left: 5%;
    cursor: pointer;
    position: absolute;

    :hover {
        transform: scale(1.2);
    }
`

export const AllGirlsImage = styled.img`
    position: absolute;
    width: 63%;
    margin-top: 120px;

    @media (max-width: 768px) {
        width: 100%;
    }
`

export const SubscriptionModalInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;

    * {
        text-align: center;
    }

    .character-name {
        color: #fff;
        font-weight: 900;
        font-size: 28px;
        -webkit-text-stroke: 1px #5c1890;
    }

    .description {
        color: #ffff81;
        font-size: 26px;
        font-weight: 800;
        -webkit-text-stroke: 1px #5c1890;
    }

    .special-colored {
        color: #b9f4c7;
        font-size: 28px;
        font-weight: 900;
        -webkit-text-stroke: 0.5px #5c1890;
    }

    .small-info__days-count {
        color: #e1bbe2;
        font-weight: 600;
    }

    .small-info__billing-info {
        font-weight: 400;
        color: #bb64f3;
    }

    .continue-button {
        margin-top: 10px;
        margin-bottom: -10px;
        font-size: 28px;
        width: 60%;
    }

    .footer-links {
        display: flex;
        flex: 1;
        justify-content: space-between;
        width: 90%;
        margin-bottom: 5px;
        margin-top: 20px;

        a {
            text-align: left;
            color: #9053bf;
            font-size: 16px;
            font-weight: 600;
        }
    }
`
export const SubscriptionModalDescription = styled.div`
    max-width: 103%;
`

export const Button = styled.button``

export const ButtonBackground = styled.div``

export const ButtonText = styled.span``
