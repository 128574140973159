import styled from 'styled-components/macro'

export const ButtonPhotoChat = styled.button`
    border: none;
    outline: none;
    display: flex;
    position: absolute;
    right: -12px;
    bottom: -5px;
    padding: 0;
    max-width: 30px;
    :hover {
        transform: scale(1.1);
    }
    img {
        max-width: 100%;
        object-fit: contain;
        display: block;
    }
    cursor: pointer;
    background: none;
`
export const ButtonPhotoChatIcomIn = styled.button`
    border: none;
    outline: none;
    display: flex;
    width: 30px;
    padding: 0;
    margin-bottom: 12px;
    :hover {
        transform: scale(1.1);
    }
    img {
        max-width: 100%;
        object-fit: contain;
        display: block;
    }
    :last-child {
        margin-bottom: 0;
    }
    cursor: pointer;
    background: none;
`
export const BlockButtonIcom = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0px 0px 8px 1px #0000004a;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
`
