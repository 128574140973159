import starterPackDialogImage from './img/StarterPackDialog.png'
import starterPackImage from './img/StarterPack.png'
import coinIcon from '../../page/store/image/smallCoinIcon@3x.png'
import giftIcon from '../../page/store/image/giftSmallIcon@3x.png'
import redBackground from '../../dashboards/menu/image/priceBae@3x.png'
import buttonBackgrount from '../../page/store/image/layer702@3x.png'

import {
    BlockButtonCollect2,
    BlockButtonCollect2Text1,
    BlockButtonCollect2Text2,
    BlockImage2,
    BlockImage3,
    CoinBlock2,
    GiftBlock2,
    ImageCoinBandle,
    Price2,
    TextWelcome2Offer,
} from '../../page/profile/settings/style'
import {
    BlockBandl,
    BlockBandlImage,
    BlockBandlInfo,
    BlockBandlInfoPay2,
    BlockBandlInfoPrice2,
    BlockBandlInfoText2,
    BlockBandlInfoVitMoney3,
    BlockBandlInfoVitMoneyCoin,
    BlockBandlInfoVitMoneyGift,
    BlockButtonStoreImgBBland,
} from '../../page/store/style'
import { useXsollaPayments } from '../../hooks/useXsollaPayments'

export const StarterPack = ({ isDialog = false, isXLpack }: { isDialog?: boolean; isXLpack?: boolean }) => {
    const { getXsollaItem, purchaseXsollaItem } = useXsollaPayments()
    const starterPack = isXLpack ? getXsollaItem('starterpackxl') : getXsollaItem('starterpacks')

    return isDialog ? (
        <>
            <ImageCoinBandle src={starterPackDialogImage} />
            <TextWelcome2Offer>{starterPack.name}</TextWelcome2Offer>
            <Price2>
                <CoinBlock2>
                    <img src={coinIcon} />
                    {starterPack.content[0].content[0].quantity}
                </CoinBlock2>
                <GiftBlock2>
                    <img src={giftIcon} />
                    {starterPack.content[1].content[0].quantity}
                </GiftBlock2>
            </Price2>
            <BlockButtonCollect2
                onClick={() => {
                    purchaseXsollaItem(starterPack.sku, starterPack.price.amount)
                }}
            >
                <BlockImage3 src={redBackground} />
                <BlockImage2 src={buttonBackgrount} />
                <BlockButtonCollect2Text1>Buy now</BlockButtonCollect2Text1>
                <BlockButtonCollect2Text2>$ {starterPack.price.amount}</BlockButtonCollect2Text2>
            </BlockButtonCollect2>
        </>
    ) : (
        <>
            <BlockBandl
                onClick={() => {
                    purchaseXsollaItem(starterPack.sku, starterPack.price.amount)
                }}
            >
                <BlockBandlImage src={starterPackImage} />
                <BlockBandlInfo>
                    <BlockBandlInfoText2>
                        <span>{starterPack.name}</span>
                    </BlockBandlInfoText2>
                    <BlockBandlInfoVitMoney3>
                        <BlockButtonStoreImgBBland src={coinIcon} />
                        <BlockBandlInfoVitMoneyCoin>
                            {starterPack.content[0].content[0].quantity}
                        </BlockBandlInfoVitMoneyCoin>
                        <BlockButtonStoreImgBBland src={giftIcon} />
                        <BlockBandlInfoVitMoneyGift>
                            {starterPack.content[1].content[0].quantity}
                        </BlockBandlInfoVitMoneyGift>
                    </BlockBandlInfoVitMoney3>
                    <BlockBandlInfoPrice2>{starterPack.price.amount} $</BlockBandlInfoPrice2>
                    <BlockBandlInfoPay2>Buy now</BlockBandlInfoPay2>
                </BlockBandlInfo>
            </BlockBandl>
        </>
    )
}
