import styled from 'styled-components/macro'

export const ContainerGeneral = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const Container = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`
export const ContainerColumn = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 2;
    justify-content: center;
    align-items: center;
`
export const ContainerColumnIn = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
`
export const ButtonSetting = styled.button`
    cursor: pointer;
    position: absolute;
    width: 13%;
    border: none;
    outline: none;
    background: none;
    padding: 0;
    margin-left: -65%;
    margin-bottom: -22%;
    :hover {
        transform: scale(1.1);
    }
`
export const ContainerUserPhoto = styled.div`
    position: relative;
    width: 50%;
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
    align-items: center;
`
export const ImageButton = styled.img`
    max-width: 100%;
`
export const Image = styled.img`
    max-width: 85%;
    position: absolute;
    display: flex;
    justify-content: center;
`
export const Image2 = styled.img`
    position: relative;
    max-width: 100%;
    display: flex;
    justify-content: center;
`
export const Image3 = styled.img`
    position: absolute;
    max-width: 98%;
    display: flex;
    justify-content: center;
    transform: translateX(-0.3px) translateY(0px) rotate(154deg) !important;
`
