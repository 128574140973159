import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import {
    isFadeBg,
    isFadePage,
    isFadeSub,
    isNoCards,
    selectIsFadeSub,
    selectSubPage,
    setPage,
} from '../../store/page/pageSlice'
import { useEffect, useRef } from 'react'
import { General } from './general/general'
import { CircularProgress, Fade } from '@mui/material'
import boy from './general/image/boyBig@3x.png'
import roundBase from './general/image/roundBaseBar@3x.png'
import roundInner from './general/image/roundInnerBar.png'
import setting from './general/image/settings@3x.png'
import {
    ContainerGeneral,
    ButtonSetting,
    Container,
    ContainerUserPhoto,
    Image,
    Image2,
    Image3,
    ImageButton,
    ContainerColumn,
    ContainerColumnIn,
} from './style'
import { Setting } from './settings/settings'

const progressBar = {
    position: 'absolute',
    transform: 'translateX(0px) translateY(0.5px) scale(-1, 1) rotate(23deg) !important',
    circle: {
        stroke: 'url(#linearColors)',
    },
}

export const Profile = () => {
    const subPage = useAppSelector(selectSubPage)
    const dispatch = useAppDispatch()
    const fadeMount = useAppSelector(selectIsFadeSub)

    const flag = useRef(0)

    useEffect(() => {
        if (flag.current === 0) {
            document.body.style.overflow = 'auto'
            flag.current = 1
            dispatch(isNoCards(false))
        }
    }, [subPage])
    const openSettings = () => {
        dispatch(setPage({ name: 'profile', subPage: 'setting', isFadeSub: false, isFadePage: true }))
    }

    return (
        <ContainerGeneral>
            <Container>
                <ContainerUserPhoto>
                    <Image src={boy} />
                    <Image2 src={roundBase} />
                    <Image3 src={roundInner} />
                    <svg width="0" height="0">
                        <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                            <stop offset="30%" stopColor="#afd6fd" />
                        </linearGradient>
                    </svg>
                    <CircularProgress
                        sx={progressBar}
                        size={'95.3%'}
                        thickness={1.7}
                        variant="determinate"
                        value={100 - 100}
                    />
                </ContainerUserPhoto>
                <Fade
                    in={subPage !== 'setting' && fadeMount}
                    onExited={() => {
                        dispatch(isFadeSub(true))
                    }}
                    mountOnEnter
                    unmountOnExit
                >
                    <ButtonSetting onClick={openSettings}>
                        <ImageButton src={setting} />
                    </ButtonSetting>
                </Fade>
            </Container>
            <ContainerColumn>
                <Fade
                    in={subPage === 'general' && fadeMount}
                    onExited={() => {
                        dispatch(isFadeSub(true))
                    }}
                    mountOnEnter
                    unmountOnExit
                >
                    <ContainerColumnIn>
                        <General />
                    </ContainerColumnIn>
                </Fade>
                <Fade
                    in={subPage === 'setting' && fadeMount}
                    onExited={() => {
                        dispatch(isFadeSub(true))
                        dispatch(isFadePage(true))
                        dispatch(isFadeBg(true))
                    }}
                    mountOnEnter
                    unmountOnExit
                >
                    <ContainerColumnIn>
                        <Setting />
                    </ContainerColumnIn>
                </Fade>
            </ContainerColumn>
        </ContainerGeneral>
    )
}
