import { useAppDispatch, useAppSelector } from '../../../hooks/hooks'
import { clearPage, selectCurrentLanguage, setCurrentLangugae } from '../../../store/page/pageSlice'
import React, { useState } from 'react'
import {
    BackArrow,
    GeneralContainer,
    Text,
    ActiveBlock,
    Block,
    BlockColumn1,
    BlockColumn2,
    Block2Item,
    BlockButtonDelete,
    TextRemove,
    TextHeaderRemove,
    BlockButtonCancel,
} from './style'
import { ButtonL } from '../../../components/button/button'
import { Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemButton, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import back from '../../store/image/back@3x.png'
import tick from './image/SmallTick.png'
import { delUserR, isRegStatusR, selectName, setClearAll } from '../../../store/authToken/auth'
import buttonFon from '../../store/image/layer702@3x.png'

const dialogTitle = {
    background: 'linear-gradient(90deg, rgba(172,229,255,1) 0%, rgba(255,214,237,1) 100%)',
    height: '30px',
}
const dialogContent = {
    padding: '0px 10px 30px 10px',
}
const list = {
    padding: '10px 0px 0px 0px',
}

const buttomItem = {
    paddingLeft: '30px',
    paddingRight: '30px',
    borderRadius: '10px',
}

export const Setting = () => {
    const [openLanguage, setOpenLanguage] = useState(false)
    const [openRemove, setOpenRemove] = useState(false)
    const dispatch = useAppDispatch()
    const name = useAppSelector(selectName)
    const currentLanguage = useAppSelector(selectCurrentLanguage)
    const openPopupLanguage = () => {
        setOpenLanguage(true)
    }
    const closePopupLanguage = () => {
        setOpenLanguage(false)
    }

    const closePopupRemove = () => {
        setOpenRemove(false)
    }

    const openPopupLogin = async () => {
        await dispatch(isRegStatusR(true))
    }
    const RemovePersonalOpen = () => {
        setOpenRemove(true)
    }

    const LogOut = async () => {
        await dispatch(clearPage())
        await dispatch(setClearAll())
        await window.location.reload()
    }
    const DelUserY = async () => {
        await dispatch(delUserR())
        await dispatch(clearPage())
        await dispatch(setClearAll())
        await window.location.reload()
    }

    const languages = [
        {
            name: 'English',
            value: 'en',
        },
    ]

    return (
        <GeneralContainer>
            <>
                <BlockColumn1>
                    <ButtonL name={'Language'} onClick={openPopupLanguage} icon={'language'} />
                    {name === null && <ButtonL name={'Login'} onClick={openPopupLogin} />}
                    {name !== null && <ButtonL name={'Log out'} onClick={LogOut} />}
                    <ButtonL name={'Remove personal data'} onClick={RemovePersonalOpen} />
                </BlockColumn1>
                <BlockColumn2>
                    <Block2Item>
                        <a href="http://wideview.games/terms-of-service-1.html">Terms of use</a>
                    </Block2Item>
                    <Block2Item>
                        <a href="http://wideview.games/privacy-policy.html">Privacy Policy</a>
                    </Block2Item>
                </BlockColumn2>
                <Dialog
                    open={openLanguage}
                    TransitionComponent={Transition}
                    keepMounted
                    fullWidth
                    maxWidth={'xs'}
                    PaperProps={{
                        style: { borderRadius: '11.7pt' },
                    }}
                    onClose={closePopupLanguage}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle sx={dialogTitle}>
                        <BackArrow onClick={closePopupLanguage}>
                            <img src={back} />
                        </BackArrow>
                    </DialogTitle>
                    <DialogContent sx={dialogContent}>
                        <List sx={list}>
                            {languages.map((language) => (
                                <ListItem
                                    onClick={() => {
                                        console.log(language.value)
                                        dispatch(setCurrentLangugae(language.value))
                                    }}
                                    key={language.value}
                                    disablePadding
                                >
                                    <ListItemButton sx={buttomItem}>
                                        <Block>
                                            <Text>{language.name}</Text>
                                            {currentLanguage === language.value && (
                                                <ActiveBlock>
                                                    <img src={tick} />
                                                </ActiveBlock>
                                            )}
                                        </Block>
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={openRemove}
                    TransitionComponent={Transition}
                    keepMounted
                    fullWidth
                    maxWidth={'xs'}
                    PaperProps={{
                        style: { borderRadius: '11.7pt' },
                    }}
                    onClose={closePopupRemove}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle sx={dialogTitle}>
                        <TextHeaderRemove>Attention!</TextHeaderRemove>
                    </DialogTitle>
                    <DialogContent sx={dialogContent}>
                        <TextRemove>
                            You are about to delete your account. This action cannot be undone. You will be looged out,
                            you will lose all your progress and all the purchased items.
                        </TextRemove>
                    </DialogContent>
                    <DialogActions>
                        <BlockButtonDelete
                            onClick={() => {
                                DelUserY()
                            }}
                        >
                            <img src={buttonFon} />
                            <span>Delete</span>
                        </BlockButtonDelete>
                        <BlockButtonCancel onClick={closePopupRemove}>
                            <img src={buttonFon} />
                            <span>Cancel</span>
                        </BlockButtonCancel>
                    </DialogActions>
                </Dialog>
            </>
        </GeneralContainer>
    )
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction={props.in ? 'left' : 'right'} ref={ref} {...props} />
})
