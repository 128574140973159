import {
    Bg,
    ContainerGeneral,
    HeaderText,
    ContainerBlock,
    ContainerBlockCenter,
    ContainerBlockRight,
    ContainerBlockLeft,
    BottonPhoto,
    AvatarBackgroundImage,
    ImgBlockLeftStatusOnline,
    Avatar,
    ImgBlockLeftStatusVip,
    ContainerGeneralProgress,
} from './style'
import bg from './image/base@3x.png'
import bgPhoto from './image/directAvatarBase@3x.png'
import photoButton from './image/photosButton@3x.png'
import onlineStatus from './image/onlineIndicatorCopy@3x.png'
import offline from './image/offlineIndicator@3x.png'
import vip from './image/vipIconText@3x.png'
import { useEffect, useMemo, useState } from 'react'
import { isBigInfo, isFirstOpen, isNoCards, selectPage, setPage } from '../../store/page/pageSlice'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { getChats, selectChats, setChat } from '../../store/chat/chat'
import { textFormatIcon } from '../../helper/textEdit'
import { selectUserInfo } from '../../store/authToken/auth'
import { CircularProgress } from '@mui/material'

export const Chats = () => {
    const dispatch = useAppDispatch()
    const page = useAppSelector(selectPage)
    const idTokenData = useAppSelector(selectUserInfo)
    const chats = useAppSelector(selectChats)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        const start = async () => {
            dispatch(isNoCards(false))
            dispatch(isFirstOpen(false))
            setIsLoading(true)
            await dispatch(getChats())
            setIsLoading(false)
            document.body.style.overflow = 'auto'
        }
        start()
    }, [])

    const openClick = async (val: any) => {
        dispatch(isBigInfo(true))
        dispatch(setChat(val))
        dispatch(
            setPage({
                name: 'chatItem',
                subPage: 'general',
                isFadeSub: false,
                isFadePage: false,
                isFadeBg: true,
                oldPage: page,
            }),
        )
    }
    const openGallery = async (e: any, val: any) => {
        e.stopPropagation()
        dispatch(isBigInfo(true))
        dispatch(setChat(val))
        dispatch(
            setPage({
                name: 'gallery',
                subPage: 'general',
                isFadeSub: false,
                isFadePage: false,
                isFadeBg: true,
                oldPage: page,
            }),
        )
    }
    const lastMessage = (arr: any): string => {
        let res = ''
        for (let i = 0; i < arr.length; i++) {
            if (arr[i].type === '') {
                res = lenSimvol(arr[i].translations.en)
                break
            }
        }
        return res
    }
    const lenSimvol = (text: string) => {
        let newText = ''
        if (window.innerWidth > 756) {
            if (text.length < 50) {
                newText = textFormatIcon(text)
            } else {
                newText = text.substr(0, 50) + '...'
                newText = textFormatIcon(newText)
            }
        } else {
            newText = text.substr(0, 50) + '...'
            newText = textFormatIcon(newText)
        }
        return newText
    }

    const sortedChats = useMemo(
        () =>
            Object.assign([], chats)?.sort(
                (a: any, b: any) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
            ),
        [chats],
    )
    return (
        <ContainerGeneral>
            <HeaderText>Chats</HeaderText>
            {sortedChats.map((val: any) => {
                return (
                    <Bg key={val.character.id} onClick={() => openClick(val.character)}>
                        <img className={'bg'} src={bg} />
                        <ContainerBlock>
                            <ContainerBlockLeft>
                                <AvatarBackgroundImage src={bgPhoto} />
                                <Avatar src={val.character.chat_pic_url} />
                                <ImgBlockLeftStatusOnline src={val.is_online ? onlineStatus : offline} />
                                {val.character.rarity === 1 &&
                                    !idTokenData.unlocked_characters.includes(val.character.id) && (
                                        <ImgBlockLeftStatusVip gray={true} src={vip} />
                                    )}
                            </ContainerBlockLeft>
                            <ContainerBlockCenter>
                                <div>
                                    <span>{val.character.profile_name.English}</span>
                                    {val.last_chat_messages.translations !== null && (
                                        <span
                                            className={'message'}
                                            dangerouslySetInnerHTML={{ __html: lastMessage(val.last_chat_messages) }}
                                        ></span>
                                    )}
                                    {val.last_chat_messages.translations === null && (
                                        <span className={'message'}>Photo</span>
                                    )}
                                </div>
                            </ContainerBlockCenter>
                            <ContainerBlockRight>
                                <BottonPhoto onClick={(e) => openGallery(e, val.character)}>
                                    <img src={photoButton} />
                                </BottonPhoto>
                            </ContainerBlockRight>
                        </ContainerBlock>
                    </Bg>
                )
            })}
            {sortedChats.length === 0 && isLoading && (
                <ContainerGeneralProgress>
                    <CircularProgress />
                </ContainerGeneralProgress>
            )}
            {sortedChats.length === 0 && !isLoading && <ContainerGeneralProgress>No chats</ContainerGeneralProgress>}
        </ContainerGeneral>
    )
}
