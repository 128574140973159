import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import { useEffect, useRef, useState } from 'react'
import { BlockCanvas, Canvas, ElementTutur, ElementTuturBox, ImageBlockCanvas } from './styleImageCanvas'
import {
    getChat,
    getChatImages,
    getChatPriceDate,
    imageDateCanvas,
    selectChat,
    setCanvasOpen,
} from '../store/chat/chat'
import { selectTuturClear, setTuturClear, selectFirstRegistrationFlag } from '../store/authToken/auth'
import { setOpenLoginInfo } from '../store/storeShop/shop'
export const ImageCanvas = () => {
    const urlImg = useAppSelector(imageDateCanvas)
    let flagImage = useRef(1)
    const [sizeW, setSizeW] = useState(0)
    const [opacityImage, setOpacityImage] = useState(0)
    const [opacityCanvas, setOpacityCanvas] = useState(0)
    const chat = useAppSelector(selectChat)
    const tutorClear = useAppSelector(selectTuturClear)
    const isNotRegistered = useAppSelector(selectFirstRegistrationFlag)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (flagImage.current === 1) {
            let size = window.innerWidth
            if (window.innerWidth > 600) {
                size = 600
                setSizeW(size)
            } else {
                size = window.innerWidth
                setSizeW(size)
            }
            flagImage.current = 0
            let canvas: any = document.getElementById('canvas')
            let ctx = canvas.getContext('2d')

            let isDown = false
            let radius = 40
            let pi2 = Math.PI * 2
            let img = new Image()

            let start = (a: any) => {
                setOpacityCanvas(1)
                ctx.drawImage(a, 0, 0, canvas.width, canvas.height)
                ctx.globalCompositeOperation = 'destination-out'
                if (window.innerWidth > 600) {
                    canvas.onmousedown = handleMouseDown
                    canvas.onmousemove = handleMouseMove
                    window.onmouseup = handleMouseUp
                } else {
                    canvas.ontouchstart = handleMouseDown
                    canvas.ontouchmove = handleTouchMove
                    window.ontouchend = handleMouseUp
                }
            }
            img.crossOrigin = 'Anonymous'
            img.src = urlImg?.url2 + '?q=1'
            img.onload = function () {
                let a = this
                start(a)
            }
            let handleMouseDown = (e: any) => {
                if (tutorClear) {
                    dispatch(setTuturClear(false))
                }
                isDown = true
                let pos = getXY(e)
                erase(pos.x, pos.y)
            }

            let handleMouseUp = () => {
                let arr = ctx.getImageData(0, 0, size, size).data
                let count = 0
                let res = 0
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i] === 0) {
                        count++
                    }
                }
                res = (count * 100) / arr.length

                if (res >= 60) {
                    dispatch(getChat(chat.id))
                    setOpacityImage(1)
                    setOpacityCanvas(0)
                    canvas.onmousedown = null
                    canvas.onmousemove = null
                    window.onmouseup = null
                    canvas.ontouchstart = null
                    canvas.ontouchmove = null
                    window.ontouchend = null
                }
                isDown = false
            }

            let handleTouchMove = (e: any) => {
                if (!isDown) return
                let pos = getXYTouch(e)
                erase(pos.x, pos.y)
            }
            let handleMouseMove = (e: any) => {
                if (!isDown) return
                let pos = getXY(e)
                erase(pos.x, pos.y)
            }

            let getXY = (e: any) => {
                let rect = canvas.getBoundingClientRect()
                return {
                    x: e.clientX - rect.left,
                    y: e.clientY - rect.top,
                }
            }
            let getXYTouch = (e: any) => {
                let rect = canvas.getBoundingClientRect()
                let touch = e.touches[0]
                return {
                    x: touch.clientX - rect.left,
                    y: touch.clientY - rect.top,
                }
            }

            let erase = (x: any, y: any) => {
                ctx.beginPath()
                ctx.arc(x, y, radius, 0, pi2)
                ctx.fill()
            }
        }
    }, [])

    const close = async (event: any) => {
        if (event.currentTarget === event.target) {
            if (opacityImage === 1) {
                setOpacityImage(0)
                setOpacityCanvas(0)
                await dispatch(getChatImages(chat.id))
                await dispatch(getChatPriceDate(chat.id))
                dispatch(setCanvasOpen(false))

                if (isNotRegistered) {
                    dispatch(setOpenLoginInfo(true))
                }
            }
        }
    }

    return (
        <BlockCanvas onClick={close}>
            <ElementTuturBox>
                {opacityImage === 1 && <ImageBlockCanvas src={urlImg.url} />}
                <Canvas id="canvas" height={sizeW} width={sizeW} image={urlImg?.url} opacity={opacityCanvas} />
                {tutorClear && <ElementTutur />}
            </ElementTuturBox>
        </BlockCanvas>
    )
}
