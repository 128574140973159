import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { useEffect, useState } from 'react'

import { selectChatImages, getChatImages, postChatResourcesId, selectChat, setCanvasOpen } from '../../store/chat/chat'
import {
    BoxImage,
    Container,
    ContainerImage,
    ContainerImages,
    BoxImageGen,
    BoxImageFon,
    ContainerImageGen,
    ContainerImageFon,
} from './style'
import hot from './image/galleryHotFrame@3x.png'
import { CircularProgress } from '@mui/material'
import { MessageButtonCurrencyBackground, ChatBottomGift2 } from '../chatItem/style'
import vipButton from '../chatItem/image/vipYellBut@3x.png'
import greenButton from '../chatItem/image/bigGreenButton@3x.png'
import hotLock from '../chatItem/image/hotLock@3x.png'
import { getCurrentUser } from '../../store/authToken/auth'
import { useXsollaPayments } from '../../hooks/useXsollaPayments'
import { setChatNameSubscriptionModal } from '../../store/storeShop/shop'

export const Gallery = () => {
    const dispatch = useAppDispatch()
    const chat = useAppSelector(selectChat)
    const chatImages = useAppSelector(selectChatImages)
    const [image, setImage] = useState({ image: '', type: '', id: '', idName: '' })
    const { boughtBundles } = useXsollaPayments()

    useEffect(() => {
        const start = async () => {
            await dispatch(getChatImages(chat.id))
        }
        start()
    }, [])

    useEffect(() => {
        if (chatImages && image.image !== '') {
            let currentImage = chatImages?.messages.find(
                (element: any) => Object.keys(element.resources_urls)[0] === image.idName,
            )
            setImage({
                image: chatImages.resources_urls[Object.keys(currentImage.resources_urls)[0]],
                type: '',
                id: currentImage.id,
                idName: Object.keys(currentImage.resources_urls)[0],
            })
        }
        console.log(chatImages)
    }, [chatImages])

    const BuyPhoto = async (id: string, idRes: string, url: string) => {
        if (boughtBundles.allPhotosSubscription) {
            await dispatch(
                postChatResourcesId({
                    id: chat.id,
                    message_id: id,
                    idResources: idRes,
                    url: url,
                }),
            )
            await dispatch(getCurrentUser())
            dispatch(setCanvasOpen(true))
        } else {
            dispatch(setChatNameSubscriptionModal(chat.id))
        }
    }

    return (
        <Container>
            {chatImages === null && (
                <>
                    <CircularProgress />
                </>
            )}

            {chatImages !== null && (
                <>
                    <ContainerImage>
                        <ContainerImageGen
                            src={
                                image.image ||
                                chatImages.messages[0].resources_urls[
                                    Object.keys(chatImages.messages[0].resources_urls)[0]
                                ]
                            }
                        />

                        {chatImages.messages[0].id !== image.id &&
                            (!boughtBundles.allPhotosSubscription || !(image.id in chatImages.resources)) &&
                            image.image !== '' && (
                                <>
                                    <ContainerImageFon src={hot} />
                                    <ChatBottomGift2 onClick={() => BuyPhoto(image.id, image.idName, image.image)}>
                                        <div className="so-hot-message">
                                            <div>WOW!</div>
                                            <div>SO HOT!</div>
                                        </div>

                                        <button>
                                            {!boughtBundles.allPhotosSubscription && (
                                                <img className="hot-lock" src={hotLock} />
                                            )}
                                            <MessageButtonCurrencyBackground
                                                src={boughtBundles.allPhotosSubscription ? greenButton : vipButton}
                                            />
                                            <span>OPEN</span>
                                        </button>
                                    </ChatBottomGift2>
                                </>
                            )}
                    </ContainerImage>

                    <ContainerImages>
                        {chatImages.messages?.map((image: any) => {
                            return (
                                <BoxImage
                                    key={image.id}
                                    onClick={() => {
                                        setImage({
                                            image:
                                                !(image.id in chatImages.resources) ||
                                                !boughtBundles.allPhotosSubscription
                                                    ? image.resources_urls[Object.keys(image.resources_urls)[0]]
                                                    : chatImages.resources_urls[Object.keys(image.resources_urls)[0]],
                                            type: image.type,
                                            id: image.id,
                                            idName: Object.keys(image.resources_urls)[0],
                                        })
                                    }}
                                >
                                    {(!boughtBundles.allPhotosSubscription || !(image.id in chatImages.resources)) && (
                                        <>
                                            <BoxImageGen
                                                src={image.resources_urls[Object.keys(image.resources_urls)[0]]}
                                            />
                                            {chatImages.messages[0].id !== image.id && <BoxImageFon src={hot} />}
                                        </>
                                    )}

                                    {boughtBundles.allPhotosSubscription && image.id in chatImages.resources && (
                                        <>
                                            <BoxImageGen
                                                src={chatImages.resources_urls[Object.keys(image.resources_urls)[0]]}
                                            />
                                        </>
                                    )}
                                </BoxImage>
                            )
                        })}
                    </ContainerImages>
                </>
            )}
        </Container>
    )
}
