import axios, { AxiosResponse } from 'axios'
import { store } from '../store/store'
import { refreshTokenAsync } from '../store/authToken/auth'
import { isFirstOpen, isNoCards } from '../store/page/pageSlice'
import { isStatus, setTimer } from '../store/swipe/swipeSlice'

export const API_URL =
    window.location.origin === 'https://loverzgame.com'
        ? 'https://fx6rvngdmll3ihvgedkv5ki4e40voazd.lambda-url.eu-central-1.on.aws/api'
        : 'https://3tqeqh4cyfzicioq5xxpvjrysy0ggrdn.lambda-url.eu-central-1.on.aws/api'

export const URL_METRIK = 'https://1l-api.my.games/events/119490'

export function fetchCount(amount = 1) {
    return new Promise<{ data: number }>((resolve) => setTimeout(() => resolve({ data: amount }), 500))
}

export const $api = axios.create({
    baseURL: API_URL,
})

export const $api2 = axios.create({
    baseURL: API_URL,
})

export const $api3 = axios.create({
    baseURL: URL_METRIK,
})

$api.interceptors.request.use((config) => {
    const storer = store.getState().auth
    // @ts-ignore
    config.headers.Authorization = `Bearer ${storer.access_token}`
    return config
})

// $api2.interceptors.response.use(
//     async (config) => {
//         await store.dispatch(isFirstOpen(true))
//         return config
//     },
//     async (error) => {
//         const storer = store.getState().auth

//         if (error.response.status === 401 && error.response.config.url.match(/refresh-access-token/).length > 0) {
//             store.dispatch(clearPage())
//             store.dispatch(setRegStatus(''))
//             store.dispatch(authAsync())

//             return $api.request(storer.failRequest)
//         }
//     },
// )

$api.interceptors.response.use(
    async (config) => {
        await store.dispatch(isFirstOpen(true))
        return config
    },
    async (error) => {
        const originalRequest = error.config
        const refreshToken = store.getState().auth.refresh_token

        if (
            error.response.status === 401 &&
            !originalRequest._retry &&
            !error.response.config.url.match(/refresh-access-token/)
        ) {
            originalRequest._retry = true
            await store.dispatch(refreshTokenAsync({ token: refreshToken, data: originalRequest }))
            return $api(originalRequest)
        }

        if (error.response.status === 402) {
            store.dispatch(isStatus(false))
        }

        if (error.response.status === 429) {
            store.dispatch(setTimer(error.response.headers['retry-after']))
            store.dispatch(isNoCards(true))
            store.dispatch(isFirstOpen(true))
        }

        return Promise.reject(error)
    },
)

export function authAnonimuse(): Promise<AxiosResponse<any>> {
    return axios.post(API_URL + '/users/anonymous')
}
export function refreshToken(token: string): Promise<AxiosResponse<any>> {
    return $api.post(API_URL + '/users/refresh-access-token', { token })
}

export function tokenXsolla(token: string): Promise<AxiosResponse<any>> {
    return $api.post(API_URL + '/users/link-xsolla', { token })
}
export function delUser(): Promise<AxiosResponse<any>> {
    return $api.delete(`/users/me`)
}
export function eventMail(data: any): Promise<AxiosResponse<any>> {
    return $api3.post(API_URL + '/events/119490', data)
}
export function getCurrentUser(): Promise<AxiosResponse<any>> {
    return $api.get(API_URL + '/users/me')
}
