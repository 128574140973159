import styled from 'styled-components/macro'
import '@fontsource/roboto/900.css'
import '@fontsource/roboto/300.css'

interface IImgBlockLeftStatusVip {
    gray?: boolean
}
export const ContainerGeneral = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    @media (max-width: 768px) {
        height: 100%;
    }
`
export const ContainerGeneralProgress = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    font-size: 14pt;
    font-family: Roboto, sans-serif;
    color: rgb(0, 0, 117);
    font-weight: 900;
    @media (max-width: 768px) {
        height: 100%;
    }
`
export const HeaderText = styled.div`
    display: flex;
    font-size: 14pt;
    font-family: 'Roboto', sans-serif;
    color: rgb(0, 0, 117);
    padding-left: 10px;
    font-weight: 900;
    margin-top: 25pt;
    margin-bottom: 11pt;
`
export const Bg = styled.div`
    width: 100%;
    position: relative;
    margin-bottom: 10px;
    img.bg {
        max-width: 100%;
    }

    img.pause {
        position: absolute;
        top: -3%;
        left: 1%;
        cursor: pointer;
        width: 7%;
        :hover {
            transform: scale(1.1);
        }
    }
`
export const ContainerBlock = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 3% 2% 6% 5%;
    box-sizing: border-box;
    top: 0;
    cursor: pointer;
`
export const ContainerBlockCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 3;
    width: 100%;
    div {
        display: flex;
        height: 74.3%;
        flex-direction: column;
        overflow: hidden;
    }
    span {
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        font-size: 14pt;
        padding-left: 7%;
        color: rgb(0, 0, 117);
        @media (max-width: 768px) {
            font-size: 11pt;
        }
    }
    .message {
        margin-top: 3%;
        font-family: 'Roboto', sans-serif;
        font-weight: 300;
    }
`
export const ContainerBlockLeft = styled.div`
    display: flex;
    position: relative;
    flex: 1.3;
    justify-content: center;
    align-items: center;
`
export const AvatarBackgroundImage = styled.img`
    max-width: 100%;
    object-fit: contain;
    display: block;
`
export const Avatar = styled.img`
    width: 92%;
    position: absolute;
    border-radius: 100%;
    @media (max-width: 768px) {
        width: 92%;
    }
`
export const ImgBlockLeftStatusOnline = styled.img`
    object-fit: contain;
    display: block;
    position: absolute;
    width: 24%;
    bottom: -3%;
    right: 12%;
`
export const ImgBlockLeftStatusVip = styled.img<IImgBlockLeftStatusVip>`
  object-fit: contain;
  display: block;
  position: absolute;
  width: 39%;
  bottom: -1%;
  left: -1%;
  filter:  ${(props) => (props.gray ? 'grayscale(0%)' : 'grayscale(100%)')};grayscale(100%);
`
export const ContainerBlockRight = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    flex: 1;
`
export const BottonPhoto = styled.button`
    border: none;
    outline: none;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0;
    :hover {
        transform: scale(1.1);
    }
    img {
        max-width: 85%;
        margin-top: 9%;
        object-fit: contain;
        display: block;
    }
    cursor: pointer;
    background: none;
`
