import { useEffect, useMemo, useState } from 'react'
import './App.css'
import { DashboardLayout } from './dashboards/dashboard-layout'
import { Pages } from './page/pages'

import { useAppDispatch, useAppSelector } from './hooks/hooks'
import {
    authAsync,
    registrationEvent,
    selectAccess_token,
    selectRegStatus,
    selectUserInfo,
    selectUserUid,
    setOpenPart,
    setRegStatus,
} from './store/authToken/auth'
import { BgBlockImgContariner, BgBlockImgPlay } from './page/style'
import buttonFon from './page/store/image/layer702@3x.png'
import ReactPixel from 'react-facebook-pixel'
import TagManager from 'react-gtm-module'
import { Adsense } from '@ctrl/react-adsense'
import { setCurrentCoins } from './store/page/pageSlice'

if (window.location.origin === 'https://loverzgame.com') {
    ReactPixel.init('774246260602914')
    ReactPixel.pageView()
}

TagManager.initialize({
    gtmId: 'GTM-P2XCZ6GQ',
})

function App() {
    const dispatch = useAppDispatch()
    const token = useAppSelector(selectAccess_token)
    const userId = useAppSelector(selectUserUid)
    const userInfo = useAppSelector(selectUserInfo)
    const [loadButton, setLoadButton] = useState(false)
    const [loadEvent, setLoadEvent] = useState(false)
    const [is1LinkAdded, setIs1LinkAdded] = useState(false)
    const regStatus = useAppSelector(selectRegStatus)
    const [isCurrentCoinsSet, setIsCurrentCoinsSet] = useState(false)

    const start = () => {
        // @ts-ignore
        document.querySelector('.preLoderBox').remove()
        setLoadButton(false)
        setLoadEvent(false)
    }

    useMemo(() => {
        if (userInfo === null) return

        if (!isCurrentCoinsSet) {
            dispatch(setCurrentCoins(userInfo.coin))
            setIsCurrentCoinsSet(true)
        }

        if (regStatus !== 'reg') {
            dispatch(setRegStatus('reg'))

            setTimeout(() => {
                setLoadEvent(true)
                dispatch(registrationEvent(userInfo))
            }, 1000)
        }
    }, [userInfo])

    useEffect(() => {
        if (userId !== null && !is1LinkAdded) {
            setIs1LinkAdded(true)

            if (window.location.origin === 'https://loverzgame.com') {
                let partnerScript = document.createElement('script')
                partnerScript.type = 'text/javascript'
                partnerScript.async = true
                partnerScript.src =
                    '//1l-hit.my.games/v1/hit/119490.js?r=' +
                    encodeURIComponent(document.referrer) +
                    '&l=' +
                    encodeURIComponent(window.location.href) +
                    '&u=' +
                    encodeURIComponent(userId) +
                    '&rnd=' +
                    Math.random()
                let firstScript = document.getElementsByTagName('script')[0]
                firstScript?.parentNode?.insertBefore(partnerScript, firstScript)
            }
        }
    }, [userId])

    useMemo(() => {
        const zIndexBlock = document.querySelector('.zIndex')
        if (loadButton && loadEvent && zIndexBlock) {
            // @ts-ignore
            zIndexBlock.remove()
        }
    }, [loadButton, loadEvent])

    useEffect(() => {
        const anonymous = async function () {
            // @ts-ignore
            window.interval = setInterval(() => {
                if (document.readyState === 'complete') {
                    setLoadButton(true)
                    if (window.location.origin !== 'https://loverzgame.com') {
                        // @ts-ignore
                        document.querySelector('.zIndex').remove()
                    }
                    // @ts-ignore
                    clearInterval(window.interval)
                }
            }, 1000)

            if (token === 'none') {
                await dispatch(authAsync())
                await dispatch(setOpenPart(true))
            } else {
                await dispatch(setOpenPart(false))
                if (window.location.origin === 'https://loverzgame.com') {
                    setLoadEvent(true)
                }
            }
        }

        anonymous()
    }, [])

    return (
        <>
            <DashboardLayout>
                <Pages />
            </DashboardLayout>
            {window.location.origin === 'https://loverzgame.com' && (
                <Adsense
                    client="ca-pub-6464532605001573"
                    slot="2761899634"
                    style={{ display: 'block', position: 'fixed', bottom: '0px' }}
                    layout="in-article"
                    format="fluid"
                />
            )}

            {window.location.origin === 'https://loverzgame.com' && (
                <>
                    {loadButton && loadEvent && (
                        <BgBlockImgContariner onClick={start}>
                            <BgBlockImgPlay>
                                <img src={buttonFon} />
                                <span>Play</span>
                            </BgBlockImgPlay>
                        </BgBlockImgContariner>
                    )}
                </>
            )}

            {window.location.origin !== 'https://loverzgame.com' && (
                <>
                    {loadButton && (
                        <BgBlockImgContariner onClick={start}>
                            <BgBlockImgPlay>
                                <img src={buttonFon} />
                                <span>Play</span>
                            </BgBlockImgPlay>
                        </BgBlockImgContariner>
                    )}
                </>
            )}
        </>
    )
}

export default App
