import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getCharacters, postLikeDislike, postSkip } from '../../api/swipeApi'
import { RootState } from '../store'

export interface PageState {
    swipe: any
    status: boolean
    macthData: any
    macthStatus: boolean
    paidStatus: boolean
    timer: number
    reload_characters: boolean
}

const initialState: PageState = {
    swipe: [],
    status: false,
    macthData: null,
    macthStatus: false,
    paidStatus: false,
    timer: 61,
    reload_characters: false,
}

export const getSwipe = createAsyncThunk('swipe/getSwipe', async () => {
    const response = await getCharacters()
    return response.data
})
export const postSkipR = createAsyncThunk('swipe/postSkip', async (skip: string) => {
    const response = await postSkip(skip)
    return response.data
})

export const postSwipeLikeDislike = createAsyncThunk('swipe/postSwipeLikeDislike', async (userAction: any) => {
    const response = await postLikeDislike(userAction.id, userAction.action)
    return response.data
})

export const swipeSlice = createSlice({
    name: 'swipe',
    initialState,
    reducers: {
        changeSwipe: (state, action) => {
            let arr = JSON.parse(JSON.stringify(action.payload))
            if (arr.length === 0) {
                state.swipe = arr
            } else {
                arr.pop()
                state.swipe = arr
            }
        },
        isStatus: (state, action) => {
            state.status = action.payload
        },

        isStatusMacth: (state, action) => {
            state.macthStatus = action.payload
        },
        isPaidStatus: (state, action) => {
            state.paidStatus = action.payload
            state.macthStatus = action.payload
        },
        setTimer: (state, action) => {
            state.timer = action.payload
        },
        isReloadCharacters: (state, action) => {
            state.reload_characters = action.payload
        },
    },
    extraReducers(builder) {
        builder.addCase(getSwipe.fulfilled, (state, action) => {
            let a = action.payload
            state.swipe = a.reverse()
            state.reload_characters = false
        })
        builder.addCase(getSwipe.pending, () => {})
        builder.addCase(postSwipeLikeDislike.pending, () => {})
        builder.addCase(postSwipeLikeDislike.fulfilled, (state, action) => {
            state.macthData = action.payload
            if (action.payload.character !== null) {
                // state.paidStatus = action.payload.character.paid
            } else {
                state.paidStatus = false
            }
            state.reload_characters = action.payload.reload_characters
            state.macthStatus = action.payload.match
            state.status = false
        })
    },
})

export const { changeSwipe, isStatus, isStatusMacth, isPaidStatus, setTimer, isReloadCharacters } = swipeSlice.actions

export const selectSwipe = (state: RootState) => state.swipeSlice.swipe
export const selectStatus = (state: RootState) => state.swipeSlice.status
export const selectMatchStatus = (state: RootState) => state.swipeSlice.macthStatus
export const selectPaidStatus = (state: RootState) => state.swipeSlice.paidStatus
export const selectMacthData = (state: RootState) => state.swipeSlice.macthData
export const selectTimer = (state: RootState) => state.swipeSlice.timer
export const selectReloadCharacters = (state: RootState) => state.swipeSlice.reload_characters
export default swipeSlice.reducer
