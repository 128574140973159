import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit'
import counterReducer from '../features/counter/counterSlice'
import pageReducer from './page/pageSlice'
import authReducer from './authToken/auth'
import { persistReducer } from 'reduxjs-toolkit-persist'
import storage from 'reduxjs-toolkit-persist/lib/storage'
import autoMergeLevel2 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel2'
import swipeReducer from './swipe/swipeSlice'
import chatReducer from './chat/chat'
import shopReducer from './storeShop/shop'

const reducers = combineReducers({
    counter: counterReducer,
    page: pageReducer,
    auth: authReducer,
    swipeSlice: swipeReducer,
    chatSlice: chatReducer,
    shopSlice: shopReducer,
})

const persistConfig = {
    key: 'root',
    storage,
    version: 1,
    timeout: 500,
    blacklist: ['swipeSlice', 'shopSlice'],
    stateReconciler: autoMergeLevel2,
}

const persistedReducer = persistReducer<any, any>(persistConfig, reducers)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
