import styled, { keyframes } from 'styled-components/macro'
import '@fontsource/roboto/900.css'
import '@fontsource/roboto/300.css'

interface TextLeft {
    bgColor: string
    colorText?: string
    isUser?: boolean
}
interface TextRight {
    bgColor: string
    colorText?: string
}
interface ChatButtonsI {
    borderColor?: string
}
interface ContainerGeneralI {
    heightP?: number
}
interface BlockDateCharacterI {
    scale?: number
}
const opacitychange = keyframes`
  0%, 100%{
    opacity: 0;
  }

  60%{
    opacity: 1;
  }
`
export const ContainerGeneral = styled.div<ContainerGeneralI>`
    position: relative;
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    height: 100%;
    @media (max-width: 768px) {
        height: 100%;
        width: calc(100% + 32px);
        left: -16px;
    }
`

export const LoaderPoints = styled.div`
    border-radius: 100%;
    position: relative;
    display: flex;
    margin: 0 auto;
    span {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #3498db;
        margin: 5px 5px;
        opacity: 0;
    }
    span:nth-child(1) {
        animation: ${opacitychange} 1s ease-in-out infinite;
    }

    span:nth-child(2) {
        animation: ${opacitychange} 1s ease-in-out 0.33s infinite;
    }

    span:nth-child(3) {
        animation: ${opacitychange} 1s ease-in-out 0.66s infinite;
    }
`

export const ChatScroll = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
`
export const ChatBlock = styled.div`
    padding: 35px 24px;
    :first-child {
        margin-top: auto !important;
    }
    @media (max-width: 768px) {
        padding: 60px 16px 25px 16px;
    }
`
export const ChatBlockDate = styled.div`
    padding: 35px 24px;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    @media (max-width: 768px) {
        padding: 60px 16px 25px 16px;
    }
`
export const ChatMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
    :first-child {
        margin-top: auto !important;
    }
    .left {
        opacity: 0;
    }
    :last-child .left {
        opacity: 1;
    }
    .leftChat .left {
        opacity: 1;
    }
`
export const ChatDate = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    .left {
        opacity: 0;
    }
    :last-child .left {
        opacity: 1;
    }
    .leftChat .left {
        opacity: 1;
    }
`
export const UserMessageContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    position: relative;
`
export const ChatCenter = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
`
export const ChatRightText = styled.div<TextRight>`
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 17px;
    position: relative;
    background: ${(props) => props.bgColor || '#e8e8e8;'};
    color: ${(props) => props.colorText || '#fff;'};
    border: 0.7px solid rgb(233 220 226);
    box-shadow: inset 0px -1.3px 0.3px 0px rgba(98 19 154, 0.11);
    padding: 14px 11px 14px 19px;
    border-radius: 12px;
    border-bottom-right-radius: 0px;
    margin-bottom: 8px;
    max-width: 78%;
`
export const ChatNameSend = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 14px;
    height: 17px;
    padding: 0px 10px;
    -webkit-transform: skew(-20deg);
    -ms-transform: skew(-20deg);
    transform: skew(-20deg);
    background: #d4d5da;
    position: absolute;
    top: -7px;
    white-space: nowrap;
    margin: auto;
    text-align: center;
    border-radius: 5px;
`
export const ChatCenterText = styled.div<TextRight>`
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 17px;
    position: relative;
    background: ${(props) => props.bgColor || '#e8e8e8;'};
    color: ${(props) => props.colorText || '#fff;'};
    border: 0.7px solid rgb(233 220 226);
    box-shadow: inset 0px -1.3px 0.3px 0px rgba(98 19 154, 0.11);
    padding: 14px 11px 14px 19px;
    border-radius: 12px;
    margin-bottom: 8px;
    max-width: 78%;
`
export const ChatRightIconLike = styled.img`
    position: absolute;
    left: -12px;
    bottom: -5px;
    max-width: 30px;
`
export const MessageReaction = styled.img`
    max-width: 30px;
    position: absolute;
    right: -12px;
    bottom: -5px;
`
export const ChatLeftIconLikeSelect = styled.img`
    max-width: 30px;
`
export const AvatarImage = styled.img`
    width: 88%;
    position: absolute;
    border-radius: 100%;
    @media (max-width: 768px) {
        width: 92%;
    }
`
export const BotMessageContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
`
export const ChatLeftAvatar = styled.div``
export const BotImageMessageContainer = styled.div`
    display: flex;
    flex-direction: column;
`
export const TextMessageContainer = styled.div<TextLeft>`
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 17px;
    background: ${(props) => props.bgColor || '#e8e8e8;'};
    color: ${(props) => props.colorText || '#000;'};
    border: 0.7px solid rgb(233 220 226);
    box-shadow: inset 0px -1.3px 0.3px 0px rgba(98 19 154, 0.01);
    padding: ${(props) => (props.isUser ? '14px 11px 14px 19px' : '14px 21px 14px 11px')};
    border-radius: 12px;
    border-bottom-left-radius: 0px;
    margin-bottom: 8px;
    position: relative;
    max-width: 78%;
`
export const MessageImageContainer = styled.div<TextLeft>`
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 17px;
    background: ${(props) => props.bgColor || '#e8e8e8;'};
    border: 0.7px solid rgb(233 220 226);
    box-shadow: inset 0px -1.3px 0.3px 0px rgba(98 19 154, 0.01);
    padding: 14px 14px 14px 14px;
    border-radius: 12px;
    border-bottom-left-radius: 0px;
    margin-bottom: 8px;
    position: relative;
    width: fit-content;
    max-width: 78%;
    height: 78%;
`
export const BlurredImageMessage = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .so-hot-message {
        bottom: 40%;
        position: absolute;
        font-size: 28px;
        font-weight: 800;
        color: #f3dc6d;
        text-align: center;
    }
`
export const MessageImage = styled.img`
    border-radius: 10px;
    max-width: 100%;
`
export const ImageMessageBackground = styled.img`
    position: absolute;
    top: 0px;
    max-width: 100%;
    left: 0px;
    right: 0px;
    border-radius: 10px;
    pointer-events: none;
`
export const ChatLeftTextExtraPhoto = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 14px;
    color: white;
`
export const AvatarContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 31pt;
    min-width: 31pt;
    height: 31pt;
    margin-right: 7px;
    bottom: 8px;
`
export const TextitsAMAtch = styled.span`
    color: rgb(164 27 109);
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    width: 75%;
    font-size: 28px;
    text-align: center;
    color: white;
    @media (max-width: 768px) {
        font-size: 20px;
    }
`
export const TextitsAMAtchPhoto = styled.span`
    color: rgb(164 27 109);
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    width: 100%;
    font-size: 23px;
    text-align: center;
    color: white;
`
export const GiftBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    margin-top: 10%;
    img {
        max-width: 38% !important;
    }
    span {
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        font-size: 23px;
        color: white;
        position: absolute;
        margin-left: 30%;
        text-shadow:
            0px 1px 0 rgb(0, 0, 0),
            0px -1px 0 rgb(0, 0, 0),
            1px 0px 0 rgb(0, 0, 0),
            -1px 0px 0 rgb(0, 0, 0),
            1px 1px 0 rgb(0, 0, 0),
            1px -1px 0 rgb(0, 0, 0),
            -1px 1px 0 rgb(0, 0, 0),
            -1px -1px 0 rgb(0, 0, 0);
    }
`
export const TextitsAMAtchSmile = styled.span`
    color: rgb(164 27 109);
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    width: 75%;
    margin-top: 3%;
    font-size: 28px;
    text-align: center;
    color: white;
    @media (max-width: 768px) {
        font-size: 21px;
    }
`
export const BlockImageCenter = styled.div`
    width: 45%;
    margin-top: 3%;
    position: relative;
    display: flex;
    flex-direction: column;
    img {
        max-width: 100%;
    }
    img.avatar {
        position: absolute;
        border-radius: 100%;
        max-width: 83.8%;
        margin-top: 8.4%;
        right: 8%;
    }
    button {
        border: none;
        outline: none;
        padding: 0;
        cursor: pointer;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 80%;
        margin-top: 17%;
        border-radius: 0 !important;
    }
    button:hover {
        transform: scale(1.1);
    }
    button img.button {
        pointer-events: none;
        position: relative;
        max-width: 100%;
    }
    button span {
        position: absolute;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin-top: -10px;
        font-size: 180%;
        text-shadow:
            0px 1px 0 rgb(35, 136, 0),
            0px -1px 0 rgb(35, 136, 0),
            1px 0px 0 rgb(35, 136, 0),
            -1px 0px 0 rgb(35, 136, 0),
            1px 1px 0 rgb(35, 136, 0),
            1px -1px 0 rgb(35, 136, 0),
            -1px 1px 0 rgb(35, 136, 0),
            -1px -1px 0 rgb(35, 136, 0);
    }
`
export const ChatBottomDate = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    button:hover {
        transform: scale(1.1);
    }
    button img {
        pointer-events: none;
        position: relative;
        max-width: 100%;
    }

    button {
        border: none;
        outline: none;
        padding: 0;
        cursor: pointer;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 60%;
        border-radius: 0 !important;
    }
    button span {
        position: absolute;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin-top: -10px;
        font-size: 180%;
        text-shadow:
            0px 1px 0 rgb(35, 136, 0),
            0px -1px 0 rgb(35, 136, 0),
            1px 0px 0 rgb(35, 136, 0),
            -1px 0px 0 rgb(35, 136, 0),
            1px 1px 0 rgb(35, 136, 0),
            1px -1px 0 rgb(35, 136, 0),
            -1px 1px 0 rgb(35, 136, 0),
            -1px -1px 0 rgb(35, 136, 0);
    }
`
export const ChatImage = styled.img`
    width: 100%;
    border-radius: 10px;
`
export const MessageButtonCurrency = styled.div`
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 10%;

    button:hover {
        transform: scale(1.1);
    }

    button {
        position: relative;
        border: none;
        outline: none;
        padding: 0;
        cursor: pointer;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 60%;
        border-radius: 0 !important;

        .hot-lock {
            position: absolute;
            top: -10px;
            right: 0px;
            z-index: 200;
            width: 25px;
        }
    }
    button span {
        position: absolute;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin-top: -5px;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 180%;
        text-shadow:
            0px 1px 0 rgb(35, 136, 0),
            0px -1px 0 rgb(35, 136, 0),
            1px 0px 0 rgb(35, 136, 0),
            -1px 0px 0 rgb(35, 136, 0),
            1px 1px 0 rgb(35, 136, 0),
            1px -1px 0 rgb(35, 136, 0),
            -1px 1px 0 rgb(35, 136, 0),
            -1px -1px 0 rgb(35, 136, 0);
    }
`
export const ChatBottomCoin2 = styled.div`
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0px;
    left: 0px;
    height: 100%;
    button:hover {
        transform: scale(1.1);
    }

    button {
        border: none;
        outline: none;
        padding: 0;
        cursor: pointer;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 42%;
        border-radius: 0 !important;
    }
    button span {
        position: absolute;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin-top: -5px;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 180%;
        text-shadow:
            0px 1px 0 rgb(35, 136, 0),
            0px -1px 0 rgb(35, 136, 0),
            1px 0px 0 rgb(35, 136, 0),
            -1px 0px 0 rgb(35, 136, 0),
            1px 1px 0 rgb(35, 136, 0),
            1px -1px 0 rgb(35, 136, 0),
            -1px 1px 0 rgb(35, 136, 0),
            -1px -1px 0 rgb(35, 136, 0);
    }
`
export const ChatBottomGift = styled.div`
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0px;
    left: 0px;
    height: 100%;
    button:hover {
        transform: scale(1.1);
    }

    button {
        border: none;
        outline: none;
        padding: 0;
        cursor: pointer;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 60%;
        border-radius: 0 !important;
    }
    button span {
        position: absolute;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin-top: -5px;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 180%;
        text-shadow:
            0px 1px 0 rgb(205 111 0),
            0px -1px 0 rgb(205 111 0),
            1px 0px 0 rgb(205 111 0),
            -1px 0px 0 rgb(205 111 0),
            1px 1px 0 rgb(205 111 0),
            1px -1px 0 rgb(205 111 0),
            -1px 1px 0 rgb(205 111 0),
            -1px -1px 0 rgb(205 111 0);
    }
`
export const ChatBottomGift2 = styled.div`
    width: 100%;
    height: 100%;

    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0px;
    left: 0px;
    text-align: center;
    button:hover {
        transform: scale(1.1);
    }

    .so-hot-message {
        font-size: 28px;
        font-weight: 800;
        color: #f3dc6d;
        margin-bottom: 20px;
    }

    button {
        position: relative;
        border: none;
        outline: none;
        padding: 0;
        cursor: pointer;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 36%;
        border-radius: 0 !important;

        .hot-lock {
            position: absolute;
            top: -10px;
            right: 0px;
            z-index: 200;
            width: 25px;
        }
    }
    button span {
        position: absolute;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin-top: -5px;
        justify-content: center;
        align-items: center;
        display: flex;
        font-size: 180%;
        text-shadow:
            0px 1px 0 rgb(205 111 0),
            0px -1px 0 rgb(205 111 0),
            1px 0px 0 rgb(205 111 0),
            -1px 0px 0 rgb(205 111 0),
            1px 1px 0 rgb(205 111 0),
            1px -1px 0 rgb(205 111 0),
            -1px 1px 0 rgb(205 111 0),
            -1px -1px 0 rgb(205 111 0);
    }
`
export const MessageButtonCurrencyBackground = styled.img`
    pointer-events: none;
    position: relative;
    max-width: 100%;
`
export const ChatBottom = styled.div`
    display: flex;
    box-shadow:
        0px -9.7px 7px -6px #2e005e12,
        inset 0px 0.7px 0.3px 0px #ece9ff85;
    border-top: 0.5px solid #ebebeb;
    min-height: 100px;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 28px 20px 0px 20px;
    min-height: 205px;
    :first-child button {
        margin-bottom: 20px;
    }
    @media (max-width: 768px) {
        padding: 20px 16px 0px 16px;
        min-height: 170px;
    }
`
export const ButtonBtn = styled.button`
    width: 56pt;
    border: none;
    outline: none;
    margin: auto;
    margin-top: 3%;
    width: 6%;
    padding: 0;
    cursor: pointer;
    background: none;
    position: absolute;
    right: 8%;
    :hover {
        transform: scale(1.1);
    }
    img {
        max-width: 100%;
    }
    cursor: pointer;
    background: none;
`
export const Button = styled.button`
    width: 56pt;
    border: none;
    outline: none;
    margin: auto;
    margin-top: 33px;
    width: 100%;
    padding: 0;
    :hover {
        transform: scale(1.1);
    }
    img {
        max-width: 34pt;
    }
    cursor: pointer;
    background: none;
`
export const BackArrow = styled.button`
    width: 75px;
    height: 75px;
    border: none;
    outline: none;
    position: absolute;
    padding: 0;
    :hover {
        transform: scale(1.1);
    }
    img {
        max-width: 65%;
    }
    cursor: pointer;
    background: none;
`
export const BackArrowDate = styled.button`
    width: 75px;
    height: 75px;
    border: none;
    outline: none;
    padding: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
    :hover {
        transform: scale(1.1);
    }
    img {
        max-width: 65%;
    }
    cursor: pointer;
    background: none;
`
export const GeneralContaineritsAMAtch = styled.div`
    display: flex;
    z-index: 100;
    position: absolute;
    top: 0;
    max-width: 535px;
    margin: auto;
    width: 100%;
    justify-content: flex-end;
`
export const GeneralContainerRand = styled.div`
    display: flex;
    z-index: 100;
    position: absolute;
    top: 0;
    max-width: 535px;
    padding-right: 24px;
    padding-left: 24px;
    margin: auto;
    width: 100%;
    height: 100%;
    @media (max-width: 768px) {
        padding-right: 0px;
        padding-left: 0px;
    }
`
export const Container = styled.div`
    display: flex;
    flex: 1;
`
export const BlockContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    margin-top: 5%;
`
export const BlockMessage = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    flex: 1;
`
export const BlockMessageIngBg = styled.img`
    width: 80vw;
    object-fit: cover;
    height: 100%;
    @media (max-width: 768px) {
        width: 134vw;
        margin-left: -17vw;
    }
`
export const BlockMessageIngBgHead = styled.img`
    position: absolute;
    height: 100%;
`
export const BlockMessageIngBgBody = styled.img`
    height: 100%;
`
export const BlockMessageImgBlock = styled.div`
    position: absolute;
    overflow: hidden;
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const BlockDateCharacterAbsol = styled.div`
    position: absolute;
    margin: auto;
    bottom: 0px;
    width: 100vw;
    height: 100%;
    display: block;
    overflow: hidden;
`
export const BlockDateCharacter = styled.div<BlockDateCharacterI>`
    position: absolute;
    margin: auto;
    z-index: 1;
    bottom: -4px;
    width: 100vw;
    height: ${(props) => props.scale + 'vh' || '87vh'};
    transition: all 0.6s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const BlockMessageArrow = styled.div`
    display: flex;
    width: 100%;
    z-index: 10;
`
export const BlockMessageText = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    flex: 1;
    :first-child {
        margin-top: initial;
    }
`
export const BlockMessageTextRandevu = styled.div`
    display: flex;
    align-content: center;
    justify-content: center;
    flex: 1;
    width: 92%;
    z-index: 1;
    :first-child {
        margin-top: initial;
    }
`
export const BlockMessageButton = styled.div`
    z-index: 1;
    width: 100%;
`
export const BlockMessageNext = styled.div`
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
    z-index: 10;
    overflow: hidden;
`
export const ChatButtons = styled.button<ChatButtonsI>`
    outline: none;
    border: none;
    background: white;
    display: flex;
    align-items: center;
    position: relative;
    padding: 0;
    cursor: pointer;
    transition: all 0.25s;
    :first-child {
        margin-bottom: 24px;
    }
    :active {
        outline: none !important;
        transform: scale(0.9);
    }
    :hover {
        outline: none !important;
    }
    ::-moz-focus-inner {
        border: 0 !important;
    }
    :focus {
        outline: none !important;
    }
`
export const ChatButtonsDate = styled.button<ChatButtonsI>`
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    position: relative;
    background: transparent;
    padding: 0;
    margin: auto;
    width: 89%;
    cursor: pointer;
    :first-child {
        margin-bottom: 24px;
    }
    :active {
        outline: none !important;
    }
    :hover {
        outline: none !important;
    }
    ::-moz-focus-inner {
        border: 0 !important;
    }
    :focus {
        outline: none !important;
    }
`
export const ChatButtonsCoin = styled.div`
    background: #f1eef4ff;
    width: 50px;
    height: 15px;
    border-radius: 24px;
    padding: 2px 6px 2px 2px;
    position: absolute;
    margin-top: -21px;
    text-align: right;
    right: 1%;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 14px;
    color: #d1721cff;
    @media (max-width: 768px) {
        right: -1%;
    }
`
export const ChatButtonsGift = styled.div`
    background: #f1eef4ff;
    width: 40px;
    height: 15px;
    border-radius: 24px;
    padding: 2px 6px 2px 19px;
    position: absolute;
    margin-top: 60px;
    text-align: right;
    right: 1%;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 14px;
    color: #d1721cff;
    @media (max-width: 768px) {
        right: -1%;
    }
`
export const MessageButtonCurrencyImage = styled.img`
    max-width: 26%;
    margin-right: 5px;
`
export const ChatButtonsGiftImage = styled.img`
    max-width: 20%;
    margin-right: 5px;
`
export const ChatButtonsCoinImageB = styled.img`
    max-width: 42%;
    margin-right: 5px;
    position: absolute;
    left: -3px;
    top: -2px;
`
export const ChatButtonsCoinImageG = styled.img`
    width: 9%;
    object-fit: contain;
    margin-right: 1%;
    left: inherit;
`
export const ChatButtonsCoinImageA = styled.img`
    max-width: 25px;
    margin-right: 5px;
    width: 44%;
    position: absolute;
    left: 0px;
    top: -5px;
`
export const BlockInfoGifts = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`
export const BlockInfoGiftsText = styled.div`
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 10px;
`
export const BlockInfoGiftsTextDown = styled.div`
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    flex-direction: column;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    span {
        color: #64bd00;
    }
`
export const TimerHiddrn = styled.button<ChatButtonsI>`
    position: absolute;
    opacity: 0;
    pointer-events: none;
`
export const ChatButtonsSpan = styled.span<ChatButtonsI>`
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    background: white;
    border-radius: 10px;
    background: white;
    padding: 10px;
    color: black;
    font-size: 17px;
    min-height: 20px;
    text-align: left;
    flex: 5;
    width: 100%;
    border: ${(props) => (props.borderColor ? '2px solid ' + props.borderColor : '2px solid #19ace4')};
`
export const ChatButtonsSpanImg = styled.span<ChatButtonsI>`
    display: flex;
    flex: 1;
    justify-content: end;
`
export const ChatButtonsSpanImgSrc = styled.img`
    position: relative;
    top: 1px;
    max-width: 70%;
    @media (max-width: 768px) {
        max-width: 80%;
    }
    :hover {
        transform: scale(1.1);
    }
`
