import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { useEffect, useRef, useState } from 'react'
import {
    BlockDateCharacter,
    GeneralContainerRand,
    BlockMessage,
    BlockDateCharacterAbsol,
    BlockMessageArrow,
    BlockMessageIngBgBody,
    BlockMessageNext,
    BlockMessageButton,
    BotMessageContainer,
    BackArrowDate,
    TextMessageContainer,
    ChatBlockDate,
    UserMessageContainer,
    ChatRightText,
    ChatCenter,
    ChatCenterText,
    ChatDate,
    BlockMessageIngBg,
    BlockMessageImgBlock,
    BlockMessageIngBgHead,
    BlockMessageTextRandevu,
    ChatButtonsSpan,
    ChatButtonsSpanImg,
    ChatButtonsSpanImgSrc,
    ChatButtonsDate,
    ChatButtonsCoin,
    ChatButtonsCoinImageB,
    ChatNameSend,
} from './style'
import nextImage from './image/dateButtonNext@3x.png'
import back from '../../page/store/image/back@3x.png'
import {
    selectDateMessages,
    getMessagesDate,
    getMessageDatePrice,
    postChatDate,
    postChatDateCreate,
    postChatId,
    selectChat,
    selectChatStatus,
    selectDatePrices,
    selectLastReadDateMessage,
    setLevelNext,
    setLastReadDateMessage,
} from '../../store/chat/chat'
import { textFormatIcon } from '../../helper/textEdit'
import { buttons } from './chathItem'
import coinIcon from './image/smallCoinIcon@3x.png'
import { Slide } from '@mui/material'
import { getCurrentUser } from '../../store/authToken/auth'
import { setRandevu } from '../../store/storeShop/shop'
import { useXsollaPayments } from '../../hooks/useXsollaPayments'
let UrlImg = 'https://loverz-images.s3.eu-central-1.amazonaws.com/'

export const Rendezvous = () => {
    const dispatch = useAppDispatch()
    const flag = useRef(0)
    const chat = useAppSelector(selectChat)
    const dateMessages = useAppSelector(selectDateMessages)
    const datePrices = useAppSelector(selectDatePrices)
    const chatStatus = useAppSelector(selectChatStatus)
    const lastReadDateMessage = useAppSelector(selectLastReadDateMessage)
    const [chatMessageBackground, setChatMessageBackground] = useState<null | any>(null)
    const [chatMessageBody, setChatMessageBody] = useState<null | any>(null)
    const [chatMessageHead, setChatMessageHead] = useState<null | any>(null)
    const [currentMessage, setCurrentMessage] = useState<null | any>(null)
    const [botNextMessages, setBotNextMessages] = useState<null | any>(null)
    const [chatAnswer, setChatAnswer] = useState<null | any>(null)
    const [chatAnswerNext, setChatAnswerNext] = useState<null | any>(null)
    const { checkIfEnoughCurrency } = useXsollaPayments()
    const [isPaid, setIsPaid] = useState(false)

    useEffect(() => {
        const oneStart = async () => {
            await dispatch(postChatDateCreate(chat.id))
            await dispatch(getMessageDatePrice(chat.id))
            await dispatch(getMessagesDate(chat.id))
        }
        oneStart()
    }, [])

    useEffect(() => {
        if (lastReadDateMessage !== null) {
            if ('DateBackground' in lastReadDateMessage) {
                setChatMessageBackground(Object.keys(lastReadDateMessage.DateBackground.resources)[0] + '.png')
                if (
                    'DateCharacter' in lastReadDateMessage &&
                    Object.keys(lastReadDateMessage.DateBackground.resources)[0] !== 'sex_background'
                ) {
                    setChatMessageBody(Object.keys(lastReadDateMessage.DateCharacter.resources)[0] + '_body.png')
                    if (lastReadDateMessage.DateCharacter.align === 'happy') {
                        setChatMessageHead(Object.keys(lastReadDateMessage.DateCharacter.resources)[0] + '_happy.png')
                    } else if (lastReadDateMessage.DateCharacter.align === 'sad') {
                        setChatMessageHead(Object.keys(lastReadDateMessage.DateCharacter.resources)[0] + '_sad.png')
                    } else {
                        setChatMessageHead(Object.keys(lastReadDateMessage.DateCharacter.resources)[0] + '_neutral.png')
                    }
                }
            }
        }
    }, [lastReadDateMessage])

    useEffect(() => {
        if (dateMessages !== null) {
            setCurrentMessage(null)
            setChatAnswer([...dateMessages.next_user_messages])
            setBotNextMessages([...dateMessages.next_bot_messages])
        }
    }, [dateMessages])

    useEffect(() => {
        if (botNextMessages !== null) {
            const addNextMessage = () => {
                let message = botNextMessages[0]

                if (!message) {
                    return
                }

                if (message.type === '' || message.type === 'NarrativeWords') {
                    setCurrentMessage(null)
                    setTimeout(() => {
                        setCurrentMessage(message)
                    }, 100)
                }

                if (message.type === 'DateBackground') {
                    setChatMessageBackground(Object.keys(message.resources)[0] + '.png')
                    setBotNextMessages(botNextMessages.slice(1))
                }

                if (message.type === 'DateCharacter') {
                    if (message.action === 'show_right' || message.action === '' || message.action === 'show_left') {
                        setChatMessageBody(Object.keys(message.resources)[0] + '_body.png')
                        if (message.align === 'happy') {
                            setChatMessageHead(Object.keys(message.resources)[0] + '_happy.png')
                        } else if (message.align === 'sad') {
                            setChatMessageHead(Object.keys(message.resources)[0] + '_sad.png')
                        } else {
                            setChatMessageHead(Object.keys(message.resources)[0] + '_neutral.png')
                        }
                        setBotNextMessages(botNextMessages.slice(1))
                    } else if (message.action === 'hide_right' || message.action === 'hide_left') {
                        setBotNextMessages(botNextMessages.slice(1))
                        setChatMessageHead(null)
                        setChatMessageBody(null)
                    }
                }

                if (message.type === 'DateChapterEnd') {
                    let start = async () => {
                        await dispatch(setLastReadDateMessage(null))
                        await setCurrentMessage(null)
                        await setChatMessageBody(null)
                        await setChatMessageHead(null)
                        await setChatMessageBackground(null)
                        await setBotNextMessages(null)
                        await dispatch(setRandevu(false))
                        await dispatch(
                            postChatId({
                                idMessage: chatStatus.next_user_messages[chatStatus.next_user_messages.length - 1].id,
                                id: chat.id,
                            }),
                        )
                        await dispatch(setLevelNext(true))
                    }
                    start()
                }
            }

            setTimeout(addNextMessage, 100)
        }
    }, [botNextMessages])

    let closeRandevu = () => {
        flag.current = 0
        dispatch(setLastReadDateMessage(null))
        dispatch(setRandevu(false))
    }

    let nextDate = async () => {
        setCurrentMessage(null)

        if (botNextMessages.length > 0) {
            setBotNextMessages(botNextMessages.slice(1))
        } else if (chatAnswer.length === 0) {
            await dispatch(postChatDate({ idMessage: chatAnswerNext[0].id, id: chat.id }))

            if (isPaid) {
                await dispatch(getCurrentUser())
                setIsPaid(false)
            }
        }
    }

    const answer = (data: any) => {
        setCurrentMessage(data)
        setChatAnswerNext([data])
        setChatAnswer([])
    }

    const answerPay = async (data: any, money: 'gift' | 'coin', minus: number) => {
        if (checkIfEnoughCurrency(minus, money)) {
            setCurrentMessage(data)
            setChatAnswerNext([data])

            setIsPaid(true)

            if (money !== undefined) {
                await dispatch(getMessageDatePrice(chat.id))
            }

            setChatAnswer([])
        }
    }

    const renderCurrentMessage = () => {
        if (currentMessage === null) {
            return null
        }

        if (currentMessage.is_user_action === false && currentMessage.type === '') {
            return (
                <ChatDate key={currentMessage.id}>
                    <BotMessageContainer>
                        {currentMessage.translations.en !== null && (
                            <TextMessageContainer bgColor={'#e8e8e8'}>
                                {currentMessage.third_character === null && (
                                    <ChatNameSend>{chat.profile_name.English}</ChatNameSend>
                                )}
                                {currentMessage.third_character !== null && (
                                    <ChatNameSend>{currentMessage.third_character.English}</ChatNameSend>
                                )}
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: textFormatIcon(currentMessage.translations.en),
                                    }}
                                />
                            </TextMessageContainer>
                        )}
                    </BotMessageContainer>
                </ChatDate>
            )
        } else if (currentMessage.is_user_action === false && currentMessage.type === 'NarrativeWords') {
            return (
                <ChatDate key={currentMessage.id}>
                    <ChatCenter>
                        <ChatCenterText bgColor={'#dcdcdc'} colorText={'#000'}>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: textFormatIcon(currentMessage.translations.en),
                                }}
                            />
                        </ChatCenterText>
                    </ChatCenter>
                </ChatDate>
            )
        } else if (
            currentMessage.is_user_action &&
            ((currentMessage.type === '' && currentMessage.price === 'coins2') ||
                (currentMessage.type === 'Variant' && currentMessage.price === 'coins2') ||
                (currentMessage.type === 'Variant' && currentMessage.price === 'coins20') ||
                (currentMessage.type === '' && currentMessage.price === 'coins20'))
        ) {
            return (
                <ChatDate key={currentMessage.id}>
                    <UserMessageContainer>
                        <ChatRightText bgColor={'#e89734'} colorText={'#fff'}>
                            <ChatNameSend>You</ChatNameSend>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: textFormatIcon(currentMessage.translations.en),
                                }}
                            />
                        </ChatRightText>
                    </UserMessageContainer>
                </ChatDate>
            )
        } else if (
            currentMessage.is_user_action &&
            ((currentMessage.type === '' && currentMessage.price !== 'coins2') ||
                (currentMessage.type === 'Variant' && currentMessage.price !== 'coins2'))
        ) {
            return (
                <ChatDate key={currentMessage.id}>
                    <UserMessageContainer>
                        <ChatRightText bgColor={'#19ace4'} colorText={'#fff'}>
                            <ChatNameSend>You</ChatNameSend>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: textFormatIcon(currentMessage.translations.en),
                                }}
                            />
                        </ChatRightText>
                    </UserMessageContainer>
                </ChatDate>
            )
        } else {
            return null
        }
    }

    return (
        <GeneralContainerRand>
            <BlockMessage>
                {chatMessageBackground !== null && (
                    <BlockMessageImgBlock>
                        <BlockMessageIngBg src={UrlImg + chatMessageBackground} />
                    </BlockMessageImgBlock>
                )}
                {botNextMessages !== null && (
                    <Slide
                        direction="left"
                        in={chatMessageHead !== null && chatMessageBody !== null}
                        mountOnEnter
                        unmountOnExit
                        timeout={500}
                    >
                        <BlockDateCharacterAbsol>
                            <BlockDateCharacter
                                scale={
                                    (botNextMessages !== null && botNextMessages.length === 0) ||
                                    currentMessage?.is_user_action
                                        ? 80
                                        : 87
                                }
                            >
                                <BlockMessageIngBgHead src={UrlImg + chatMessageHead} />
                                <BlockMessageIngBgBody src={UrlImg + chatMessageBody} />
                            </BlockDateCharacter>
                        </BlockDateCharacterAbsol>
                    </Slide>
                )}

                <BlockMessageArrow>
                    <BackArrowDate onClick={closeRandevu}>
                        <img src={back} />
                    </BackArrowDate>
                </BlockMessageArrow>

                <BlockMessageTextRandevu>
                    {currentMessage !== null && <ChatBlockDate>{renderCurrentMessage()}</ChatBlockDate>}
                </BlockMessageTextRandevu>

                <BlockMessageButton>
                    {botNextMessages?.length === 0 &&
                        !currentMessage &&
                        chatAnswer.map((item: any) => {
                            if (item.price?.includes('coins')) {
                                return (
                                    <ChatButtonsDate
                                        key={item.id}
                                        onClick={() =>
                                            answerPay(
                                                item,
                                                'coin',
                                                item.price === 'coins20'
                                                    ? datePrices.Variant_sex.coin
                                                    : datePrices.Variant.coin,
                                            )
                                        }
                                    >
                                        <ChatButtonsSpan
                                            borderColor={'#ff7a0f'}
                                            dangerouslySetInnerHTML={{
                                                __html: textFormatIcon(item.translations.en),
                                            }}
                                        ></ChatButtonsSpan>
                                        <ChatButtonsSpanImg>
                                            <ChatButtonsCoin>
                                                <ChatButtonsCoinImageB src={coinIcon}></ChatButtonsCoinImageB>
                                                {item.price === 'coins20' && datePrices.Variant_sex.coin}
                                                {item.price === 'coins2' && datePrices.Variant.coin}
                                            </ChatButtonsCoin>
                                            <ChatButtonsSpanImgSrc src={buttons.coin} />
                                        </ChatButtonsSpanImg>
                                    </ChatButtonsDate>
                                )
                            } else {
                                return (
                                    <ChatButtonsDate key={item.id} onClick={() => answer(item)}>
                                        <ChatButtonsSpan
                                            dangerouslySetInnerHTML={{
                                                __html: textFormatIcon(item.translations.en),
                                            }}
                                        ></ChatButtonsSpan>
                                        <ChatButtonsSpanImg>
                                            <ChatButtonsSpanImgSrc src={buttons.send} />
                                        </ChatButtonsSpanImg>
                                    </ChatButtonsDate>
                                )
                            }
                        })}
                </BlockMessageButton>

                <BlockMessageNext>
                    <BackArrowDate onClick={nextDate}>
                        <img src={nextImage} />
                    </BackArrowDate>
                </BlockMessageNext>
            </BlockMessage>
        </GeneralContainerRand>
    )
}
