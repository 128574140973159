import {
    ContainerGeneral,
    ImageInfo,
    ImageLabel,
    ImageZadiak,
    ItemButton,
    NoCards,
    SwiperHeader,
    SwiperInfo,
    SwiperText,
    BlockInfoAvatar,
    BlockInfoAvatarText,
    BlockInfoAvatarTextOr,
    BlockInfoAvatarTextSpan,
    BlockInfoAvatarTextHeader,
    BlockInfoAvatarSpan,
    ButtonTextCoin,
    ButtonTextCoin2,
    BlockNoCard,
    BlockNoCardImg,
    SwiperInfoNoCard,
    BlockNoCardImgBg,
    BigInfoCard,
    ArrowAnimation,
    BlockInfoAvatarOverflow,
    ContainerBocked,
    SwiperAvatar,
} from './style'
import './swiper.css'

import ReactHammer from 'react-hammerjs'
import { useEffect, useRef, useState } from 'react'
import epic from './image/epicBase@3x.png'
import like from './image/likeButton@3x.png'
import dislike from './image/dislikeButton@3x.png'
import vip from './image/specialBigBase2@3x.png'
import legeng from './image/legendaryBase@3x.png'
import avatar from './image/stewardess_avatar.jpg'
import Textfit from '@namhong2001/react-textfit'
import scorpio from './image/Scorpio_sign.png'
import virgo from './image/Virgo_sign.png'
import cancer from './image/Cancer_sign.png'
import capricorn from './image/Capricon_sign.png'
import aquarius from './image/Aquarius_sign.png'
import pisces from './image/Pisces_sign.png'
import aries from './image/Aries_Sign.png'
import gemini from './image/Gemini_sign.png'
import leo from './image/Lion_sign.png'
import libra from './image/Libra_sign.png'
import sagittarius from './image/Sagittarius_sign.png'
import taurus from './image/Taurus_sign.png'
import epicLabel from './image/EPIC.png'
import infoButton from './image/InfoButton.png'
import vipLabel from './image/VIP.png'
import legendLabel from './image/Legendary.png'
import photoCard from './image/PhotoCard.png'
import { CircularProgress, Fade } from '@mui/material'
import {
    isBigInfo,
    isNoCards,
    selectIsBigInfo,
    selectIsFirstOpen,
    selectIsNoCards,
    setHeightContent,
    setSubStore,
} from '../../store/page/pageSlice'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import midGreen from './image/greenButtonMid@3x.png'
import {
    changeSwipe,
    getSwipe,
    isPaidStatus,
    isStatus,
    postSkipR,
    postSwipeLikeDislike,
    selectMatchStatus,
    selectReloadCharacters,
    selectStatus,
    selectSwipe,
    selectTimer,
} from '../../store/swipe/swipeSlice'
import {
    getItemsXsollaR,
    getCurrentUser,
    selectAccess_token,
    selectUserInfo,
    setBundleOpen,
} from '../../store/authToken/auth'
import hand from './image/tutorialHand@3x.png'
import { BigInfoSwipe } from './bigInfo'
import Timer from '@amplication/react-compound-timer'
import { setChat } from '../../store/chat/chat'
import { textFormatIcon } from '../../helper/textEdit'
import { fnBrowserDetect } from '../../helper/brouserDetect'
import { setShop } from '../../store/storeShop/shop'
import { ChatButtonsCoinImageG } from '../chatItem/style'
import coinIcon from '../chatItem/image/smallCoinIcon@3x.png'

interface IZodiac {
    [key: string]: string
}
export let zodiac: IZodiac = {
    Taurus: taurus,
    Scorpio: scorpio,
    Virgo: virgo,
    Sagittarius: sagittarius,
    Cancer: cancer,
    Capricorn: capricorn,
    Aquarius: aquarius,
    Pisces: pisces,
    Aries: aries,
    Gemini: gemini,
    Leo: leo,
    Libra: libra,
}
interface ICardIn {
    bg: string
    label: string
}
interface ICard {
    [key: number]: ICardIn
}
export let typeCard: ICard = {
    0: { bg: photoCard, label: 'none' },
    1: { bg: vip, label: vipLabel },
    2: { bg: epic, label: epicLabel },
    3: { bg: legeng, label: legendLabel },
}

export const Swiper = () => {
    const dispatch = useAppDispatch()
    const noCards = useAppSelector(selectIsNoCards)
    const access_token = useAppSelector(selectAccess_token)
    const characters = useAppSelector(selectSwipe)
    const bigInfo = useAppSelector(selectIsBigInfo)
    const status = useAppSelector(selectStatus)
    const idTokenData = useAppSelector(selectUserInfo)
    const likeStatus = useAppSelector(selectMatchStatus)
    const timer = useAppSelector(selectTimer)
    const firstOpen = useAppSelector(selectIsFirstOpen)
    const needReload = useAppSelector(selectReloadCharacters)
    const [isFread, setIsFread] = useState(true)
    const blocktwoClick = useRef(1)
    const elementGetHeight = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (access_token !== 'none') {
            const start = async () => {
                await dispatch(getSwipe())
                await dispatch(getCurrentUser())
                dispatch(setHeightContent(elementGetHeight.current?.offsetHeight))
                document.body.style.overflow = 'hidden'
            }
            start()
        }
    }, [access_token])

    useEffect(() => {
        if (characters.length !== 0) {
            if (
                idTokenData.unlocked_characters.includes(characters[characters.length - 1].id) &&
                characters[characters.length - 1].id !== 'blogger'
            ) {
                setTimeout(() => {
                    createButtonNotPay(true)
                }, 1000)
            }
        }
    }, [idTokenData])

    useEffect(() => {
        if (needReload) {
            dispatch(getSwipe())
        }
    }, [needReload])

    useEffect(() => {
        if (isFread === false) {
            setIsFread(true)
        }
        if (likeStatus === false) {
            blocktwoClick.current = 1
        }
    }, [likeStatus])

    useEffect(() => {
        blocktwoClick.current = 1
    }, [characters])

    const lenSimvol = (text: string) => {
        let newText = ''
        if (window.innerWidth > 756) {
            if (text.length < 100) {
                newText = textFormatIcon(text)
            } else {
                newText = text.substr(0, 170) + '...'
                newText = textFormatIcon(newText)
            }
        } else {
            newText = text.substr(0, 85) + '...'
            newText = textFormatIcon(newText)
        }
        return newText
    }

    const userOpen = () => {
        dispatch(isBigInfo(true))
    }

    let scaleF = (index: number, len: number) => {
        let scale = (5 - (len - 1 - index)) / 5
        return scale < 0 ? 0 : scale
    }

    function initCards() {
        let newCards = document.querySelectorAll('.swiper--card:not(.removed)')
        if (newCards.length === 0) {
            setTimeout(() => {
                dispatch(getSwipe())
            }, 500)
        }
        newCards.forEach(function (card, index) {
            // @ts-ignore
            card.style.transform = 'scale(' + scaleF(index, newCards.length) + ')'
        })
    }

    const handlePan = (event: any) => {
        if (event.target.localName !== 'button') {
            event.target.classList.add('moving')
            if (event.deltaX === 0) return
            if (event.center.x === 0 && event.center.y === 0) return
            // let keepPaid = Math.abs(event.deltaX) > 5
            // let moveOutWidth = document.body.clientWidth
            // let endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth)
            // let toX = event.deltaX > 0 ? endX : -endX
            let rotate = +(event.deltaX / 10)
            // @ts-ignore
            event.target.querySelector('.fa-heart').style.opacity = event.deltaX / 140
            // @ts-ignore
            event.target.querySelector('.fa-remove').style.opacity = -(event.deltaX / 140)
            event.target.style.transform = 'translate(' + event.deltaX + 'px) rotate(' + rotate + 'deg)'
            // if (characters[characters.length - 1].paid && keepPaid && toX > 0) {
            //     dispatch(setChat(characters[characters.length - 1]))
            //     event.target.classList.remove('moving')
            //     event.target.classList.toggle('removed', !keepPaid)
            //     // @ts-ignore
            //     event.target.querySelector('.fa-heart').style.opacity = 0
            //     // @ts-ignore
            //     event.target.querySelector('.fa-remove').style.opacity = 0
            //     dispatch(isPaidStatus(true))
            //     setIsFread(false)
            //     event.target.style.transform = ''
            // }
        }
    }

    const handlePanEnd = (event: any) => {
        if (event.target.localName !== 'button') {
            event.target.classList.remove('moving')
            // @ts-ignore
            event.target.querySelector('.fa-heart').style.opacity = 0
            // @ts-ignore
            event.target.querySelector('.fa-remove').style.opacity = 0

            let moveOutWidth = document.body.clientWidth
            let keep = Math.abs(event.deltaX) < 150

            event.target.classList.toggle('removed', !keep)

            if (keep) {
                event.target.style.transform = ''
            } else {
                let endX = Math.max(Math.abs(event.velocityX) * moveOutWidth, moveOutWidth)
                let toX = event.deltaX > 0 ? endX : -endX
                let rotate = +(event.deltaX / 10)

                event.target.style.transform = 'translate(' + toX * 2 + 'px) rotate(' + rotate + 'deg)'

                if (toX > 0) {
                    dispatch(isStatus(true))
                    dispatch(setChat(characters[characters.length - 1]))
                    dispatch(
                        postSwipeLikeDislike({
                            id: characters[characters.length - 1].id,
                            action: 'like',
                        }),
                    )
                } else {
                    dispatch(isStatus(true))
                    dispatch(setChat(characters[characters.length - 1]))
                    dispatch(
                        postSwipeLikeDislike({
                            id: characters[characters.length - 1].id,
                            action: 'dislike',
                        }),
                    )
                }

                setTimeout(() => {
                    dispatch(changeSwipe(characters))
                }, 500)

                initCards()
            }
        }
    }

    // @ts-ignore
    let createButtonListener = (loveFlag: any) => {
        if (blocktwoClick.current === 1) {
            blocktwoClick.current = 0
            let cards = document.querySelectorAll('.swiper--card:not(.removed)')
            let moveOutWidth = document.body.clientWidth * 1.5

            if (!cards.length) return false

            dispatch(isStatus(true))
            let card = cards[cards.length - 1]
            dispatch(isBigInfo(false))

            if (loveFlag) {
                // if (characters[characters.length - 1].paid) {
                //     dispatch(isStatus(false))
                //     dispatch(isPaidStatus(true))
                //     dispatch(setChat(characters[characters.length - 1]))
                //     setIsFread(false)
                // } else {
                card.classList.add('removed')
                dispatch(setChat(characters[characters.length - 1]))
                dispatch(
                    postSwipeLikeDislike({
                        id: characters[characters.length - 1].id,
                        action: 'like',
                    }),
                )
                // @ts-ignore
                card.style.transform = 'translate(' + moveOutWidth + 'px, 10px) rotate(60deg)'
                setTimeout(() => {
                    dispatch(changeSwipe(characters))
                }, 500)
                // }
            } else {
                card.classList.add('removed')
                // @ts-ignore
                card.style.transform = 'translate(-' + moveOutWidth + 'px, 10px) rotate(-60deg)'
                dispatch(
                    postSwipeLikeDislike({
                        id: characters[characters.length - 1].id,
                        action: 'dislike',
                    }),
                )
                setTimeout(() => {
                    dispatch(changeSwipe(characters))
                }, 500)
            }

            initCards()
        }
    }
    // @ts-ignore
    let createButtonNotPay = (loveFlag: any) => {
        console.log('here')
        if (blocktwoClick.current === 1) {
            blocktwoClick.current = 0
            let cards = document.querySelectorAll('.swiper--card:not(.removed)')
            let moveOutWidth = document.body.clientWidth * 1.5

            if (!cards.length) return false

            dispatch(isStatus(false))
            let card = cards[cards.length - 1]
            dispatch(isBigInfo(false))

            if (loveFlag) {
                card.classList.add('removed')
                dispatch(isPaidStatus(false))
                dispatch(setChat(characters[characters.length - 1]))
                dispatch(
                    postSwipeLikeDislike({
                        id: characters[characters.length - 1].id,
                        action: 'like',
                    }),
                )
                // @ts-ignore
                card.style.transform = 'translate(' + moveOutWidth + 'px, 10px) rotate(60deg)'
                setTimeout(() => {
                    dispatch(changeSwipe(characters))
                }, 500)
            } else {
                card.classList.add('removed')
                // @ts-ignore
                card.style.transform = 'translate(-' + moveOutWidth + 'px, 10px) rotate(-60deg)'
                dispatch(
                    postSwipeLikeDislike({
                        id: characters[characters.length - 1].id,
                        action: 'dislike',
                    }),
                )
                setTimeout(() => {
                    dispatch(changeSwipe(characters))
                }, 500)
            }

            initCards()
        }
    }

    const skipSwiper = async (skip: string, amt: number) => {
        if (idTokenData.coin >= amt) {
            await dispatch(postSkipR(skip))
            await dispatch(getSwipe())
            await dispatch(getCurrentUser())
            await dispatch(isNoCards(false))
        } else {
            let secondPackXL = idTokenData.orders.find((element: any) => element.sku === 'starterpackxl')
            if (!secondPackXL) {
                await dispatch(setSubStore('coin'))
                await dispatch(getItemsXsollaR())
                await dispatch(setBundleOpen(true))
            } else {
                await dispatch(setSubStore('coin'))
                await dispatch(setShop(true))
            }
        }
    }

    return (
        <ContainerGeneral ref={elementGetHeight} brouser={fnBrowserDetect()}>
            {firstOpen && (
                <>
                    {noCards === false && (
                        <Fade in={noCards === false}>
                            <div className="swiper">
                                <Fade in={bigInfo === false} style={bigInfo !== false ? { position: 'absolute' } : {}}>
                                    <div className="swiper--cards">
                                        {characters.length === 0 && <CircularProgress />}
                                        {characters.length !== 0 &&
                                            characters.map((val: any, index: number) => {
                                                return (
                                                    <ReactHammer
                                                        onPan={handlePan}
                                                        options={{
                                                            recognizers: {
                                                                pan: {
                                                                    enable: isFread && !val.like_required,
                                                                },
                                                            },
                                                        }}
                                                        onPanEnd={handlePanEnd}
                                                        key={val.id}
                                                    >
                                                        <div
                                                            className="swiper--card"
                                                            style={{
                                                                //zIndex: characters.length - index,
                                                                transform:
                                                                    'scale(' + scaleF(index, characters.length) + ')',
                                                            }}
                                                            key={val.id}
                                                        >
                                                            <div className="swiper-imgbg">
                                                                <img
                                                                    className="swiper-img-bg"
                                                                    src={typeCard[val.rarity].bg}
                                                                />
                                                                <div className="swiper-avatar">
                                                                    <SwiperAvatar src={val.profile_pic_url} />
                                                                    <SwiperInfo>
                                                                        <SwiperText>
                                                                            {val.profile_name.English}
                                                                        </SwiperText>
                                                                        <SwiperHeader>
                                                                            <ImageZadiak
                                                                                src={zodiac[val.zodiac_sign.English]}
                                                                            />
                                                                            <div
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: lenSimvol(
                                                                                        val.profile_description_small
                                                                                            .English,
                                                                                    ),
                                                                                }}
                                                                            />
                                                                        </SwiperHeader>
                                                                        <ItemButton onClick={userOpen}>
                                                                            <ImageInfo src={infoButton} />
                                                                        </ItemButton>
                                                                    </SwiperInfo>
                                                                </div>
                                                                {typeCard[val.rarity].label !== 'none' && (
                                                                    <ImageLabel src={typeCard[val.rarity].label} />
                                                                )}
                                                            </div>
                                                            <div className="swiper--status">
                                                                <span className="fa fa-remove">
                                                                    <img src={dislike} />
                                                                </span>
                                                                <span className="fa fa-heart">
                                                                    <img src={like} />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </ReactHammer>
                                                )
                                            })}
                                    </div>
                                </Fade>
                                <Fade
                                    in={bigInfo}
                                    style={bigInfo === false ? { position: 'absolute' } : {}}
                                    mountOnEnter
                                    unmountOnExit
                                >
                                    <BigInfoCard>{characters.length !== 0 && <BigInfoSwipe />}</BigInfoCard>
                                </Fade>
                                <div className="swiper--buttons">
                                    <button
                                        disabled={
                                            (blocktwoClick.current === 0 &&
                                                (characters.length !== 0
                                                    ? characters[characters.length - 1].like_required
                                                    : false)) ||
                                            (blocktwoClick.current === 0 && status)
                                        }
                                        id="nope"
                                        onClick={() => createButtonListener(false)}
                                    >
                                        <span className="fa fa-remove">
                                            <img src={dislike} />
                                        </span>
                                    </button>
                                    <button
                                        id="love"
                                        disabled={blocktwoClick.current === 0 && status}
                                        className={
                                            characters.length !== 0 && characters[characters.length - 1].like_required
                                                ? 'superLike'
                                                : ''
                                        }
                                        onClick={() => createButtonListener(true)}
                                    >
                                        <span className="fa fa-heart">
                                            <img className="likeHeard" src={like} />
                                            {(characters.length !== 0
                                                ? characters[characters.length - 1].like_required
                                                : false) && (
                                                <ArrowAnimation>
                                                    <img src={hand} />
                                                </ArrowAnimation>
                                            )}
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </Fade>
                    )}
                    {noCards && timer !== 61 && (
                        <Fade in={noCards} mountOnEnter unmountOnExit>
                            <NoCards>
                                <div className="swiper-imgbg">
                                    <BlockNoCardImgBg src={photoCard} />
                                    <BlockNoCard>
                                        <BlockInfoAvatar>
                                            <BlockInfoAvatarOverflow>
                                                <BlockNoCardImg src={avatar} />
                                                <BlockInfoAvatarText>
                                                    <BlockInfoAvatarTextHeader>
                                                        <Textfit mode={'single'}>Out of profiles</Textfit>
                                                    </BlockInfoAvatarTextHeader>
                                                    <BlockInfoAvatarSpan>
                                                        <Textfit mode={'single'}>
                                                            Meet new singles in{' '}
                                                            <i>
                                                                <Timer
                                                                    initialTime={
                                                                        Number(String(timer).split('.')[0]) * 1000 +
                                                                        1000
                                                                    }
                                                                    direction="backward"
                                                                    checkpoints={[
                                                                        {
                                                                            time: 0,
                                                                            callback: async () => {
                                                                                await dispatch(getSwipe())
                                                                                await dispatch(isNoCards(false))
                                                                            },
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Timer.Hours
                                                                        formatValue={(value) =>
                                                                            `${value < 10 ? `0${value}` : value}`
                                                                        }
                                                                    />
                                                                    :
                                                                    <Timer.Minutes
                                                                        formatValue={(value) =>
                                                                            `${value < 10 ? `0${value}` : value}`
                                                                        }
                                                                    />
                                                                    :
                                                                    <Timer.Seconds
                                                                        formatValue={(value) =>
                                                                            `${value < 10 ? `0${value}` : value}`
                                                                        }
                                                                    />
                                                                </Timer>
                                                            </i>
                                                        </Textfit>
                                                    </BlockInfoAvatarSpan>
                                                    <BlockInfoAvatarTextOr>
                                                        <hr></hr>
                                                        <BlockInfoAvatarTextSpan>
                                                            <Textfit mode={'single'}>or</Textfit>
                                                        </BlockInfoAvatarTextSpan>
                                                        <hr></hr>
                                                    </BlockInfoAvatarTextOr>
                                                </BlockInfoAvatarText>
                                            </BlockInfoAvatarOverflow>
                                        </BlockInfoAvatar>
                                        <SwiperInfoNoCard>
                                            <ButtonTextCoin
                                                onClick={() => {
                                                    skipSwiper('profile_1', 100)
                                                }}
                                            >
                                                <img className={'button'} src={midGreen} />
                                                <span>
                                                    <i>1 more profile</i>
                                                    <ChatButtonsCoinImageG src={coinIcon}></ChatButtonsCoinImageG>
                                                    <i>100</i>
                                                </span>
                                            </ButtonTextCoin>
                                            <ButtonTextCoin2
                                                onClick={() => {
                                                    skipSwiper('profile_6', 300)
                                                }}
                                            >
                                                <img className={'button'} src={midGreen} />
                                                <span>
                                                    <i>6 more profile</i>
                                                    <ChatButtonsCoinImageG src={coinIcon}></ChatButtonsCoinImageG>
                                                    <i>300</i>
                                                </span>
                                            </ButtonTextCoin2>
                                        </SwiperInfoNoCard>
                                    </BlockNoCard>
                                </div>
                            </NoCards>
                        </Fade>
                    )}
                </>
            )}
            {!firstOpen && <CircularProgress />}
            {status && <ContainerBocked />}
        </ContainerGeneral>
    )
}
