import {
    ChatBottomDate,
    ChatButtons,
    ChatButtonsCoin,
    ChatButtonsCoinImageB,
    ChatButtonsSpan,
    ChatButtonsSpanImg,
    ChatButtonsSpanImgSrc,
} from '../../page/chatItem/style'
import bigGreenButton from '../../page/chatItem/image/bigButtonSubs@3x.png'
import coinIcon from '../../page/chatItem/image/smallCoinIcon@3x.png'
import { textFormatIcon } from '../../helper/textEdit'
import { buttons } from '../../page/chatItem/chathItem'
import { useAppSelector } from '../../hooks/hooks'
import { selectPrices } from '../../store/chat/chat'
import { selectCurrentLanguage } from '../../store/page/pageSlice'

export const ChatAnswer = ({ chatAnswer, onOpenDate, onAnswer }: any) => {
    const prices = useAppSelector(selectPrices)
    const currentLanguage = useAppSelector(selectCurrentLanguage)

    const renderAnswer = (isPaid: boolean) => {
        return (
            <ChatButtons
                key={chatAnswer.id}
                onClick={() => {
                    onAnswer(chatAnswer.id, chatAnswer, isPaid ? prices.Variant.coin : 0)
                }}
            >
                <ChatButtonsSpan
                    borderColor={isPaid ? '#ff7a0f' : ''}
                    dangerouslySetInnerHTML={{
                        __html: textFormatIcon(chatAnswer.translations[currentLanguage]),
                    }}
                ></ChatButtonsSpan>
                {isPaid ? (
                    <ChatButtonsSpanImg>
                        <ChatButtonsCoin>
                            <ChatButtonsCoinImageB src={coinIcon} />
                            {prices.Variant.coin}
                        </ChatButtonsCoin>
                        <ChatButtonsSpanImgSrc src={buttons.coin} />
                    </ChatButtonsSpanImg>
                ) : (
                    <ChatButtonsSpanImg>
                        <ChatButtonsSpanImgSrc src={buttons.send} />
                    </ChatButtonsSpanImg>
                )}
            </ChatButtons>
        )
    }

    if (chatAnswer.type === 'Date') {
        return (
            <ChatBottomDate key={chatAnswer.id}>
                <button onClick={onOpenDate}>
                    <img src={bigGreenButton} />
                    <span>Date</span>
                </button>
            </ChatBottomDate>
        )
    }

    return renderAnswer(chatAnswer.price !== null && chatAnswer.price.includes('coins'))
}
