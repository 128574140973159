import {
    BackgroundMenu2,
    BlockButtonContinue,
    BlockButtonContinueGrey,
    BlockInterest,
    BlockInterestCenter,
    BlockNoAnswer,
    BlockRes,
    ButtonCustomChoes,
    ButtonCustomChoesSel,
    GeneralContainer,
    TextInterest,
} from './style'
import { useState } from 'react'
import buttonFonGrey from './image/greenButton1MidCopy@3x.png'
import buttonFon from './image/greenButtonMid@3x.png'
import { useAppDispatch } from '../../hooks/hooks'
import { setChoes, setFirstGift, setOpenPart } from '../../store/authToken/auth'
import fashion from './image/Icon/1.png'
import music from './image/Icon/2.png'
import sex from './image/Icon/3.png'
import crime from './image/Icon/4.png'
import parties from './image/Icon/5.png'
import games from './image/Icon/6.png'
import pets from './image/Icon/7.png'
import books from './image/Icon/8.png'
import blogging from './image/Icon/9.png'
import theater from './image/Icon/10.png'
import sport from './image/Icon/11.png'
import casino from './image/Icon/12.png'
import art from './image/Icon/13.png'
import shopping from './image/Icon/14.png'
import technologies from './image/Icon/15.png'
import travelling from './image/Icon/16.png'
import cars from './image/Icon/17.png'
import food from './image/Icon/18.png'
import movies from './image/Icon/19.png'
import dancing from './image/Icon/20.png'
import { setCurrentCoins } from '../../store/page/pageSlice'

let objectChoose = [
    {
        name: 'Parties',
        img: parties,
        status: false,
    },
    {
        name: 'Sport',
        img: sport,
        status: false,
    },
    {
        name: 'Pets',
        img: pets,
        status: false,
    },
    {
        name: 'Books',
        img: books,
        status: false,
    },
    {
        name: 'Food',
        img: food,
        status: false,
    },
    {
        name: 'Travelling',
        img: travelling,
        status: false,
    },
    {
        name: 'Dancing',
        img: dancing,
        status: false,
    },
    {
        name: 'Art',
        img: art,
        status: false,
    },
    {
        name: 'Shopping',
        img: shopping,
        status: false,
    },
    {
        name: 'Crime',
        img: crime,
        status: false,
    },
    {
        name: 'Fashion',
        img: fashion,
        status: false,
    },
    {
        name: 'Sex',
        img: sex,
        status: false,
    },
    {
        name: 'Blogging',
        img: blogging,
        status: false,
    },
    {
        name: 'Music',
        img: music,
        status: false,
    },
    {
        name: 'Games',
        img: games,
        status: false,
    },
    {
        name: 'Movies',
        img: movies,
        status: false,
    },
    {
        name: 'Cars',
        img: cars,
        status: false,
    },
    {
        name: 'Theater',
        img: theater,
        status: false,
    },
    {
        name: 'Technologies',
        img: technologies,
        status: false,
    },
    {
        name: 'Casino',
        img: casino,
        status: false,
    },
]

export const ChooseYourInterests = () => {
    const [obj, setObj] = useState(objectChoose)
    const [counter, setCounter] = useState(0)
    const dispatch = useAppDispatch()
    const selectItem = (info: any, i: number) => {
        let count = counter
        let a = JSON.parse(JSON.stringify(obj))
        if (info.status === false && count < 5) {
            a[i] = { ...info, status: true }
            setCounter(count + 1)
            setObj(a)
        } else if (info.status === true && count <= 5) {
            a[i] = { ...info, status: false }
            setCounter(count - 1)
            setObj(a)
        }
    }
    const closeBlock = () => {
        dispatch(setChoes(false))

        setTimeout(() => {
            dispatch(setOpenPart(false))
            setTimeout(() => {
                dispatch(setFirstGift(true))
                dispatch(setCurrentCoins(0))
            }, 1000)
        }, 1000)
    }
    return (
        <GeneralContainer>
            <BackgroundMenu2 zIndex={1} />
            <TextInterest>Choose your interests</TextInterest>
            <BlockInterestCenter>
                <BlockInterest>
                    {obj.map((val: any, index: number) => {
                        if (val.status === false) {
                            return (
                                <ButtonCustomChoes key={val.name} onClick={() => selectItem(val, index)}>
                                    <img src={val.img} />
                                    <span>{val.name}</span>
                                </ButtonCustomChoes>
                            )
                        } else if (val.status === true) {
                            return (
                                <ButtonCustomChoesSel key={val.name} onClick={() => selectItem(val, index)}>
                                    <img src={val.img} />
                                    <span>{val.name}</span>
                                </ButtonCustomChoesSel>
                            )
                        } else {
                            return null
                        }
                    })}
                </BlockInterest>
            </BlockInterestCenter>
            <BlockRes>{counter}/5</BlockRes>
            {counter === 0 && (
                <BlockButtonContinueGrey>
                    <img src={buttonFonGrey} />
                    <span>Continue</span>
                </BlockButtonContinueGrey>
            )}
            {counter > 0 && (
                <BlockButtonContinue onClick={closeBlock}>
                    <img src={buttonFon} />
                    <span>Continue</span>
                </BlockButtonContinue>
            )}
            <BlockNoAnswer onClick={closeBlock}>No answer</BlockNoAnswer>
        </GeneralContainer>
    )
}
