import { ImageInfo, BlockInfoCoin } from './styleInfoCoin'
import { useAppDispatch, useAppSelector } from '../hooks/hooks'
import coinIcon from '../page/store/image/smallCoinIcon@3x.png'
import { useEffect, useState } from 'react'
import { setCurrentCoins, selectCurrentCoins } from '../store/page/pageSlice'
import { selectUserInfo, setTriggerAddingCoin } from '../store/authToken/auth'

export const InfoCoin = () => {
    const dispatch = useAppDispatch()

    const currentCoins = useAppSelector(selectCurrentCoins)
    const [progressCoins, setProgressCoins] = useState(0)
    const userInfo = useAppSelector(selectUserInfo)

    function animateValue(obj: any, start: any, end: any, duration: any) {
        let startTimestamp: any = null
        const step = (timestamp: any) => {
            if (!startTimestamp) startTimestamp = timestamp
            const progress = Math.min((timestamp - startTimestamp) / duration, 1)
            setProgressCoins(Math.floor(progress * (end - start) + start))
            if (progress < 1) {
                window.requestAnimationFrame(step)
            }
        }
        window.requestAnimationFrame(step)
    }

    useEffect(() => {
        setProgressCoins(currentCoins)
    }, [currentCoins])

    useEffect(() => {
        if (userInfo === null) return

        setTimeout(() => {
            animateValue(setProgressCoins, currentCoins, userInfo.coin, 1100)
            dispatch(setCurrentCoins(userInfo.coin))
        }, 400)
        setTimeout(() => {
            dispatch(setTriggerAddingCoin(false))
        }, 2800)
    }, [userInfo])

    return (
        <>
            {userInfo !== null && (
                <BlockInfoCoin>
                    <ImageInfo src={coinIcon} />
                    {progressCoins}
                </BlockInfoCoin>
            )}
        </>
    )
}
