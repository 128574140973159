import buttonBg from './image/greenButton1Mid@3x.png'
import arrow from './image/ExpandIcon.png'
import LanguageIcon from './image/LanguageIcon.png'
import buttonBgG from './image/button@3x.png'

import { Button } from './style'

interface imagesI {
    language: string
}
const images: imagesI = {
    language: LanguageIcon,
}

export const ButtonL = (props: any) => {
    const { name, icon, onClick } = props

    return (
        <Button onClick={onClick}>
            <img src={buttonBg} />
            <div>
                {icon && (
                    <span>
                        <img src={images[icon as keyof imagesI]} />
                    </span>
                )}
                <span className={'text'}>{name}</span>
                {icon && (
                    <span>
                        <img src={arrow} />
                    </span>
                )}
            </div>
        </Button>
    )
}
export const ButtonG = (props: any) => {
    const { name, icon, onClick } = props

    return (
        <Button onClick={onClick} className={props?.className}>
            <img src={buttonBgG} />
            <div>
                {icon && (
                    <span>
                        <img src={images[icon as keyof imagesI]} />
                    </span>
                )}
                <span className={'text'}>{name}</span>
                {icon && (
                    <span>
                        <img src={arrow} />
                    </span>
                )}
            </div>
        </Button>
    )
}
