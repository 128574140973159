import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store/store'

export interface PageState {
    shop: boolean
    xsolaOpen: boolean
    randevu: boolean
    openLoginInfo: boolean
    chatNameSubscriptionModal: string
}

const initialState: PageState = {
    shop: false,
    xsolaOpen: false,
    randevu: false,
    openLoginInfo: false,
    chatNameSubscriptionModal: '',
}

export const shopSlice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        setShop: (state, action) => {
            state.shop = action.payload
        },
        setOpenLoginInfo: (state, action) => {
            state.openLoginInfo = action.payload
        },
        setXsolaOpen: (state, action) => {
            state.xsolaOpen = action.payload
        },
        setRandevu: (state, action) => {
            state.randevu = action.payload
        },

        setChatNameSubscriptionModal: (state, action) => {
            state.chatNameSubscriptionModal = action.payload
        },
    },
})

export const { setShop, setOpenLoginInfo, setRandevu, setXsolaOpen, setChatNameSubscriptionModal } = shopSlice.actions

export const selectShop = (state: RootState) => state.shopSlice.shop
export const selectIsXsolaOpen = (state: RootState) => state.shopSlice.xsolaOpen
export const selectOpenLoginInfo = (state: RootState) => state.shopSlice.openLoginInfo
export const selectRandevu = (state: RootState) => state.shopSlice.randevu
export const selectChatNameSubscriptionModal = (state: RootState) => state.shopSlice.chatNameSubscriptionModal

export default shopSlice.reducer
