import inlove from './emoji/inlove.png'
import wink from './emoji/wink.png'
import lol from './emoji/lol.png'
import fingerUr from './emoji/like.png'
import doubts from './emoji/doubts2.png'
import smile from './emoji/smile.png'
import heart from './emoji/heart.png'
import lol2 from './emoji/lol2.png'
import withhHearts from './emoji/hearts.png'
import kiss from './emoji/kiss.png'
import confused from './emoji/confused.png'
import shock from './emoji/shock.png'
import fire from './emoji/fire.png'
import diablo from './emoji/diablo.png'
import shampain from './emoji/shampain.png'
import sad from './emoji/sad.png'
import force from './emoji/force.png'
import facepalm from './emoji/facepalm.png'
import idea from './emoji/doubts.png'
import angry from './emoji/angry.png'
import tongue from './emoji/tongue.png'
import eggplant from './emoji/eggplant.png'
import cry from './emoji/cry.png'
import pepper from './emoji/pepper.png'

const map = {
    '<sprite name=smile_loving_eyes>': inlove,
    '<sprite name=smile_wink>': wink,
    '<sprite name=smile_laughing_tears>': lol,
    '<sprite name=smile_thumb>': fingerUr,
    '<sprite name=smile_doubt>': doubts,
    '<sprite name=smile_smile>': smile,
    '<sprite name=smile_heart>': heart,
    '<sprite name=smile_lol>': lol2,
    '<sprite name=smile_with_hearts>': withhHearts,
    '<sprite name=smile_kiss>': kiss,
    '<sprite name=smile_confusion>': confused,
    '<sprite name=smile_shock>': shock,
    '<sprite name=smile_fire>': fire,
    '<sprite name=smile_devil>': diablo,
    '<sprite name=smile_champagne>': shampain,
    '<sprite name=smile_sad>': sad,
    '<sprite name=smile_biceps>': force,
    '<sprite name=smile_facepalm>': facepalm,
    '<sprite name=smile_no_idea>': idea,
    '<sprite name=smile_evil>': angry,
    '<sprite name=smile_tongue>': tongue,
    '<sprite name=smile_eggplant>': eggplant,
    '<sprite name=smile_cry>': cry,
    '<sprite name=smile_pepper>': pepper,
}
export const iconForChat: any = {
    smile_loving_eyes: inlove,
    smile_wink: wink,
    smile_laughing_tears: lol,
    smile_thumb: fingerUr,
    smile_doubt: doubts,
    smile_smile: smile,
    smile_heart: heart,
    smile_lol: lol2,
    smile_with_hearts: withhHearts,
    smile_kiss: kiss,
    smile_shock: shock,
    smile_fire: fire,
    smile_confusion: confused,
    smile_devil: diablo,
    smile_champagne: shampain,
    smile_sad: sad,
    smile_biceps: force,
    smile_facepalm: facepalm,
    smile_no_idea: idea,
    smile_evil: angry,
    smile_tongue: tongue,
    smile_eggplant: eggplant,
    smile_cry: cry,
    smile_pepper: pepper,
}
const styleIcon = `
    max-width: 20px;
    max-height: 20px;
    position: relative;
    bottom: -2px;
    margin-right: 3px;
    margin-left: 3px;
`
export const textFormatIcon = (text: string) => {
    const replace = (text: string, map: any) =>
        Object.keys(map).reduce((result, key) => {
            let pattern = new RegExp(`${key}`, 'g')
            let value = map[key]

            let img = `<img style="${styleIcon}" src="${value}" />`

            return result.replace(pattern, img)
        }, text)

    let a = text.replace(/\[.*?\]/g, () => {
        return ''
    })

    let res = replace(a, map)

    return res
}
