import styled, { keyframes } from 'styled-components'
import '@fontsource/roboto/900.css'
import '@fontsource/roboto/300.css'

interface IHeight {
    brouser?: string
}
export const GeneralContaineritsAMAtch = styled.div`
    display: flex;
    z-index: 100;
    position: absolute;
    top: 0;
    max-width: 535px;
    padding-right: 24px;
    padding-left: 24px;
    margin: auto;
    width: 100%;
    justify-content: flex-end;
    @media (max-width: 756px) {
        padding-right: 0px;
        padding-left: 0px;
    }
`
export const Container = styled.div`
    display: flex;
    flex: 1;
`
export const GeneralContainer = styled.div`
    display: flex;
    z-index: 100;
    position: absolute;
    top: 0;
    flex-direction: column;
    max-width: 535px;
    padding-right: 24px;
    padding-left: 24px;
    margin: auto;
    width: 100%;
    flex: 1;
    height: 100%;
    @media (max-width: 756px) {
        padding-right: 0px;
        padding-left: 0px;
    }
`
export const GeneralContainerRow = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
`
export const GeneralContainerRowGeneral = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex: 1;
`
export const GeneralContainerRowLast = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 4%;
`
export const HeaderContainer = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    color: white;
    width: 53%;
    display: flex;
    flex-direction: column;
    padding-top: 10%;
`
export const GeneralContainerRowChild1 = styled.div`
    display: flex;
    position: relative;
    width: 50%;
    cursor: pointer;
`
export const GeneralContainerRowChild2 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 1;
    cursor: pointer;
    overflow: hidden;
`
export const GeneralChildBlock = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
`
export const GeneralChildBlockImage = styled.div`
    max-width: 60%;
    margin: auto;
    top: 6%;
    position: relative;
`
export const GeneralChildTextName = styled.div`
    position: absolute;
    color: #ffffffb0;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    bottom: 40%;
    font-size: 29px;
    width: 100%;
    text-align: center;
    @media (max-width: 768px) {
        font-size: 22px;
        margin-top: -7px;
    }
`
export const GeneralChildTextNameWeek = styled.div`
    position: absolute;
    color: #ffffff85;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    bottom: 25.5%;
    font-size: 12px;
    width: 100%;
    text-align: center;
    @media (max-width: 768px) {
        font-size: 12px;
    }
`
export const GeneralChildTextRovno = styled.div`
    position: absolute;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    bottom: 38.5%;
    font-size: 31px;
    width: 100%;
    text-align: center;
    margin-left: 20%;
    @media (max-width: 768px) {
        font-size: 12px;
    }
`
export const GeneralChildTextProc = styled.div`
    position: absolute;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    bottom: 44.5%;
    font-size: 36px;
    text-align: center;
    right: 8%;
    @media (max-width: 768px) {
        font-size: 12px;
    }
`
export const GeneralChildTextI = styled.div`
    position: absolute;
    color: #ffff89;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    top: 13.5%;
    font-size: 15px;
    text-align: center;
    width: 100%;
    @media (max-width: 768px) {
        font-size: 12px;
    }
`
export const GeneralChildClose = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0px;
`
export const GeneralChildCloseX = styled.div`
    width: 100%;
    height: 3px;
    background: red;
    position: absolute;
    transform: rotate(18deg);
    top: 42%;
    right: -2%;
`
export const GeneralChildCloseY = styled.div`
    width: 100%;
    height: 3px;
    background: red;
    position: absolute;
    transform: rotate(-18deg);
    top: 42%;
    right: -2%;
`
export const GeneralChildText = styled.div`
    position: absolute;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    bottom: 12.2%;
    font-size: 30px;
    width: 100%;
    text-align: center;
    text-shadow:
        0px 1px 0 rgb(35, 136, 0),
        0px -1px 0 rgb(35, 136, 0),
        1px 0px 0 rgb(35, 136, 0),
        -1px 0px 0 rgb(35, 136, 0),
        1px 1px 0 rgb(35, 136, 0),
        1px -1px 0 rgb(35, 136, 0),
        -1px 1px 0 rgb(35, 136, 0),
        -1px -1px 0 rgb(35, 136, 0);
    @media (max-width: 768px) {
        font-size: 22px;
        margin-top: -7px;
    }
`
export const GeneralChildTextSub = styled.div`
    position: absolute;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    bottom: 16.5%;
    font-size: 30px;
    width: 100%;
    text-align: center;
    text-shadow:
        0px 1px 0 rgb(35, 136, 0),
        0px -1px 0 rgb(35, 136, 0),
        1px 0px 0 rgb(35, 136, 0),
        -1px 0px 0 rgb(35, 136, 0),
        1px 1px 0 rgb(35, 136, 0),
        1px -1px 0 rgb(35, 136, 0),
        -1px 1px 0 rgb(35, 136, 0),
        -1px -1px 0 rgb(35, 136, 0);
    @media (max-width: 768px) {
        font-size: 22px;
        margin-top: -7px;
    }
`
export const GeneralChildImage = styled.img`
    max-width: 100%;
`
export const GeneralChildImageText = styled.span`
    display: flex;
    font-family: 'Roboto', sans-serif;
    color: rgb(183 86 133);
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    font-size: 13.3pt;
    position: absolute;
    bottom: 14%;
    width: 100%;
    padding-left: 6%;
    transform: rotate(-10.2deg);
    @media (max-width: 768px) {
        font-size: 13px;
    }
`
export const GeneralContainerRowChildImage1 = styled.img`
    max-width: 100%;
`
export const GeneralContainerRowChildImage2 = styled.img`
    max-width: 100%;
`
export const Block2Item = styled.div`
    display: flex;
    font-family: 'Roboto', sans-serif;
    color: rgb(183 86 133);
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 45px;

    a {
        font-family: 'Roboto', sans-serif;
        color: rgb(183 86 133);
    }
`
export const Block2ItemButton = styled.button`
    display: flex;
    font-family: 'Roboto', sans-serif;
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 900;
    width: 100%;
    justify-content: center;
    background: transparent;
    border: 0;
    cursor: pointer;
    text-decoration: underline;
    outline: 0;
    padding: 7% 0 15% 0;
`
export const Button = styled.button`
    width: 56pt;
    border: none;
    outline: none;
    margin: auto;
    margin-top: 33px;
    width: 100%;
    padding: 0;
    :hover {
        transform: scale(1.1);
    }
    img {
        max-width: 34pt;
    }
    cursor: pointer;
    background: none;
`

export const ButtonBtn = styled.button`
    width: 56pt;
    border: none;
    outline: none;
    margin: auto;
    margin-top: 3%;
    width: 6%;
    padding: 0;
    cursor: pointer;
    background: none;
    position: absolute;
    right: 8%;
    :hover {
        transform: scale(1.1);
    }
    img {
        max-width: 100%;
    }
    cursor: pointer;
    background: none;
`

export const Bg = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: 1;
    left: 0;
    display: flex;
    justify-content: center;
    right: 0;
    background: #000000e8;
`

export const ContainerGeneral = styled.div<IHeight>`
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    @media (max-width: 768px) {
        height: 100%;
    }
`
export const ContainerBocked = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    height: calc(93vh - 80pt);
    left: 0;
    z-index: 10000;
    @media (max-width: 768px) {
        height: 100%;
    }
`
export const SwiperText = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    color: rgb(0, 0, 117);
    text-align: left;
    width: 40%;
    padding: 2.2% 4%;
    font-size: 28px;
    white-space: nowrap;
    @media (max-width: 756px) {
        font-size: 20px;
    }
`
export const SwiperAvatar = styled.img`
    max-width: 93%;
    pointer-events: none;
    margin-top: 2.6%;
    border-radius: 11px;
`
export const SwiperTextCard = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    color: rgb(0, 0, 117);
    text-align: left;
    width: 40%;
    word-wrap: normal;
    font-size: 30px;
    text-align: center;
    padding: 2.2% 4%;
    margin-top: 3%;
    margin-bottom: 3%;
`
export const SwiperHeader = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: rgb(0, 0, 117);
    text-align: left;
    padding: 0% 4% 0% 4%;
    width: 92%;
    font-size: 24px;
    pointer-events: none;
    height: 100%;
    @media (max-width: 756px) {
        height: auto;
        font-size: 18px;
    }
`
export const SwiperInfo = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    flex: 1;
    pointer-events: none;
`
export const SwiperInfoNoCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    pointer-events: none;
`
export const ImageZadiak = styled.img`
    float: left;
    margin: 0 2% 0 0 !important;
    pointer-events: none;
    object-fit: contain;
    max-height: 16%;
    max-width: 6%;
    min-height: 18px;
    min-width: 20px;
    @media (max-width: 756px) {
        max-height: 22px;
        max-width: 7%;
    }
`
export const ImageZadiakBigInfo = styled.img`
    float: left;
    margin: 0 2% 0 0 !important;
    pointer-events: none;
    max-height: 6.8%;
    @media (max-width: 756px) {
        max-height: 22px;
    }
`
export const ImageLabel = styled.img`
    position: absolute;
    top: 0;
    max-width: 40% !important;
    left: 1%;
    z-index: 15;
    pointer-events: none;
`
export const ImageNew = styled.img`
    position: absolute;
    top: 20%;
    max-width: 20% !important;
    right: 0%;
    z-index: 15;
    pointer-events: none;
`
export const ItemButton = styled.button`
    border: none;
    outline: none;
    margin: auto;
    position: absolute;
    top: 5%;
    width: 7%;
    pointer-events: all;
    padding: 0;
    right: 3.6%;
    :hover {
        transform: scale(1.1);
    }
    cursor: pointer;
    background: none;
    border-radius: 0 !important;
`
export const ImageInfo = styled.img`
    max-width: 100% !important;
    z-index: 15;
    border-radius: 0 !important;
    margin-top: 0px !important;
    pointer-events: none;
`
export const BlockContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    margin-top: 20%;
`
export const TextitsAMAtch = styled.span`
    color: rgb(164 27 109);
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    width: 50%;
`
export const BlockImageCenter = styled.div`
    width: 45%;
    margin-top: 5%;
    position: relative;
    display: flex;
    flex-direction: column;
    img {
        max-width: 100%;
    }
    img.avatar {
        position: absolute;
        border-radius: 100%;
        max-width: 83.8%;
        margin-top: 8.4%;
        right: 8%;
    }
    button {
        border: none;
        outline: none;
        padding: 0;
        cursor: pointer;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 100%;
        margin-top: 35%;
        border-radius: 0 !important;
    }
    button:hover {
        transform: scale(1.1);
    }
    button img.button {
        pointer-events: none;
        position: relative;
        max-width: 100%;
    }
    button span {
        position: absolute;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin-top: -10px;
        font-size: 230%;
        text-shadow:
            0px 1px 0 rgb(35, 136, 0),
            0px -1px 0 rgb(35, 136, 0),
            1px 0px 0 rgb(35, 136, 0),
            -1px 0px 0 rgb(35, 136, 0),
            1px 1px 0 rgb(35, 136, 0),
            1px -1px 0 rgb(35, 136, 0),
            -1px 1px 0 rgb(35, 136, 0),
            -1px -1px 0 rgb(35, 136, 0);
    }
`
export const NoCards = styled.div`
    display: inline-block;
    width: 90vw;
    max-width: 93%;
    border-radius: 8px;
    opacity: 1;
    position: absolute;
    will-change: transform;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    cursor: default;
`
export const BigInfoCard = styled.div`
    display: flex;
    width: 90vw;
    max-width: 100%;
    border-radius: 8px;
    flex-direction: column;
    opacity: 1;
    position: relative;
    will-change: transform;
    align-items: center;
    flex: 1;
    transition: all 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    cursor: default;
`
const aAnimation = keyframes`
 0% { transform: scale(1) }
 50%{
     transform: scale(1.2)
  }
 100% { transform: scale(1) }
`
export const ArrowAnimation = styled.div`
    position: absolute;
    width: 60%;
    transition: 0.2s linear 50ms;
    animation-name: ${aAnimation};
    animation-duration: 1s;
    animation-iteration-count: infinite;
    img {
        max-width: 100%;
        margin-top: 63%;
        margin-right: -14%;
    }
`

export const BlockInfoAvatar = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const BlockInfoAvatarOverflow = styled.div`
    position: relative;
    margin: auto;
    display: flex;
    width: 92.5%;
    overflow: hidden;
    margin-top: 2.6%;
    border-radius: 11px;
`
export const BlockInfoAvatarText = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgb(222, 224, 237);
    bottom: 0;
`
export const BlockInfoAvatarSpan = styled.div`
    width: 70%;
    color: rgb(0 0 117);
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    i {
        font-style: normal;
        color: rgb(255 70 58);
    }
`
export const BlockInfoAvatarTextHeader = styled.div`
    width: 45%;
    color: rgb(0 0 117);
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    padding: 2%;
`
export const BlockInfoAvatarTextOr = styled.div`
    display: flex;
    width: 93%;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 2%;
    margin: auto;
    hr {
        display: flex;
        flex: 1;
    }
`
export const BlockInfoAvatarTextSpan = styled.div`
    width: 6%;
    padding: 0 3%;
    color: rgb(0 0 117);
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
`
export const ButtonTextCoin = styled.button`
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    background: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    margin: auto;
    width: 93%;
    position: relative;
    margin-top: 3%;
    border-radius: 0 !important;
    pointer-events: all;
    img {
        pointer-events: none;
        position: relative;
        max-width: 100%;
    }
    span {
        position: absolute;
        color: white;
        width: 87%;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin: auto;
        margin-top: -10px;
        font-size: 200%;
        display: flex;

        text-align: left;
        text-shadow:
            0px 1px 0 rgb(35, 136, 0),
            0px -1px 0 rgb(35, 136, 0),
            1px 0px 0 rgb(35, 136, 0),
            -1px 0px 0 rgb(35, 136, 0),
            1px 1px 0 rgb(35, 136, 0),
            1px -1px 0 rgb(35, 136, 0),
            -1px 1px 0 rgb(35, 136, 0),
            -1px -1px 0 rgb(35, 136, 0);
    }
    span i {
        font-style: normal;
        display: flex;
        flex-direction: row;
    }
    span i:first-child {
        flex: 1;
    }
`
export const ButtonTextCoin2 = styled.button`
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    background: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    margin: auto;
    width: 93%;
    position: relative;
    margin-top: 2%;
    border-radius: 0 !important;
    pointer-events: all;
    img {
        pointer-events: none;
        position: relative;
        max-width: 100%;
    }
    span {
        position: absolute;
        color: white;
        width: 87%;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin: auto;
        margin-top: -10px;
        font-size: 200%;
        display: flex;

        text-align: left;
        text-shadow:
            0px 1px 0 rgb(35, 136, 0),
            0px -1px 0 rgb(35, 136, 0),
            1px 0px 0 rgb(35, 136, 0),
            -1px 0px 0 rgb(35, 136, 0),
            1px 1px 0 rgb(35, 136, 0),
            1px -1px 0 rgb(35, 136, 0),
            -1px 1px 0 rgb(35, 136, 0),
            -1px -1px 0 rgb(35, 136, 0);
    }
    span i {
        font-style: normal;
        display: flex;
        flex-direction: row;
    }
    span i:first-child {
        flex: 1;
    }
`
export const BlockNoCard = styled.div`
    z-index: 14;
    position: absolute;
    width: 100%;
    pointer-events: none;
    display: flex;
    flex: 1;
    height: 98%;
    overflow: hidden;
    flex-direction: column;
    top: 0;
    align-items: center;
`
export const BlockNoCardImg = styled.img`
    max-width: 100%;
    pointer-events: none;
    filter: blur(20px);
`

export const BlockNoCardImgBg = styled.img`
    max-width: 100%;
    position: relative;
    left: 0;
    right: 0;
`
export const BlockImageCenterSwipe = styled.div`
    width: 45%;
    position: relative;
    display: flex;
    flex-direction: column;
    img {
        max-width: 100%;
    }
    img.avatar {
        position: absolute;
        border-radius: 100%;
        max-width: 83.8%;
        margin-top: 8.4%;
        right: 8%;
    }
    button {
        border: none;
        outline: none;
        padding: 0;
        cursor: pointer;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        width: 100%;
        margin-top: 35%;
        border-radius: 0 !important;
    }
    button:hover {
        transform: scale(1.1);
    }
    button img.button {
        pointer-events: none;
        position: relative;
        max-width: 100%;
    }
    button span {
        position: absolute;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin-top: -10px;
        font-size: 230%;
        text-shadow:
            0px 1px 0 rgb(35, 136, 0),
            0px -1px 0 rgb(35, 136, 0),
            1px 0px 0 rgb(35, 136, 0),
            -1px 0px 0 rgb(35, 136, 0),
            1px 1px 0 rgb(35, 136, 0),
            1px -1px 0 rgb(35, 136, 0),
            -1px 1px 0 rgb(35, 136, 0),
            -1px -1px 0 rgb(35, 136, 0);
    }
`
