import { BlockImageCenterSwipe, SwiperTextCard, SwiperHeader, ImageZadiakBigInfo } from './style'
import photo from './image/photo@3x.png'
import { zodiac } from './swiper'
import { useAppSelector } from '../../hooks/hooks'
import { selectSwipe } from '../../store/swipe/swipeSlice'
import { textFormatIcon } from '../../helper/textEdit'

export const BigInfoSwipe = () => {
    const characters = useAppSelector(selectSwipe)
    const lenSimvol = (text: string) => {
        let newText = textFormatIcon(text)

        return newText
    }
    return (
        <>
            <BlockImageCenterSwipe>
                <img src={photo} />
                <img
                    className={'avatar'}
                    src={
                        characters[characters.length - 1].chat_pic_url !== null
                            ? characters[characters.length - 1].chat_pic_url
                            : characters[characters.length - 1].profile_pic_url
                    }
                />
            </BlockImageCenterSwipe>
            <SwiperTextCard>{characters[characters.length - 1].profile_name.English}</SwiperTextCard>
            <SwiperHeader>
                <ImageZadiakBigInfo src={zodiac[characters[characters.length - 1].zodiac_sign.English]} />
                <div
                    dangerouslySetInnerHTML={{
                        __html: lenSimvol(characters[characters.length - 1].profile_description_big.English),
                    }}
                />
            </SwiperHeader>
        </>
    )
}
