import { useAppDispatch, useAppSelector } from './hooks'

import {
    postItemsXsollaR,
    postItemsXsollaSubR,
    selectUserInfo,
    selectXsollaVariant,
    setOrder,
    setXsollaClear,
    setBundleOpen,
    getCurrentUser,
    setBuyingSubscriptionId,
    payEvent,
    selectAmount,
    selectSubscriptions,
} from '../store/authToken/auth'
import { setChatNameSubscriptionModal, setXsolaOpen } from '../store/storeShop/shop'
import { useEffect, useState } from 'react'
import { setCurrentCoins, setSubStore } from '../store/page/pageSlice'

export const useXsollaPayments = () => {
    const xsollaItems = useAppSelector(selectXsollaVariant)
    const userInfo = useAppSelector(selectUserInfo)
    const orderAmount = useAppSelector(selectAmount)
    const subscriptions = useAppSelector(selectSubscriptions)
    const [allPhotosSubscription, setAllPhotosSubscription] = useState<any>(null)

    const [boughtBundles, setBoughtBundles] = useState({
        starterpacks: null,
        secondpackxl: null,
        unlimitedprofiles: null,
        allPhotosSubscription: false,
    })

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!subscriptions) return
        setAllPhotosSubscription(subscriptions.find((element: any) => element.localized_name === 'ALL PHOTOS'))
    }, [subscriptions])

    useEffect(() => {
        if (!userInfo || !subscriptions) return

        const userAllPhotosSubscription = Object.entries(userInfo.subscriptions)?.find(
            ([id]) => id === allPhotosSubscription?.external_id,
        )

        setBoughtBundles({
            starterpacks: userInfo?.orders.find((element: any) => element.sku === 'starterpacks'),
            secondpackxl: userInfo?.orders.find((element: any) => element.sku === 'starterpackxl'),
            unlimitedprofiles: userInfo?.orders.find((element: any) => element.sku === 'unlimitedprofiles'),
            allPhotosSubscription: userAllPhotosSubscription !== undefined && userAllPhotosSubscription[1] !== null,
        })
    }, [userInfo, subscriptions, allPhotosSubscription])

    const finishXsollaPurchase = async (idOrder: string) => {
        await dispatch(getCurrentUser())
        dispatch(setBuyingSubscriptionId(null))
        dispatch(setXsolaOpen(false))
        dispatch(setXsollaClear())
        dispatch(setBuyingSubscriptionId(null))
        dispatch(setChatNameSubscriptionModal(''))
        dispatch(setBundleOpen(false))

        // @ts-ignore
        window.dataLayer.push({ event: 'Purchase' })
        await dispatch(payEvent({ userInfo, amount: orderAmount, id: idOrder }))
    }

    const setXsollaOrder = (sku: string, price: number) => {
        dispatch(setOrder({ amount: price, idOrder: sku }))
        dispatch(setXsolaOpen(true))
    }

    const purchaseXsollaItem = async (sku: string, price: number) => {
        setXsollaOrder(sku, price)
        await dispatch(postItemsXsollaR(sku))
    }

    const purchaseXsollaSubscription = async (sku: string, price: number) => {
        setXsollaOrder(sku, price)
        dispatch(setBuyingSubscriptionId(sku))
        await dispatch(postItemsXsollaSubR(sku))
    }

    const getXsollaItem = (sku: string): any => {
        return xsollaItems.find((item: any) => item.sku === sku)
    }

    const checkIfEnoughCurrency = (price: number, currency: 'gift' | 'coin'): boolean => {
        if (userInfo[currency] >= price) {
            dispatch(setCurrentCoins(userInfo[currency] - price))
            dispatch(getCurrentUser())
            return true
        }

        dispatch(setSubStore(currency))
        dispatch(setBundleOpen(true))

        return false
    }

    return {
        xsollaItems,
        purchaseXsollaItem,
        getXsollaItem,
        purchaseXsollaSubscription,
        finishXsollaPurchase,
        setXsollaOrder,
        checkIfEnoughCurrency,
        allPhotosSubscription,
        subscriptions,
        boughtBundles,
    }
}
