import { Dialog, DialogContent, Slide } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { setBundleOpen, selectBundleOpen } from '../../store/authToken/auth'
import { setShop } from '../../store/storeShop/shop'
import React, { useEffect } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import { AutoBundle } from '../Bundles/AutoBundle'
import { useXsollaPayments } from '../../hooks/useXsollaPayments'

export const BundleDialog = () => {
    const { boughtBundles } = useXsollaPayments()
    const isBundleOpen = useAppSelector(selectBundleOpen)
    const dispatch = useAppDispatch()

    const dialogContentStyles = {
        padding: '5px 5px 13px 5px',
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    }

    let bundleToOpen: 'starterpacks' | 'starterpackxl' | 'unlimitedprofiles' = 'unlimitedprofiles'

    useEffect(() => {
        if (boughtBundles.unlimitedprofiles && boughtBundles.starterpacks && boughtBundles.secondpackxl) {
            closePopupBundle()
        }
    }, [isBundleOpen])

    let closePopupBundle = () => {
        dispatch(setBundleOpen(false))
        dispatch(setShop(true))
    }

    return (
        <Dialog
            open={isBundleOpen && bundleToOpen !== null}
            TransitionComponent={Transition}
            keepMounted
            fullWidth
            maxWidth={'xs'}
            PaperProps={{
                style: { borderRadius: '11.7pt' },
            }}
            onClose={closePopupBundle}
            aria-describedby="alert-dialog-slide-description1"
        >
            <DialogContent sx={dialogContentStyles}>
                <AutoBundle isDialog={true} />
            </DialogContent>
        </Dialog>
    )
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction={'right'} ref={ref} {...props} />
})
