import { iconForChat, textFormatIcon } from '../../helper/textEdit'
import { useAppSelector } from '../../hooks/hooks'
import {
    ChatMessageContainer,
    UserMessageContainer,
    BotMessageContainer,
    TextMessageContainer,
    LoaderPoints,
    AvatarImage,
    MessageReaction,
    MessageImageContainer,
    MessageImage,
    MessageButtonCurrency,
    MessageButtonCurrencyBackground,
    ChatBottomGift,
    ChatLeftTextExtraPhoto,
    BotImageMessageContainer,
    AvatarContainer,
    ImageMessageBackground,
    BlurredImageMessage,
} from '../../page/chatItem/style'
import { AvatarBackgroundImage } from '../../page/chats/style'
import { selectCurrentLanguage } from '../../store/page/pageSlice'
import bgPhoto from '../../page/chats/image/directAvatarBase@3x.png'
import { LikeButton } from '../likeButtonChat/LikeButton'
import React from 'react'
import vipButton from '../../page/chatItem/image/vipYellBut@3x.png'
import greenButton from '../../page/chatItem/image/bigGreenButton@3x.png'
import hot from '../../page/chatItem/image/galleryHotFrame@3x.png'
import { useXsollaPayments } from '../../hooks/useXsollaPayments'
import hotLock from '../../page/chatItem/image/hotLock@3x.png'

interface IChatMessage {
    chat: any
    message: any
    messageIndex: number
    chatMessages: any
    resources: any
    resourcesUrl: any
    onSetResources: any
    onBuyPhoto: any
    onScrollToBottom: any
}

export const ChatMessage = ({
    chat,
    message,
    chatMessages,
    messageIndex,
    resources,
    resourcesUrl,
    onSetResources,
    onBuyPhoto,
    onScrollToBottom,
}: IChatMessage) => {
    const currentLanguage = useAppSelector(selectCurrentLanguage)

    const { boughtBundles } = useXsollaPayments()

    const drawBotAvatar = () => (
        <>
            <AvatarContainer className={'left'}>
                <AvatarBackgroundImage src={bgPhoto} />
                <AvatarImage src={chat.chat_pic_url} />
            </AvatarContainer>

            {message.loader && (
                <TextMessageContainer bgColor={'#e8e8e8'}>
                    <LoaderPoints key={message.id + 'loader'}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </LoaderPoints>
                </TextMessageContainer>
            )}
        </>
    )

    const drawTextMessage = () => {
        const isUser = message.is_user_action
        const isPaidMessage = message.price === 'coins2'

        let messageTextColor = isUser ? '#fff' : '#000'
        let messageBgColor = '#e8e8e8'

        if (isUser) {
            messageBgColor = isPaidMessage ? '#e89734' : '#19ace4'
        }

        return (
            message.translations[currentLanguage] && (
                <TextMessageContainer bgColor={messageBgColor} colorText={messageTextColor} isUser={isUser}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: textFormatIcon(message.translations[currentLanguage]),
                        }}
                    />

                    {chatMessages[messageIndex + 1]?.type === 'SmileVariant' &&
                        !(chatMessages[messageIndex + 1]?.id in resources) &&
                        Object.keys(chatMessages[messageIndex + 1].resources).length > 1 && (
                            <LikeButton
                                data={chatMessages[messageIndex + 1]}
                                chatMessages={chatMessages}
                                chatId={chat.id}
                                resources={resources}
                                setResources={onSetResources}
                            />
                        )}
                    {chatMessages.length !== messageIndex + 1 &&
                        chatMessages[messageIndex + 1].type === 'SmileVariant' &&
                        chatMessages[messageIndex + 1].id in resources && (
                            <MessageReaction
                                src={iconForChat[resources[chatMessages[messageIndex + 1].id].SmileVariant]}
                            />
                        )}
                </TextMessageContainer>
            )
        )
    }

    const drawPhotoMessage = (type: 'ExtraPhoto' | 'Photo') => {
        const handleBuy = () => {
            onBuyPhoto(
                message.id,
                Object.keys(message.resources_urls)[0],
                message.resources_urls[Object.keys(message.resources_urls)[0]],
            )
        }

        return (
            message.resources !== null && (
                <BotImageMessageContainer>
                    {type === 'ExtraPhoto' && (
                        <TextMessageContainer bgColor={'#fd5226'}>
                            <ChatLeftTextExtraPhoto>
                                The relationship level has grown and a special photo has been shared with you
                            </ChatLeftTextExtraPhoto>
                        </TextMessageContainer>
                    )}

                    <MessageImageContainer bgColor={'#e8e8e8'}>
                        {(!(message.id in resources) || !boughtBundles.allPhotosSubscription) && (
                            <BlurredImageMessage>
                                <MessageImage
                                    onLoad={() => onScrollToBottom(messageIndex)}
                                    src={message.resources_urls[Object.keys(message.resources_urls)[0]]}
                                />

                                {message.load && (
                                    <>
                                        <ImageMessageBackground src={hot} />
                                        <div className="so-hot-message">
                                            <div>WOW!</div>
                                            <div>SO HOT!</div>
                                        </div>

                                        <MessageButtonCurrency onClick={handleBuy}>
                                            <button>
                                                {!boughtBundles.allPhotosSubscription && (
                                                    <img className="hot-lock" src={hotLock} />
                                                )}
                                                <MessageButtonCurrencyBackground
                                                    src={boughtBundles.allPhotosSubscription ? greenButton : vipButton}
                                                />
                                                <span>OPEN</span>
                                            </button>
                                        </MessageButtonCurrency>
                                    </>
                                )}
                            </BlurredImageMessage>
                        )}

                        {boughtBundles.allPhotosSubscription && message.id in resources && (
                            <>
                                <MessageImage
                                    onLoad={() => onScrollToBottom()}
                                    src={resourcesUrl[Object.keys(message.resources_urls)[0]]}
                                />

                                <ChatBottomGift></ChatBottomGift>
                            </>
                        )}
                    </MessageImageContainer>
                </BotImageMessageContainer>
            )
        )
    }

    const drawBotMessage = (children: React.ReactNode) => {
        const invincibleMessageTypes = ['SympathyReward', 'SmileVariant', 'ChapterEnd', 'ChapterStart']
        const shouldDrawAvatar =
            chatMessages[messageIndex + 1]?.is_user_action ||
            invincibleMessageTypes.includes(chatMessages[messageIndex + 1]?.type)

        return (
            <BotMessageContainer className={shouldDrawAvatar && 'leftChat'}>
                {drawBotAvatar()}
                {children}
            </BotMessageContainer>
        )
    }

    const drawUserMessage = (children: React.ReactNode) => <UserMessageContainer>{children}</UserMessageContainer>

    let result

    switch (message.type) {
        case '':
            result = drawTextMessage()
            break
        case 'Variant':
            result = drawTextMessage()
            break
        case 'Photo':
            result = drawPhotoMessage('Photo')
            break
        case 'ExtraPhoto':
            result = drawPhotoMessage('ExtraPhoto')
            break
        default:
            return null
    }

    return (
        <ChatMessageContainer>
            {message.is_user_action ? drawUserMessage(result) : drawBotMessage(result)}
        </ChatMessageContainer>
    )
}
