import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from './store/store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import { PersistGate } from 'reduxjs-toolkit-persist/lib/integration/react'
import { persistStore } from 'reduxjs-toolkit-persist'

const container = document.getElementById('root')!
const root = createRoot(container)
let persistor = persistStore(store)

root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
