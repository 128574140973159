import styled from 'styled-components/macro'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/900.css'

export const GeneralContainer = styled.div`
    position: relative;
    width: 63%;
    display: flex;
    flex: 1;
    margin-top: 60px;
    justify-content: start;
    flex-direction: column;
    align-items: center;
`
export const ImageCoinMoney = styled.img`
    max-width: 70%;
    margin-top: 5%;
`
export const ImageCoinBandle = styled.img`
    max-width: 100%;
`
export const ImageCoinBandle2 = styled.img`
    max-width: 103%;
`
export const TextHeaderRemove = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 24px;
    text-align: center;
    color: #000441;
`
export const TextRemove = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 22px;
    text-align: center;
    color: #000441;
    padding: 24px 20px 0px 20px;
`
export const TextWelcome = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 24px;
    text-align: center;
    color: #000441;
`
export const TextWelcome2 = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 20px;
    text-align: center;
    color: #000441;
    margin-top: 5%;
`
export const TextWelcome2Offer = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 30px;
    position: absolute;
    top: 10%;
    text-align: center;
    color: rgb(255 250 174);
    text-shadow:
        0px 1px 0 rgb(242 43 62),
        0px -1px 0 rgb(242 43 62),
        1px 0px 0 rgb(242 43 62),
        -1px 0px 0 rgb(242 43 62),
        1px 1px 0 rgb(242 43 62),
        1px -1px 0 rgb(242 43 62),
        -1px 1px 0 rgb(242 43 62),
        -1px -1px 0 rgb(242 43 62);
`
export const TextWelcome2Offer2 = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 30px;
    position: absolute;
    top: 10%;
    width: 100%;
    text-align: center;
    color: rgb(255 250 174);
    text-shadow:
        0px 1px 0 rgb(242 43 62),
        0px -1px 0 rgb(242 43 62),
        1px 0px 0 rgb(242 43 62),
        -1px 0px 0 rgb(242 43 62),
        1px 1px 0 rgb(242 43 62),
        1px -1px 0 rgb(242 43 62),
        -1px 1px 0 rgb(242 43 62),
        -1px -1px 0 rgb(242 43 62);
    @media (max-width: 768px) {
        top: 6%;
    }
`

export const BackArrow = styled.button`
    width: 50pt;
    height: 26pt;
    border: none;
    outline: none;
    position: relative;
    padding: 0;
    left: -20px;
    :hover {
        transform: scale(1.1);
    }
    img {
        max-width: 65%;
    }
    cursor: pointer;
    background: none;
`
export const Price = styled.div`
    display: flex;
    width: 75%;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-bottom: 5%;
    margin-top: 5%;
`
export const Price2 = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    margin-bottom: 5%;
    position: absolute;
    top: 22%;
    background: #f4638a;
    border-radius: 100px;
    padding: 0px 7% 0px 0px;
    margin-left: 4%;
`

export const CoinBlock = styled.div`
    display: flex;
    font-size: 22px;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    position: relative;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-right: 14%;
    color: #ffa700;
    img {
        max-width: 100%;
        max-height: 100%;
        margin-right: 9%;
        object-fit: contain;
    }
`
export const CoinBlock2 = styled.div`
    display: flex;
    font-size: 30px;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    position: relative;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-right: 30%;
    color: #fff;
    img {
        max-width: 100%;
        max-height: 112%;
        margin-right: 9%;
        object-fit: contain;
    }
`
export const CoinBlock3 = styled.div`
    display: flex;
    font-size: 30px;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    position: relative;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-right: 30%;
    color: #fff;
    img {
        max-width: 100%;
        max-height: 112%;
        margin-right: 9%;
        object-fit: contain;
    }
    @media (max-width: 768px) {
        font-size: 24px;
    }
`
export const GiftBlock = styled.div`
    display: flex;
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 22px;
    justify-content: center;
    height: 100%;
    align-items: center;
    color: #ffa700;
    img {
        max-width: 100%;
        max-height: 100%;
        margin-right: 9%;
        object-fit: contain;
    }
`
export const GiftBlock2 = styled.div`
    display: flex;
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 30px;
    justify-content: center;
    height: 100%;
    align-items: center;
    color: #fff;
    img {
        max-width: 100%;
        max-height: 112%;
        margin-right: 9%;
        object-fit: contain;
    }
`
export const GiftBlock3 = styled.div`
    display: flex;
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 30px;
    justify-content: center;
    height: 100%;
    align-items: center;
    color: #fff;
    img {
        max-width: 100%;
        max-height: 112%;
        margin-right: 9%;
        object-fit: contain;
    }
    @media (max-width: 768px) {
        font-size: 24px;
    }
`
export const BlockButtonCollect = styled.button`
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 40%;
    margin-top: 20px;
    border-radius: 0 !important;
    :hover {
        transform: scale(1.1);
    }
    img {
        pointer-events: none;
        position: relative;
        max-width: 100%;
    }
    span {
        position: absolute;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin-top: -11px;
        font-size: 22px;
        text-shadow:
            0px 1px 0 rgb(35, 136, 0),
            0px -1px 0 rgb(35, 136, 0),
            1px 0px 0 rgb(35, 136, 0),
            -1px 0px 0 rgb(35, 136, 0),
            1px 1px 0 rgb(35, 136, 0),
            1px -1px 0 rgb(35, 136, 0),
            -1px 1px 0 rgb(35, 136, 0),
            -1px -1px 0 rgb(35, 136, 0);
        @media (max-width: 768px) {
            font-size: 14px;
            margin-top: -7px;
        }
    }
`
export const BlockButtonCollect2 = styled.button`
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    position: relative;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 40%;
    margin-top: 15%;
    border-radius: 0 !important;
    :hover {
        transform: scale(1.1);
    }
`
export const BlockButtonCollect4 = styled.button`
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 40%;
    position: absolute;
    bottom: 10px;
    margin-top: 15%;
    border-radius: 0 !important;
    :hover {
        transform: scale(1.1);
    }
`
export const BlockBandlInfoText2 = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    color: white;
    font-size: 20px;
    text-shadow:
        -0 -2px 0 #7a19bf,
        0 -2px 0 #7a19bf,
        -0 2px 0 #7a19bf,
        0 2px 0 #7a19bf,
        -2px -0 0 #7a19bf,
        2px -0 0 #7a19bf,
        -2px 0 0 #7a19bf,
        2px 0 0 #7a19bf,
        -1px -2px 0 #7a19bf,
        1px -2px 0 #7a19bf,
        -1px 2px 0 #7a19bf,
        1px 2px 0 #7a19bf,
        -2px -1px 0 #7a19bf,
        2px -1px 0 #7a19bf,
        -2px 1px 0 #7a19bf,
        2px 1px 0 #7a19bf,
        -2px -2px 0 #7a19bf,
        2px -2px 0 #7a19bf,
        -2px 2px 0 #7a19bf,
        2px 2px 0 #7a19bf,
        -2px -2px 0 #7a19bf,
        2px -2px 0 #7a19bf,
        -2px 2px 0 #7a19bf,
        2px 2px 0 #7a19bf;
    padding: 10px 23px;
    span {
        font-size: 15px;
    }
    @media (max-width: 768px) {
        font-size: 14px;
        span {
            font-size: 11px;
        }
    }
`
export const BlockButtonCollect2Text1 = styled.span`
    position: absolute;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    margin-top: -11px;
    font-size: 22px;
    text-shadow:
        0px 1px 0 rgb(35, 136, 0),
        0px -1px 0 rgb(35, 136, 0),
        1px 0px 0 rgb(35, 136, 0),
        -1px 0px 0 rgb(35, 136, 0),
        1px 1px 0 rgb(35, 136, 0),
        1px -1px 0 rgb(35, 136, 0),
        -1px 1px 0 rgb(35, 136, 0),
        -1px -1px 0 rgb(35, 136, 0);
    @media (max-width: 768px) {
        font-size: 14px;
        margin-top: -7px;
    }
`
export const BlockButtonCollect2Text2 = styled.span`
    position: absolute;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    top: -51%;
    color: rgb(255 195 249);
    font-size: 22px;
    text-shadow:
        0px 1px 0 rgb(208 40 50),
        0px -1px 0 rgb(208 40 50),
        1px 0px 0 rgb(208 40 50),
        -1px 0px 0 rgb(208 40 50),
        1px 1px 0 rgb(208 40 50),
        1px -1px 0 rgb(208 40 50),
        -1px 1px 0 rgb(208 40 50),
        -1px -1px 0 rgb(208 40 50);
    @media (max-width: 768px) {
        font-size: 14px;
        margin-top: 0px;
    }
`
export const BlockImage2 = styled.img`
    pointer-events: none;
    position: relative;
    max-width: 100%;
`
export const BlockImage3 = styled.img`
    pointer-events: none;
    position: absolute;
    max-width: 67%;
    top: -66%;
`
export const BlockClose = styled.div`
    z-index: 10000;
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0px;
    top: 10px;
`
export const BlockLine1 = styled.div`
    z-index: 10000;
    width: 40px;
    height: 2px;
    background: white;
    transform: rotate(-45deg);
    position: absolute;
    top: 19px;
`
export const BlockLine2 = styled.div`
    z-index: 10000;
    width: 40px;
    height: 2px;
    background: white;
    transform: rotate(45deg);
    position: absolute;
    top: 19px;
`

export const BlockPAy = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000000;
    top: 0;
    left: 0px;
`

export const BlockVideo = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000000;
    background: black;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    top: 0;
    left: 0px;
`
export const BlockPreLoad = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: column;
    box-sizing: border-box;
    top: 0;
    left: 0px;
    background: rgb(250, 153, 163);
    background: linear-gradient(180deg, rgba(250, 153, 163, 1) 0%, rgba(255, 200, 248, 1) 100%);
`
export const PreLoadText = styled.span`
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 29px;
    text-align: center;
`
export const ImagePreLoad = styled.img`
    max-width: 520px;
    padding: 0px 19px;
    width: 100%;
    box-sizing: border-box;
`
export const ImagePreLoadClock = styled.img``
export const GifImage = styled.video`
    max-width: 100%;
    object-fit: contain;
    webkit-playsinline: webkit-playsinline;
`
export const BlockButtonCancel = styled.button`
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 32%;
    border-radius: 0 !important;
    :hover {
        transform: scale(1.1);
    }
    img {
        pointer-events: none;
        position: relative;
        max-width: 100%;
    }
    span {
        position: absolute;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin-top: -11px;
        font-size: 22px;
        text-shadow:
            0px 1px 0 rgb(35, 136, 0),
            0px -1px 0 rgb(35, 136, 0),
            1px 0px 0 rgb(35, 136, 0),
            -1px 0px 0 rgb(35, 136, 0),
            1px 1px 0 rgb(35, 136, 0),
            1px -1px 0 rgb(35, 136, 0),
            -1px 1px 0 rgb(35, 136, 0),
            -1px -1px 0 rgb(35, 136, 0);
        @media (max-width: 768px) {
            font-size: 14px;
            margin-top: -7px;
        }
    }
`
export const BlockButtonDelete = styled.button`
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 32%;
    border-radius: 0 !important;
    :hover {
        transform: scale(1.1);
    }
    img {
        pointer-events: none;
        position: relative;
        max-width: 100%;
    }
    span {
        position: absolute;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin-top: -11px;
        font-size: 22px;
        text-shadow:
            0px 1px 0 rgb(35, 136, 0),
            0px -1px 0 rgb(35, 136, 0),
            1px 0px 0 rgb(35, 136, 0),
            -1px 0px 0 rgb(35, 136, 0),
            1px 1px 0 rgb(35, 136, 0),
            1px -1px 0 rgb(35, 136, 0),
            -1px 1px 0 rgb(35, 136, 0),
            -1px -1px 0 rgb(35, 136, 0);
        @media (max-width: 768px) {
            font-size: 14px;
            margin-top: -7px;
        }
    }
`
export const Text = styled.span`
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 20pt;
    color: rgb(0, 0, 117);
    display: flex;
    flex: 1;
`
export const ActiveBlock = styled.span`
    background: rgb(216, 208, 225);
    width: 17.7pt;
    height: 17.7pt;
    border-radius: 3pt;
    img {
        max-width: 100%;
    }
`
export const Block = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`
export const BlockColumn1 = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
`
export const BlockColumn2 = styled.div`
    display: flex;
    flex-direction: row;
    width: 93%;
    justify-content: space-between;
`
export const Block2Item = styled.div`
    display: flex;
    font-family: 'Roboto', sans-serif;
    color: rgb(0, 0, 117);
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 45px;
    font-size: 13.3pt;
    a {
        font-family: 'Roboto', sans-serif;
        color: rgb(0, 0, 117);
    }
`
export const BlockHorizontal = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
    margin-top: 0%;
    height: 555px;
    margin-left: -9px;
    margin-bottom: 17px;
`
export const BlockV = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 94%;
    margin: auto;
    margin-bottom: 3%;
    .field {
        margin: 3% 0px;
        width: 100%;
    }
    .field:first-child {
        margin: 0% 0px;
    }
`
export const BlockHorizontalButtom = styled.div`
    border: none;
    outline: none;
    position: relative;
    font-size: 40px;
    padding: 0px 20px;
    :hover {
        transform: scale(1.05);
    }

    cursor: pointer;
    background: none;
`
