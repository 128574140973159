import {
    ContainerGeneral,
    ChatBottom,
    ChatScroll,
    ChatButtons,
    ChatButtonsSpan,
    ChatButtonsSpanImg,
    ChatBlock,
    TimerHiddrn,
    BlockInfoGifts,
    BlockInfoGiftsText,
    BlockInfoGiftsTextDown,
    ChatButtonsCoinImageA,
    ChatButtonsSpanImgSrc,
    ChatButtonsGift,
} from './style'

import { useEffect, useRef, useState } from 'react'
import { selectCurrentLanguage, selectHeightContent } from '../../store/page/pageSlice'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import sendButton from './image/sentButton@3x.png'
import paidButton from './image/paidSentButton@3x.png'
import sendButtonCoin from './image/sentButtonCoin@3x.png'
import sendButtonGift from './image/sentButtonGift@3x.png'
import sendButtonWait from './image/sentButtonWait@3x.png'
import sendGreenButton from './image/sentButtonGreen@3x.png'
import giftIcon from './image/giftSmallIcon@3x.png'
import {
    chatIdMessageNext,
    clearChat,
    getChat,
    getChatPriceDate,
    getSkipTimeOutDate,
    postChatDebug,
    postChatId,
    postChatResourcesId,
    selectChapterNumber,
    selectChat,
    selectChatStatus,
    selectLevelNext,
    selectPrices,
    selectWait,
    setCanvasOpen,
    setChatIdDateMessageClear,
    setLevel,
    setLevelNext,
    setRelationshipProgress,
} from '../../store/chat/chat'
import { selectMatchStatus } from '../../store/swipe/swipeSlice'
import Timer from '@amplication/react-compound-timer'
import { getCurrentUser, openChatEvent, selectUserInfo, selectFirstRegistrationFlag } from '../../store/authToken/auth'
import { setChatNameSubscriptionModal, setRandevu, setOpenLoginInfo } from '../../store/storeShop/shop'
import ChatMessage from '../../components/ChatMessage'
import ChatAnswer from '../../components/ChatAnswer'
import { useXsollaPayments } from '../../hooks/useXsollaPayments'

export const buttons = {
    send: sendButton,
    paid: paidButton,
    coin: sendButtonCoin,
    gift: sendButtonGift,
    wait: sendButtonWait,
    green: sendGreenButton,
}

const waitingTextList = [
    'I need you real bad.',
    'Do you wanna chat?',
    "Can't stop thinking about you...",
    'I miss you...',
    'Hey, where have you gone?',
    'Waiting for you to be online',
]

const randomInteger = (min: number, max: number) => {
    return Math.floor(min + Math.random() * (max + 1 - min))
}

export const ChatsItem = () => {
    const dispatch = useAppDispatch()
    const heightContent = useAppSelector(selectHeightContent)
    const chat = useAppSelector(selectChat)
    const matchStatus = useAppSelector(selectMatchStatus)
    const prices = useAppSelector(selectPrices)
    const chapterNumber = useAppSelector(selectChapterNumber)
    const chatStatus = useAppSelector(selectChatStatus)
    const levelNextStatus = useAppSelector(selectLevelNext)
    const wait = useAppSelector(selectWait)
    const userInfo = useAppSelector(selectUserInfo)
    const chatMessageNext = useAppSelector(chatIdMessageNext)
    const messagesEndRef = useRef<HTMLInputElement>(null)
    const [chatAnswer, setChatAnswer] = useState<null | any>(null)
    const [chatMessages, setChatMessages] = useState<null | any>(null)
    const [chatNextBotMessages, setChatNextBotMessages] = useState<null | any>(null)
    const [resources, setResources] = useState<null | any>({})
    const [resourcesUrl, setResourcesUrl] = useState<null | any>(null)
    const [isCharacterOffline, setIsCharacterOffline] = useState(false)
    const [isAnswering, setIsAnswering] = useState(false)
    const [waitingText, setWaitingText] = useState(waitingTextList[randomInteger(0, 4)])
    const currentLanguage = useAppSelector(selectCurrentLanguage)
    const { checkIfEnoughCurrency, boughtBundles } = useXsollaPayments()
    const isNotRegistered = useAppSelector(selectFirstRegistrationFlag)

    useEffect(() => {
        const start = async () => {
            dispatch(clearChat())
            dispatch(setChatIdDateMessageClear())

            // Uid1669999617581145 - Anna Bailey end of chapter
            if (window.location.pathname !== '/') {
                await dispatch(
                    postChatDebug({
                        idMessage: window.location.pathname.substring(1),
                        id: chat.id,
                    }),
                )
            }
            await dispatch(getChat(chat.id))
            await dispatch(getChatPriceDate(chat.id))
            await dispatch(getCurrentUser())

            if (window.location.origin === 'https://loverzgame.com') {
                await dispatch(openChatEvent(userInfo))
            }
        }

        start()

        return () => {
            dispatch(clearChat())
        }
    }, [])

    const scrollToBottom = (index?: number): void => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
        if (index !== undefined) {
            let data = JSON.parse(JSON.stringify(chatMessages))
            data[index].load = true
            setChatMessages(data)
        }
    }

    const answer = async (id: string, data: any, moneyCur: number) => {
        if (checkIfEnoughCurrency(moneyCur, 'coin')) {
            setIsAnswering(true)

            setTimeout(async () => {
                setChatMessages([...chatMessages, data])
                dispatch(
                    setRelationshipProgress({
                        relationshipProgress: data.progress,
                        chapterNumber: data.chapter_number,
                    }),
                )

                await dispatch(postChatId({ idMessage: id, id: chat.id }))
                setIsAnswering(false)

                if (data.price) {
                    await dispatch(getCurrentUser())
                }
            }, 250)
        }
    }

    useEffect(() => {
        if (chatStatus !== null) {
            setChatMessages([])

            const previousMessages = [...chatStatus.messages]
            const nextBotMessages = [...chatStatus.next_bot_messages]
            const nextUserMessages = [...chatStatus.next_user_messages]
            const messageResources = Object.assign({}, chatStatus.resources || {})
            const messageResourcesUrls = Object.assign({}, chatStatus.resources_urls || {})

            if (previousMessages.length !== 0) {
                dispatch(
                    setRelationshipProgress({
                        relationshipProgress: previousMessages[0].progress,
                        chapterNumber: previousMessages[0].chapter_number,
                    }),
                )
            }

            setResourcesUrl(messageResourcesUrls)
            setResources(messageResources)

            const reversedPreviousMessages = previousMessages
                .reverse()
                .map((item: any) => (item = { ...item, load: true }))

            setChatMessages(reversedPreviousMessages)

            if (
                nextBotMessages.length === 0 &&
                reversedPreviousMessages[reversedPreviousMessages.length - 1]?.type === 'ChapterEnd'
            ) {
                setChatAnswer(null)
                setChatNextBotMessages(null)
                setIsCharacterOffline(true)
                setWaitingText(waitingTextList[randomInteger(0, 4)])
            } else {
                setChatNextBotMessages(nextBotMessages)
                setChatAnswer(nextUserMessages)
            }
        }
    }, [chatStatus])

    useEffect(() => {
        if (chatMessageNext !== null) {
            if (chatMessageNext.next_bot_messages.length > 0) {
                setChatNextBotMessages(chatMessageNext.next_bot_messages)
            }
            if (chatMessageNext.next_user_messages.length > 0) {
                setChatAnswer(chatMessageNext.next_user_messages)
            }
        }
    }, [chatMessageNext])

    useEffect(() => {
        scrollToBottom()
    }, [chatMessages])

    // Processing bot sending messages
    const processMessage = (item: any, delay = 1000) => {
        setTimeout(() => {
            setChatMessages([...chatMessages, { ...item, load: true }])
            setChatNextBotMessages(chatNextBotMessages.slice(1))
        }, delay)
    }

    useEffect(() => {
        if (matchStatus === false && chatNextBotMessages !== null && chatNextBotMessages.length !== 0) {
            const addNextMessage = () => {
                const nextMessage = chatNextBotMessages[0]

                switch (nextMessage?.type) {
                    case '': {
                        const messageLength = nextMessage.translations[currentLanguage].length
                        let data = [...chatMessages]

                        const emptyMessage = {
                            is_user_action: false,
                            translations: { [currentLanguage]: null },
                            type: '',
                            load: true,
                            loader: true,
                        }

                        setChatMessages([...data, emptyMessage])

                        setTimeout(
                            () => {
                                setChatMessages([...data, nextMessage])
                                setChatNextBotMessages(chatNextBotMessages.slice(1))
                                dispatch(
                                    setRelationshipProgress({
                                        relationshipProgress: nextMessage.progress,
                                        chapterNumber: nextMessage.chapter_number,
                                    }),
                                )
                            },
                            messageLength * 0.04 * 1000, // NOTE:  the new message appears after this delay
                        )
                        break
                    }

                    case 'SympathyReward':
                        dispatch(setLevel(true))
                        processMessage(nextMessage)
                        if (isNotRegistered) {
                            dispatch(setOpenLoginInfo(true))
                        }
                        break

                    case 'ChapterStart':
                        setIsCharacterOffline(false)
                        processMessage(nextMessage)
                        break

                    case 'Like':
                    case 'SmileVariant':
                        processMessage(nextMessage)
                        break

                    case 'ChapterEnd':
                        dispatch(setLevelNext(true))
                        setIsCharacterOffline(true)
                        setChatAnswer(null)
                        setChatNextBotMessages(null)
                        setWaitingText(waitingTextList[randomInteger(0, 4)])
                        break

                    case 'Photo':
                    case 'ExtraPhoto': {
                        const emptyPhotoMessage = { resources: null, type: nextMessage.type, load: true }
                        setChatMessages([...chatMessages, emptyPhotoMessage])

                        setTimeout(() => {
                            setChatMessages([...chatMessages, { ...nextMessage, load: true }])
                            setChatNextBotMessages(chatNextBotMessages.slice(1))
                        }, 1000)
                        break
                    }
                }
            }

            setTimeout(() => {
                addNextMessage()
            }, 1000)
        }
    }, [chatNextBotMessages, matchStatus])

    const skipTime = async (moneyCur: number) => {
        if (checkIfEnoughCurrency(moneyCur, 'gift')) {
            await dispatch(getSkipTimeOutDate(chat.id))
            await dispatch(getChat(chat.id))
            await dispatch(getCurrentUser())
            await dispatch(getChatPriceDate(chat.id))
            setIsCharacterOffline(false)
        }
    }

    const BuyPhoto = async (id: string, idRes: string, url: string) => {
        if (boughtBundles.allPhotosSubscription) {
            await dispatch(
                postChatResourcesId({
                    id: chat.id,
                    message_id: id,
                    idResources: idRes,
                    url: url,
                }),
            )
            await dispatch(getCurrentUser())
            dispatch(setCanvasOpen(true))
        } else {
            dispatch(setChatNameSubscriptionModal(chat.id))
        }
    }

    const renderWaiting = (condition: boolean) => {
        return (
            condition && (
                <ChatButtons>
                    <ChatButtonsSpan borderColor={'#989eb5'} />
                    <ChatButtonsSpanImg>
                        <ChatButtonsSpanImgSrc src={buttons.wait} />
                    </ChatButtonsSpanImg>
                </ChatButtons>
            )
        )
    }

    return (
        <ContainerGeneral heightP={heightContent}>
            <ChatScroll>
                {chatMessages !== null && (
                    <ChatBlock>
                        {chatMessages.map((chatMessage: any, index: number) => (
                            <ChatMessage
                                chat={chat}
                                key={chatMessage.id}
                                message={chatMessage}
                                messageIndex={index}
                                chatMessages={chatMessages}
                                resources={resources}
                                resourcesUrl={resourcesUrl}
                                onScrollToBottom={scrollToBottom}
                                onSetResources={setResources}
                                onBuyPhoto={BuyPhoto}
                            />
                        ))}
                    </ChatBlock>
                )}
                <div ref={messagesEndRef} />
            </ChatScroll>

            <ChatBottom>
                {chatAnswer !== null &&
                    !isAnswering &&
                    chatNextBotMessages?.length === 0 &&
                    chatAnswer.map((item: any) => (
                        <ChatAnswer
                            key={item?.id}
                            chatAnswer={item}
                            onOpenDate={() => dispatch(setRandevu(true))}
                            onAnswer={answer}
                        />
                    ))}

                {renderWaiting((chatNextBotMessages && chatNextBotMessages?.length !== 0) || isAnswering)}
                {renderWaiting(chatNextBotMessages === null && chatAnswer === null && wait === null)}

                {chat !== null &&
                    chatNextBotMessages === null &&
                    chatAnswer === null &&
                    wait !== null &&
                    !levelNextStatus &&
                    isCharacterOffline &&
                    chat.chapters !== chapterNumber && (
                        <>
                            <ChatButtons onClick={() => skipTime(prices.ChapterEnd.gift)}>
                                <ChatButtonsSpan borderColor={'#88de32'}>{waitingText}</ChatButtonsSpan>
                                <ChatButtonsSpanImg>
                                    <ChatButtonsGift>
                                        <ChatButtonsCoinImageA src={giftIcon}></ChatButtonsCoinImageA>
                                        {prices.ChapterEnd.gift}
                                    </ChatButtonsGift>
                                    <ChatButtonsSpanImgSrc src={buttons.green} />
                                </ChatButtonsSpanImg>
                            </ChatButtons>

                            <BlockInfoGifts>
                                <BlockInfoGiftsText>
                                    {chat.profile_name.English} is currently offline
                                </BlockInfoGiftsText>
                                <BlockInfoGiftsTextDown>
                                    <span>Send a message</span>
                                    to bring your match back now or wait till the character comes back
                                </BlockInfoGiftsTextDown>
                            </BlockInfoGifts>

                            <TimerHiddrn>
                                <Timer
                                    initialTime={Number(String(wait).split('.')[0]) * 1000 + 1000}
                                    direction="backward"
                                    checkpoints={[
                                        {
                                            time: 0,
                                            callback: async () => {
                                                await dispatch(getChat(chat.id))
                                            },
                                        },
                                    ]}
                                >
                                    <Timer.Hours formatValue={(value) => `${value < 10 ? `0${value}` : value}`} />
                                    :
                                    <Timer.Minutes formatValue={(value) => `${value < 10 ? `0${value}` : value}`} />
                                    :
                                    <Timer.Seconds formatValue={(value) => `${value < 10 ? `0${value}` : value}`} />
                                </Timer>
                            </TimerHiddrn>
                        </>
                    )}

                {renderWaiting(!levelNextStatus && isCharacterOffline && chat.chapters === chapterNumber)}
            </ChatBottom>
        </ContainerGeneral>
    )
}
