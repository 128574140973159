import { Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'

import {
    BackArrow,
    BlockHorizontal,
    TextHeaderRemove,
    TextRemove,
    BlockButtonCancel,
} from '../../page/profile/settings/style'
import back from '../../page/store/image/back@3x.png'
import React, { useEffect, useState } from 'react'
import { TransitionProps } from '@mui/material/transitions'

import {
    confirmEmailEvent,
    getCurrentUser,
    isRegStatusR,
    selectIsRegStatusR,
    selectUserInfo,
    setFirstRegistrationFlag,
    setRegistrationFirstImage,
    tokenXsollaPost,
} from '../../store/authToken/auth'

import buttonFon from '../../page/store/image/layer702@3x.png'
import { setOpenLoginInfo, selectOpenLoginInfo } from '../../store/storeShop/shop'
import { Widget } from '@xsolla/login-sdk'

const dialogTitle = {
    background: 'linear-gradient(90deg, rgba(172,229,255,1) 0%, rgba(255,214,237,1) 100%)',
    height: '30px',
}
const dialogContent = {
    padding: '0px 10px 13px 10px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
}
const xsollaAuthDialog = {
    padding: '5px 5px 13px 5px',
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
}

export let userDeviceArray = [
    { device: 'Android', platform: /Android/ },
    { device: 'android', platform: /android/ },
    { device: 'iPhone', platform: /iPhone/ },
    { device: 'iPad', platform: /iPad/ },
    { device: 'Symbian', platform: /Symbian/ },
    { device: 'Windows Phone', platform: /Windows Phone/ },
    { device: 'Tablet OS', platform: /Tablet OS/ },
    { device: 'Linux', platform: /Linux/ },
    { device: 'Windows', platform: /Windows NT/ },
    { device: 'Macintosh', platform: /Macintosh/ },
]

export let platform = navigator.userAgent

export const LoginForm = () => {
    const isRegStatus = useAppSelector(selectIsRegStatusR)
    const openLoginInfo = useAppSelector(selectOpenLoginInfo)
    const userInfo = useAppSelector(selectUserInfo)
    const [loginFormInstantiated, setLoginFormInstantiated] = useState(false)

    useEffect(() => {
        if (isRegStatus && !loginFormInstantiated) {
            // @ts-ignore
            let params = new URL(window.location).searchParams

            // TODO: add yandex login
            const formLoad = async () => {
                if (params.get('is_new') === '1') {
                    // @ts-ignore
                    window.dataLayer.push({ event: 'Registration' })
                    dispatch(confirmEmailEvent(userInfo))
                }

                if (params.get('token')) {
                    await dispatch(tokenXsollaPost({ token: params.get('token') }))
                    dispatch(setFirstRegistrationFlag(false))
                    dispatch(setRegistrationFirstImage(false))
                    dispatch(isRegStatusR(false))

                    await dispatch(getCurrentUser())
                    setTimeout(() => {
                        window.location.replace(window.location.origin)
                    }, 500)
                }

                const xl = new Widget({
                    projectId:
                        window.location.origin === 'https://loverzgame.com'
                            ? '14a3dc51-d2c5-4f89-8f47-810bb11a9ab0'
                            : 'ce30282c-5d66-4153-8b43-65dcec272827',
                    preferredLocale: 'en_XX',
                })

                xl.mount('xsolla_auth')

                setLoginFormInstantiated(true)
            }

            queueMicrotask(formLoad)
        }
    }, [isRegStatus])

    const dispatch = useAppDispatch()

    const closePopupLogin = () => {
        dispatch(isRegStatusR(false))
    }
    const openLoginForm = async () => {
        dispatch(setOpenLoginInfo(false))
        dispatch(isRegStatusR(true))
    }

    return (
        <>
            <Dialog
                open={isRegStatus}
                TransitionComponent={Transition}
                fullWidth
                keepMounted
                maxWidth={'xs'}
                PaperProps={{
                    style: { borderRadius: '11.7pt' },
                }}
                onClose={closePopupLogin}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={dialogTitle}>
                    <BackArrow onClick={closePopupLogin}>
                        <img src={back} />
                    </BackArrow>
                </DialogTitle>
                <DialogContent sx={xsollaAuthDialog}>
                    <BlockHorizontal id={'xsolla_auth'}></BlockHorizontal>
                </DialogContent>
            </Dialog>

            <Dialog
                open={openLoginInfo}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth={'xs'}
                PaperProps={{
                    style: { borderRadius: '11.7pt' },
                }}
                onClose={openLoginForm}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={dialogTitle}>
                    <TextHeaderRemove>Save Progress!</TextHeaderRemove>
                </DialogTitle>
                <DialogContent sx={dialogContent}>
                    <TextRemove>
                        Features:
                        <br />
                        - You can save your progress after clearing the browser cache and cookies.
                        <br />
                        - You can save all your purchases.
                        <br />- You can play on any device once you have logged in.
                    </TextRemove>
                </DialogContent>
                <DialogActions>
                    <BlockButtonCancel onClick={openLoginForm}>
                        <img src={buttonFon} />
                        <span>Ok</span>
                    </BlockButtonCancel>
                </DialogActions>
            </Dialog>
        </>
    )
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction={'right'} ref={ref} {...props} />
})
