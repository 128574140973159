import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { useEffect, useRef, useState } from 'react'
import {
    Container,
    GeneralContaineritsAMAtch,
    BlockContainer,
    TextitsAMAtch,
    BlockImageCenter,
    TextitsAMAtchSmile,
    TextitsAMAtchPhoto,
    GiftBlock,
} from './style'
import photo from './image/photo@3x.png'
import bigGreen from './image/bigGreenButton@3x.png'
import { selectChapterNumber, selectChat, setLevelNext } from '../../store/chat/chat'
import coinGift from './image/coinsDailyRewardCopy.png'
import { getCurrentUser, setTriggerAddingCoin } from '../../store/authToken/auth'

export const LevelNext = () => {
    const dispatch = useAppDispatch()
    const flag = useRef(0)
    const chat = useAppSelector(selectChat)
    const сuptoreNumber = useAppSelector(selectChapterNumber)
    const [showButton, setShowButton] = useState(true)
    const block = useRef(1)

    useEffect(() => {
        if (flag.current === 0) {
            flag.current = 1
        }
    }, [])

    const closeStore = async () => {
        if (block.current === 1) {
            block.current = 0
            setShowButton(false)
            dispatch(setTriggerAddingCoin(true))
            await dispatch(getCurrentUser())

            setTimeout(() => {
                dispatch(setLevelNext(false))
            }, 3500)
        }
    }
    return (
        <GeneralContaineritsAMAtch>
            <Container>
                <BlockContainer>
                    <TextitsAMAtch>
                        Wow, your relationship with {chat.profile_name.English} has reached a new level!
                    </TextitsAMAtch>
                    <TextitsAMAtchSmile>Relationship Level {сuptoreNumber}</TextitsAMAtchSmile>
                    <BlockImageCenter>
                        <img src={photo} />
                        <img className={'avatar'} src={chat.chat_pic_url} />
                        <TextitsAMAtchPhoto>Rewards</TextitsAMAtchPhoto>
                        <GiftBlock>
                            <img src={coinGift} />
                            <span>x 10</span>
                        </GiftBlock>
                        {showButton && (
                            <button onClick={closeStore}>
                                <img className={'button'} src={bigGreen} />
                                <span>Get</span>
                            </button>
                        )}
                    </BlockImageCenter>
                </BlockContainer>
            </Container>
        </GeneralContaineritsAMAtch>
    )
}
