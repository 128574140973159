import styled from 'styled-components/macro'
import '@fontsource/roboto/900.css'

interface MenuI {
    zIndex?: number
}
interface BlockEventI {
    width?: number
}
interface ProgressBarI {
    relationshipProgress?: number
}
export const Menu = styled.div<MenuI>`
    z-index: ${(props) => props.zIndex || 10};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding-top: 13px;
    padding-bottom: 10px;
`
export const MenuChat = styled.div<MenuI>`
    z-index: ${(props) => props.zIndex || 10};
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
`
export const ButtonPhotoChat = styled.button`
    border: none;
    outline: none;
    display: flex;
    width: 40pt;
    height: 40pt;
    min-width: 53.33px;
    right: -4px;
    position: relative;
    padding: 0;
    :hover {
        transform: scale(1.1);
    }
    img {
        max-width: 100%;
        object-fit: contain;
        display: block;
    }
    cursor: pointer;
    background: none;
`
export const ProgressBar = styled.div`
    display: flex;
    width: 100%;

    margin-right: 4%;
    margin-left: 4%;
    justify-content: center;
    align-items: center;
`
export const ProgressBarFull = styled.div`
    display: flex;
    width: 100%;
    border-radius: 10px;
    height: 15px;
    background: #cfc5e9;
    box-shadow: inset 0px 0px 1px 2px #bbafcc;
`
export const ProgressBarLoader = styled.div`
    display: flex;
    width: 70%;
    border-radius: 10px;
    height: 15px;
    align-items: center;
    position: relative;
`
export const ProgressBarLoaderNext = styled.div`
    display: flex;
    width: 30%;
    border-radius: 10px;
    height: 15px;
    align-items: center;
    position: relative;
`
export const ProgressBarLoaderNow = styled.div<ProgressBarI>`
    display: flex;
    width: ${(props) => props.relationshipProgress + '%' || '0%'};
    border-radius: 10px;
    background: #ff7880;
    margin-left: 1px;
    height: 13px;
    transition: all 0.5s;
    box-shadow: inset 0px -4px 7px 1px #ff5460;
`
export const HeadBlock = styled.div`
    position: absolute;
    right: -10px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const BlockEvent = styled.div<BlockEventI>`
    width: ${(props) => props.width + '%' || '0%'};
    position: absolute;
    height: 15px;
`
export const BlockEventCoor = styled.div`
    position: absolute;
    right: 0px;
    width: 2px;
    background: white;
    height: 15px;
`
export const BlockEventCoorImage = styled.img`
    position: absolute;
    max-width: 17px;
    right: -8px;
    top: -19px;
`
export const BlockEventCoorImageShar = styled.img`
    position: absolute;
    max-width: 15px;
    right: -8px;
    top: -27px;
`
export const HeadBlock2 = styled.div`
    position: absolute;
    right: -10px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const HeadBlockImage = styled.img`
    max-width: 100%;
`
export const HeadBlockText = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    color: white;
    font-size: 12px;
    position: absolute;
`
export const BlockFlexCol = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`
export const BlockFlexColCenter = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`
export const BlockFlexRow = styled.div`
    display: flex;
    flex-direction: row;
`
export const BlockFlexRowBet = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
export const BackArrow = styled.button`
    width: 56pt;
    height: 56pt;
    border: none;
    outline: none;
    position: absolute;
    padding: 0;
    :hover {
        transform: scale(1.1);
    }
    img {
        max-width: 65%;
    }
    cursor: pointer;
    background: none;
`
export const BackArrowChat = styled.button`
    width: 40pt;
    height: 40pt;
    min-width: 53.33px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    padding: 0;
    :hover {
        transform: scale(1.1);
    }
    img {
        max-width: 65%;
    }
    cursor: pointer;
    background: none;
    @media (max-width: 768px) {
        justify-content: left;
        align-items: center;
    }
`
export const ContainerBlockChatItemAvatar = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 40pt;
    height: 40pt;
    top: -5px;
`
export const ContainerBlockChatItemName = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    color: rgb(0 0 117);
    white-space: nowrap;
    position: absolute;
    bottom: -30%;
`
export const ItemMenu = styled.button`
    width: 48pt;
    height: 48pt;
    border: none;
    outline: none;
    padding: 0;
    :hover {
        transform: scale(1.1);
    }
    cursor: pointer;
    background: none;
`
export const ItemMenuActive = styled.button`
    width: 48pt;
    height: 48pt;
    cursor: pointer;
    border: none;
    outline: none;
    background: none;
    padding: 0;
`
export const ImageMenu = styled.img`
    max-width: 48pt;
    max-height: 48pt;
`
