import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store/store'

export interface PageState {
    page: string
    subPage: string
    oldPage: string
    store: boolean
    subStore: string
    isFadeSub: boolean
    isFadePage: boolean
    isFadeBg: boolean
    isLike: boolean
    isNoCards: boolean
    isBigInfo: boolean
    isFirstOpen: boolean
    heightContent: number
    language: string
    currentCoins: number
}

const initialState: PageState = {
    page: 'slider',
    subPage: 'general',
    oldPage: '',
    store: false,
    subStore: 'coin',
    isFadeSub: true,
    isFadePage: true,
    isFadeBg: true,
    isLike: false,
    isNoCards: false,
    isBigInfo: false,
    isFirstOpen: false,
    heightContent: 0,
    language: 'en',
    currentCoins: 0,
}

export const pageSlice = createSlice({
    name: 'page',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setPage: (state, action) => {
            state.page = action.payload.name
            if ('oldPage' in action.payload) {
                state.oldPage = action.payload.oldPage
            }
            state.subPage = action.payload.subPage
            state.isFadeSub = action.payload.isFadeSub
            state.isFadePage = action.payload.isFadePage
            state.isFadeBg = action.payload.isFadeBg
        },
        setHeightContent: (state, action) => {
            state.heightContent = action.payload
        },
        openStore: (state, action) => {
            state.store = action.payload
        },
        setSubStore: (state, action) => {
            state.subStore = action.payload
        },
        clearPage: (state) => {
            state.page = 'slider'
            state.subPage = 'general'
            state.oldPage = ''
            state.store = false
            state.isFadeSub = true
            state.isFadePage = true
            state.isFadeBg = true
            state.isLike = false
            state.isNoCards = false
            state.isBigInfo = false
            state.isFirstOpen = false
            state.heightContent = 0
        },
        isFadeSub: (state, action) => {
            state.isFadeSub = action.payload
        },
        isFadePage: (state, action) => {
            state.isFadePage = action.payload
        },
        isFadeBg: (state, action) => {
            state.isFadeBg = action.payload
        },
        isLike: (state, action) => {
            state.isLike = action.payload
        },
        isNoCards: (state, action) => {
            state.isNoCards = action.payload
        },
        isBigInfo: (state, action) => {
            state.isBigInfo = action.payload
        },

        isFirstOpen: (state, action) => {
            state.isFirstOpen = action.payload
        },

        setCurrentLangugae: (state, action) => {
            state.language = action.payload
        },

        setCurrentCoins: (state, action) => {
            state.currentCoins = action.payload
        },
    },
})

export const {
    setPage,
    openStore,
    isFadeSub,
    isFadePage,
    isFadeBg,
    setHeightContent,
    isLike,
    isNoCards,
    setSubStore,
    isBigInfo,
    isFirstOpen,
    clearPage,
    setCurrentLangugae,
    setCurrentCoins,
} = pageSlice.actions

export const selectPage = (state: RootState) => state.page.page
export const selectSubPage = (state: RootState) => state.page.subPage
export const selectSubStore = (state: RootState) => state.page.subStore
export const selectStore = (state: RootState) => state.page.store
export const selectIsFadeSub = (state: RootState) => state.page.isFadeSub
export const selectIsFadePage = (state: RootState) => state.page.isFadePage
export const selectIsFadeBg = (state: RootState) => state.page.isFadeBg
export const selectIsLike = (state: RootState) => state.page.isLike
export const selectIsNoCards = (state: RootState) => state.page.isNoCards
export const selectOldPage = (state: RootState) => state.page.oldPage
export const selectIsBigInfo = (state: RootState) => state.page.isBigInfo
export const selectIsFirstOpen = (state: RootState) => state.page.isFirstOpen
export const selectHeightContent = (state: RootState) => state.page.heightContent
export const selectCurrentLanguage = (state: RootState) => state.page.language
export const selectCurrentCoins = (state: RootState): number => state.page.currentCoins
export default pageSlice.reducer
