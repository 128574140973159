import { Dialog, DialogContent, Slide } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { selectChatNameSubscriptionModal, setChatNameSubscriptionModal } from '../../store/storeShop/shop'
import React, { useEffect, useState } from 'react'
import { TransitionProps } from '@mui/material/transitions'
import { SubscriptionModalImage, SubscriptionModalInfo, BackButton, AllGirlsImage } from './style'
import { ButtonG } from '../button/button'
import back from '../../page/store/image/back.png'
import { useXsollaPayments } from '../../hooks/useXsollaPayments'
import allGirls from './img/all_girls.png'

export const SubscriptionModal = () => {
    const chatName = useAppSelector(selectChatNameSubscriptionModal)
    const dispatch = useAppDispatch()
    const [image, setImage] = useState('')
    const { purchaseXsollaSubscription, allPhotosSubscription } = useXsollaPayments()

    useEffect(() => {
        if (chatName) {
            const loadImage = async () => {
                const image = await import(`./img/${chatName}.png`)
                setImage(image.default)
            }
            loadImage()
        }
    }, [chatName])

    const dialogContentStyles = {
        padding: '0px',
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        background: 'linear-gradient(0,  #560664 10%,  #a8a0f9 40%, #c7b3f9 100%)',
    }

    let closePopupBundle = () => {}

    return (
        <Dialog
            open={chatName !== ''}
            aria-describedby="alert-dialog-slide-description1"
            TransitionComponent={Transition}
            keepMounted
            fullScreen
            onClose={closePopupBundle}
        >
            {chatName && (
                <DialogContent sx={dialogContentStyles}>
                    <SubscriptionModalImage src={image} />

                    <BackButton
                        onClick={() => {
                            dispatch(setChatNameSubscriptionModal(''))
                        }}
                    >
                        <img src={back} />
                    </BackButton>

                    <AllGirlsImage src={allGirls} alt="" />

                    <SubscriptionModalInfo>
                        <div className="character-name">Get all PHOTOS</div>
                        <div className="character-name">OF ALL GIRLS</div>
                        <div className="special-colored">FOR FREE!</div>

                        <ButtonG
                            className="continue-button"
                            name={'Get for free'}
                            onClick={() => {
                                purchaseXsollaSubscription(
                                    allPhotosSubscription.external_id,
                                    allPhotosSubscription.charge.prices[0].amount,
                                )
                            }}
                        />

                        <div className="small-info__days-count">Get {allPhotosSubscription.trial.value} days.</div>
                        <div className="small-info__billing-info">
                            Then {allPhotosSubscription.charge.prices[0].amount}$ per month.
                        </div>
                        <div className="small-info__billing-info">No commitment. Cancel anytime</div>

                        <div className="footer-links">
                            <a href="http://wideview.games/terms-of-service-1.html">Terms of use</a>
                            <a href="http://wideview.games/subs-info.html">Subscription Terms</a>
                            <a href="http://wideview.games/privacy-policy.html">Privacy Policy</a>
                        </div>
                    </SubscriptionModalInfo>
                </DialogContent>
            )}
        </Dialog>
    )
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction={'right'} ref={ref} {...props} />
})
