import styled from 'styled-components/macro'

interface BackgroundMenuI {
    zIndex?: number
    height?: number | null
}
interface BodyI {
    zIndex?: number
}
export const BackgroundMenu = styled.div<BackgroundMenuI>`
    width: 100%;
    background: rgb(255, 246, 223);
    height: 65pt;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    z-index: ${(props) => props.zIndex || 1};
    border-bottom: 5px solid rgb(255, 184, 0);
    box-shadow: 0pt 4.7pt 7pt 1pt rgba(46, 0, 94, 0.24);
`

export const Body = styled.div<BodyI>`
    z-index: ${(props) => props.zIndex || 10};
    display: flex;
    flex: 1;
    height: calc(100% - 65pt);
`
export const BlockMenu = styled.div<BackgroundMenuI>`
    height: 65pt;
    position: relative;
    width: 100%;
`
