import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store/store'
import {
    authAnonimuse,
    delUser,
    eventMail,
    getCurrentUser as apiGetMe,
    refreshToken,
    tokenXsolla,
} from '../../api/authApi'
import Cookies from 'js-cookie'
import {
    getItemsSubscriptions,
    getItemsXsolla,
    getOrderPay,
    postItemsXsolla,
    postItemsXsollaSub,
} from '../../api/swipeApi'

export interface PageState {
    access_token: string
    refresh_token: string
    expires_in: number
    token_type: string
    name: string | null
    idToken: string
    userUid: string | null
    userInfo: any
    xsollaVar: any
    firstGift: boolean
    tokenXsolla: string
    idXsollaDate: any
    tokenId: any
    bundleOpen: boolean
    oldCoin: number
    tuturClear: boolean
    failRequest: any
    orderStatus: any
    choes: boolean
    addCoin: boolean
    openPart: boolean
    idOrder: string
    isRegStatus: boolean
    amount: number
    registrationFirstImage: boolean
    subscriptions: any
    autoOne: boolean
    regStatus: string
    firstRegistrationFlag: boolean
    buyingSubscriptionId: null | string
}

const initialState: PageState = {
    buyingSubscriptionId: null,
    access_token: 'none',
    regStatus: '',
    refresh_token: 'none',
    expires_in: 0,
    token_type: 'bearer',
    name: null,
    idToken: '',
    tokenXsolla: '',
    userInfo: null,
    xsollaVar: null,
    choes: true,
    idXsollaDate: null,
    userUid: null,
    firstGift: false,
    tuturClear: true,
    tokenId: null,
    oldCoin: 0,
    registrationFirstImage: true,
    failRequest: null,
    bundleOpen: false,
    openPart: false,
    addCoin: false,
    firstRegistrationFlag: true,
    orderStatus: null,
    isRegStatus: false,
    subscriptions: null,
    autoOne: false,
    idOrder: '',
    amount: 0,
}

export const authAsync = createAsyncThunk('auth/fetchAuth', async () => {
    const response = await authAnonimuse()

    const result = {
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
        expires_in: response.data.expires_in,
        token_type: response.data.token_type,
    }

    return result
})

export const refreshTokenAsync = createAsyncThunk('auth/refreshToken', async (data: any) => {
    const response = await refreshToken(data.token)
    const result = {
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
        expires_in: response.data.expires_in,
        token_type: response.data.token_type,
        failRequest: data.data,
    }
    return result
})
function toTimestamp(strDate: any) {
    let datum = Date.parse(strDate)
    return datum / 1000
}

const createMetric = (data: any) => {
    const metric = { additionalInfo: {}, ...data, timestamp: toTimestamp(new Date().toUTCString()) }

    const cookieNamesEntries = [
        ['fbc', '_fbc'],
        ['fbp', '_fbp'],
        ['mr1lad', 'mr1lad'],
        ['mr1text', 'mr1text'],
    ]

    cookieNamesEntries.forEach(([additionalInfoKey, cookieName]) => {
        const cookieValue = Cookies.get(cookieName)
        if (cookieValue) {
            metric.additionalInfo[additionalInfoKey] = cookieValue
        }
    })

    return metric
}

export const collectEvent = createAsyncThunk('auth/postMetrikCollect', async (date: any) => {
    const result = await eventMail(
        createMetric({
            userId: date.id,
            ip: date.ip,
            event: 'custom_event_repeatable',
            idEvent: 2,
        }),
    )
    return result.data
})

export const confirmEmailEvent = createAsyncThunk('auth/postMetrikConfirmEmail', async (date: any) => {
    const result = await eventMail(
        createMetric({
            userId: date.id,
            ip: date.ip,
            event: 'custom_event',
            idEvent: 1,
            additionalInfo: {
                email: date.email,
            },
        }),
    )
    return result.data
})

export const registrationEvent = createAsyncThunk('auth/postMetrikRegistration', async (date: any) => {
    const result = await eventMail(
        createMetric({
            userId: date.id,
            ip: date.ip,
            event: 'registration',
        }),
    )
    return result.data
})
export const payEvent = createAsyncThunk('auth/postMetrikPay', async (date: any) => {
    const result = await eventMail(
        createMetric({
            userId: date.userInfo.id,
            ip: date.userInfo.ip,
            event: 'payment',
            amount: date.amount,
            id: date.id,
            currency: 'USD',
        }),
    )
    return result.data
})
export const openChatEvent = createAsyncThunk('auth/postMetrikOpenChat', async (date: any) => {
    const result = await eventMail(
        createMetric({
            userId: date.id,
            ip: date.ip,
            event: 'custom_event_repeatable',
            idEvent: 1,
        }),
    )
    return result.data
})

export const getItemsXsollaR = createAsyncThunk('auth/getItemsXsolla', async () => {
    const result = await getItemsXsolla()
    return result.data
})

export const getItemsSubscriptionsR = createAsyncThunk('auth/getItemsSubscriptions', async () => {
    const result = await getItemsSubscriptions()
    return result.data
})

export const getOrderPayR = createAsyncThunk('auth/getOrderPay', async (order_id: string) => {
    const result = await getOrderPay(order_id)
    return result.data
})

export const postItemsXsollaR = createAsyncThunk('auth/postItemsXsollaR', async (id: string) => {
    const result = await postItemsXsolla(id)
    return result.data
})

export const postItemsXsollaSubR = createAsyncThunk('auth/postItemsXsollaSubR', async (id: string) => {
    const result = await postItemsXsollaSub(id)
    return result.data
})

export const tokenXsollaPost = createAsyncThunk('auth/tokenXsollaPost', async (data: any) => {
    const response = await tokenXsolla(data.token)
    const result = {
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
        expires_in: response.data.expires_in,
        token_type: response.data.token_type,
        tokenXsolla: data.token,
    }
    return result
})
export const delUserR = createAsyncThunk('auth/delUserR', async () => {
    const response = await delUser()
    return response.data
})
export const getCurrentUser = createAsyncThunk('auth/getCurrentUser', async () => {
    const response = await apiGetMe()
    return response.data
})

export const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setXsollaClear: (state) => {
            state.idXsollaDate = null
        },
        setClearAll: (state) => {
            state.access_token = 'none'
            state.refresh_token = 'none'
            state.expires_in = 0
            state.token_type = 'bearer'
            state.name = null
            state.idToken = ''
            state.tokenXsolla = ''
            state.userInfo = null
            state.xsollaVar = null
            state.idXsollaDate = null
            state.userUid = ''
            state.firstGift = false
            state.tokenId = null
            state.oldCoin = 0
            state.failRequest = null
            state.addCoin = false
            state.orderStatus = null
            state.subscriptions = null
            state.autoOne = false
        },
        setFirstGift: (state, action) => {
            state.firstGift = action.payload
        },
        setOldCoin: (state, action) => {
            state.oldCoin = action.payload
        },
        setFirstRegistrationFlag: (state, action) => {
            state.firstRegistrationFlag = action.payload
        },
        setRegistrationFirstImage: (state, action) => {
            state.registrationFirstImage = action.payload
        },
        setTuturClear: (state, action) => {
            state.tuturClear = action.payload
        },
        setRegStatus: (state, action) => {
            state.regStatus = action.payload
        },
        setTriggerAddingCoin: (state, action) => {
            state.addCoin = action.payload
        },
        isRegStatusR: (state, action) => {
            state.isRegStatus = action.payload
        },
        setAutoOne: (state, action) => {
            state.autoOne = action.payload
        },
        setChoes: (state, action) => {
            state.choes = action.payload
        },
        setBundleOpen: (state, action) => {
            state.bundleOpen = action.payload
        },
        setOpenPart: (state, action) => {
            state.openPart = action.payload
        },
        setOrder: (state, action) => {
            state.idOrder = action.payload.idOrder
            state.amount = action.payload.amount
        },

        setBuyingSubscriptionId: (state, action) => {
            state.buyingSubscriptionId = action.payload
        },
    },
    extraReducers(builder) {
        builder.addCase(authAsync.fulfilled, (state, action) => {
            state.access_token = action.payload.access_token
            state.refresh_token = action.payload.refresh_token
            state.expires_in = action.payload.expires_in
            state.token_type = action.payload.token_type
        })
        builder.addCase(refreshTokenAsync.fulfilled, (state, action) => {
            state.access_token = action.payload.access_token
            state.refresh_token = action.payload.refresh_token
            state.expires_in = action.payload.expires_in
            state.token_type = action.payload.token_type
            state.failRequest = action.payload.failRequest
        })
        builder.addCase(tokenXsollaPost.fulfilled, (state, action) => {
            state.tokenXsolla = action.payload.tokenXsolla
            state.access_token = action.payload.access_token
            state.refresh_token = action.payload.refresh_token
            state.expires_in = action.payload.expires_in
            state.token_type = action.payload.token_type
        })
        builder.addCase(getCurrentUser.fulfilled, (state, action) => {
            state.name = action.payload.name
            state.userUid = action.payload.id
            state.userInfo = action.payload
            state.orderStatus = null
        })
        builder.addCase(getItemsXsollaR.fulfilled, (state, action) => {
            state.xsollaVar = action.payload
        })
        builder.addCase(postItemsXsollaR.fulfilled, (state, action) => {
            state.idXsollaDate = action.payload
        })
        builder.addCase(postItemsXsollaSubR.fulfilled, (state, action) => {
            state.idXsollaDate = action.payload
        })
        builder.addCase(getOrderPayR.fulfilled, (state, action) => {
            state.orderStatus = action.payload
        })
        builder.addCase(getItemsSubscriptionsR.fulfilled, (state, action) => {
            state.subscriptions = action.payload
        })
    },
})

export const {
    setXsollaClear,
    setFirstGift,
    setOrder,
    setRegStatus,
    setFirstRegistrationFlag,
    setChoes,
    setOpenPart,
    setRegistrationFirstImage,
    setTuturClear,
    isRegStatusR,
    setOldCoin,
    setBundleOpen,
    setTriggerAddingCoin,
    setAutoOne,
    setClearAll,
    setBuyingSubscriptionId,
} = auth.actions

export const selectAccess_token = (state: RootState) => state.auth.access_token
export const selectUserInfo = (state: RootState) => state.auth.userInfo
export const selectTriggerAddingCoin = (state: RootState) => state.auth.addCoin
export const selectAutoOne = (state: RootState) => state.auth.autoOne
export const selectRegistrationFirstImage = (state: RootState) => state.auth.registrationFirstImage
export const selectTuturClear = (state: RootState) => state.auth.tuturClear
export const selectSubscriptions = (state: RootState) => state.auth.subscriptions
export const selectOldCoin = (state: RootState) => state.auth.oldCoin
export const selectOrderStatus = (state: RootState) => state.auth.orderStatus
export const selectUserUid = (state: RootState) => state.auth.userUid
export const selectIdOrder = (state: RootState) => state.auth.idOrder
export const selectAmount = (state: RootState) => state.auth.amount
export const selectOpenPart = (state: RootState) => state.auth.openPart
export const selectChoes = (state: RootState) => state.auth.choes
export const selectFirstRegistrationFlag = (state: RootState) => state.auth.firstRegistrationFlag
export const selectRegStatus = (state: RootState) => state.auth.regStatus
export const selectIsRegStatusR = (state: RootState) => state.auth.isRegStatus
export const selectFirstGift = (state: RootState) => state.auth.firstGift
export const selectBundleOpen = (state: RootState) => state.auth.bundleOpen
export const selectXsollaVariant = (state: RootState) => state.auth.xsollaVar
export const selectXsollaDate = (state: RootState) => state.auth.idXsollaDate
export const selectName = (state: RootState) => state.auth.name
export const selectBuyingSubscriptionId = (state: RootState) => state.auth.buyingSubscriptionId
export default auth.reducer
