import { BlockButtonIcom, ButtonPhotoChat, ButtonPhotoChatIcomIn } from './style'
import photoButton from './choiseEmojiButton@3x.png'
import { Popper } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { ChatLeftIconLikeSelect } from '../../page/chatItem/style'
import { iconForChat } from '../../helper/textEdit'
import { postChatResourcesId } from '../../store/chat/chat'
import { useAppDispatch } from '../../hooks/hooks'

export const LikeButton = (props: any) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const [open, setOpen] = useState(false)
    const button = useRef(null)
    const flag = useRef(0)
    const dispatch = useAppDispatch()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
        setOpen((previousOpen) => !previousOpen)
    }
    const handleClickFirst = (current: any) => {
        setAnchorEl(current)
        setOpen(true)
    }
    const { id, resources } = props.data
    const resourcesData = props.resources
    const setResources = props.setResources
    const chatId = props.chatId
    const res = Object.keys(resources)
    const LikeSmile = async (like: any) => {
        let idMes = id
        let nextRes = JSON.parse(JSON.stringify(resourcesData))
        if (nextRes === null) {
            nextRes = {}
        }
        nextRes[id] = { SmileVariant: like }
        setResources(nextRes)
        await dispatch(postChatResourcesId({ id: chatId, message_id: idMes, idResources: like }))
    }
    useEffect(() => {
        if (flag.current === 0) {
            flag.current = 1
            handleClickFirst(button.current)
        }
    }, [])
    return (
        <>
            <ButtonPhotoChat ref={button} id={id} onClick={handleClick}>
                <img src={photoButton} />
            </ButtonPhotoChat>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="top"
                disablePortal={true}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: false,
                        options: {
                            altBoundary: false,
                            rootBoundary: 'document',
                            padding: 8,
                        },
                    },
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        options: {
                            altAxis: false,
                            altBoundary: false,
                            tether: false,
                            rootBoundary: 'document',
                            padding: 8,
                        },
                    },
                ]}
            >
                <BlockButtonIcom>
                    {res.map((val) => {
                        return (
                            <ButtonPhotoChatIcomIn onClick={() => LikeSmile(val)} key={val}>
                                <ChatLeftIconLikeSelect src={iconForChat[val]} />
                            </ButtonPhotoChatIcomIn>
                        )
                    })}
                </BlockButtonIcom>
            </Popper>
        </>
    )
}
