import { ImageMenu, ItemMenu, ItemMenuActive, Menu, BackArrow } from './style'
import store from './image/soButton@3x.png'
import profile from './image/user@3x.png'
import profileActive from './image/userActive@3x.png'
import slider from './image/characters@3x.png'
import sliderActive from './image/charactersActive@3x.png'
import chats from './image/chats@3x.png'
import chatsActive from './image/chatsActive@3x.png'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import {
    isBigInfo,
    isFadeSub,
    selectIsBigInfo,
    selectIsFadeSub,
    selectOldPage,
    selectPage,
    selectSubPage,
    setPage,
} from '../../store/page/pageSlice'
import { Fade } from '@mui/material'
import back from '../../page/store/image/back@3x.png'
import { changeSwipe } from '../../store/swipe/swipeSlice'
import { setShop } from '../../store/storeShop/shop'

import { setImages } from '../../store/chat/chat'

const menu = [
    {
        name: 'store',
        subFirst: 'coin',
        dontShow: { page: 'profile', subPage: 'setting' },
        imageName: store,
        imageNameActive: store,
    },
    {
        name: 'profile',
        subFirst: 'general',
        imageName: profile,
        dontShow: { page: 'profile', subPage: 'setting' },
        imageNameActive: profileActive,
    },
    {
        name: 'slider',
        subFirst: 'general',
        imageName: slider,
        imageNameActive: sliderActive,
    },
    {
        name: 'chat',
        subFirst: 'general',
        imageName: chats,
        imageNameActive: chatsActive,
    },
]

export const DashboardMenu = () => {
    const page = useAppSelector(selectPage)
    const subPage = useAppSelector(selectSubPage)
    const fadeMount = useAppSelector(selectIsFadeSub)
    const bigInfoData = useAppSelector(selectIsBigInfo)
    const oldPage = useAppSelector(selectOldPage)
    const dispatch = useAppDispatch()

    const setMenu = (name: string, subPageP?: string) => {
        if (name !== 'store') {
            if (page !== name) {
                dispatch(changeSwipe([]))
                if (subPage === 'setting') {
                    dispatch(
                        setPage({
                            name: name,
                            subPage: subPageP,
                            isFadeSub: false,
                            isFadePage: false,
                            isFadeBg: false,
                            oldPage: page,
                        }),
                    )
                } else {
                    dispatch(
                        setPage({
                            name: name,
                            subPage: subPageP,
                            isFadeSub: true,
                            isFadePage: false,
                            isFadeBg: true,
                            oldPage: page,
                        }),
                    )
                }
            } else {
                dispatch(
                    setPage({
                        name: name,
                        subPage: subPageP,
                        isFadeSub: true,
                        isFadePage: true,
                        isFadeBg: true,
                        oldPage: page,
                    }),
                )
            }
        } else {
            dispatch(setShop(true))
        }
    }
    const BackProfile = () => {
        if (bigInfoData && page !== 'chatItem' && page !== 'gallery') {
            dispatch(isBigInfo(false))
        } else if (page === 'chatItem') {
            dispatch(isBigInfo(false))
            dispatch(
                setPage({
                    name: oldPage,
                    subPage: 'general',
                    isFadeSub: true,
                    isFadePage: false,
                    isFadeBg: true,
                    oldPage: page,
                }),
            )
        } else if (page === 'gallery' && oldPage === 'chatItem') {
            dispatch(setImages(null))
            dispatch(
                setPage({
                    name: oldPage,
                    subPage: 'general',
                    isFadeSub: false,
                    isFadePage: false,
                    isFadeBg: true,
                    oldPage: page,
                }),
            )
        } else if (page === 'gallery' && oldPage === 'chat') {
            dispatch(isBigInfo(false))
            dispatch(setImages(null))
            dispatch(
                setPage({
                    name: oldPage,
                    subPage: 'general',
                    isFadeSub: true,
                    isFadePage: false,
                    isFadeBg: true,
                    oldPage: page,
                }),
            )
        } else {
            dispatch(
                setPage({ name: 'profile', subPage: 'general', isFadeSub: false, isFadePage: true, oldPage: page }),
            )
        }
    }
    return (
        <Menu zIndex={page === 'chatItem' ? 20 : 10}>
            <Fade
                in={(subPage === 'setting' && page !== 'chatItem' && fadeMount) || (bigInfoData && page !== 'chatItem')}
            >
                <BackArrow onClick={BackProfile}>
                    <img src={back} />
                </BackArrow>
            </Fade>
            {menu.map((el) => {
                if (page === el.name) {
                    if (el.dontShow) {
                        return (
                            <Fade
                                in={subPage !== el.dontShow.subPage && fadeMount && bigInfoData === false}
                                onExited={() => {
                                    dispatch(isFadeSub(true))
                                }}
                                key={el.name}
                            >
                                <ItemMenuActive>
                                    <ImageMenu src={el.imageNameActive} onClick={() => setMenu(el.name, el.subFirst)} />
                                </ItemMenuActive>
                            </Fade>
                        )
                    } else {
                        return (
                            <Fade in={bigInfoData === false} key={el.name}>
                                <ItemMenuActive>
                                    <ImageMenu src={el.imageNameActive} onClick={() => setMenu(el.name, el.subFirst)} />
                                </ItemMenuActive>
                            </Fade>
                        )
                    }
                } else {
                    if (el.dontShow) {
                        return (
                            <Fade
                                in={subPage !== el.dontShow.subPage && fadeMount && bigInfoData === false}
                                onExited={() => {
                                    dispatch(isFadeSub(true))
                                }}
                                key={el.name}
                            >
                                <ItemMenu>
                                    <ImageMenu src={el.imageName} onClick={() => setMenu(el.name, el.subFirst)} />
                                </ItemMenu>
                            </Fade>
                        )
                    } else {
                        return (
                            <Fade in={bigInfoData === false} key={el.name}>
                                <ItemMenu key={el.name}>
                                    <ImageMenu src={el.imageName} onClick={() => setMenu(el.name, el.subFirst)} />
                                </ItemMenu>
                            </Fade>
                        )
                    }
                }
            })}
        </Menu>
    )
}
