import styled, { keyframes } from 'styled-components'
import hand from '../page/swiper/image/tutorialHand@3x.png'

interface ICanvas {
    image?: string
    opacity?: number
}
export const BlockCanvas = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`
export const ImageBlockCanvas = styled.img`
    width: 100%;
    max-width: 600px;
    position: absolute;
`
export const move = keyframes`
  0% {
    offset-distance: 100%;
    opacity: 0;
  }
  10% {
    offset-distance: 90%;
    opacity: 1;
  }
  90%{
    opacity: 1;
  }
  100% {
    opacity: 0;
    offset-distance: 0;
  }
`
export const ElementTutur = styled.div`
    offset-path: path(
        'M472.492 116.362c-6.646 23.175-40.358 20.157-59.28 27.464-18.36 7.095-30.33 23.788-38.4 40.984-9.81 20.917-12.303 45.234-26.718 63.91-13.96 18.105-37.532 18.906-56.484 28.003-18.88 9.07-28.845 26.77-38.85 44.34-12.958 22.767-30.44 28.89-53.3 39.913-39.888 19.24-51.8 49.524-45.342 92.08'
    );
    offset-rotate: 0deg;
    animation: ${move} 5s infinite;
    background: url(${hand});
    left: 0%;
    top: 5%;
    height: 15.2%;
    width: 11%;
    position: absolute;
    background-size: cover;
    background-repeat: no-repeat;
    pointer-events: none;
    z-index: 100;
    @media (max-width: 768px) {
        top: -19%;
        left: -36%;
    }
`
export const ElementTuturBox = styled.div`
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`
export const Canvas = styled.canvas<ICanvas>`
    width: 100%;
    max-width: ${(props) => (props.width ? props.width + 'px' : '600px')};
    background: ${(props) => (props.image !== null ? `url(${props.image})` : null)};
    background-size: cover;
    background-size: 100% 100%;
    max-height: ${(props) => (props.height ? props.height + 'px' : '600px')};
    height: 100%;
    z-index: 10;
    opacity: ${(props) => (props.opacity === 1 ? 1 : 0)};
    transition: all 1s;
`
