import styled from 'styled-components/macro'
import '@fontsource/roboto/900.css'
import '@fontsource/roboto/500.css'

export const Container2 = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
export const Container3 = styled.div`
    position: relative;
    width: 74%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    @media (min-width: 768px) {
        width: 50%;
    }
`
export const Container4 = styled.div`
    flex: 1;
`

export const Image4 = styled.img`
    display: flex;
    max-width: 50%;
    justify-content: center;
    @media (max-width: 768px) {
        max-width: 39%;
    }
`
export const Image41 = styled.img`
    display: flex;
    max-width: 50%;
    justify-content: center;
    margin-top: 9%;
    @media (max-width: 768px) {
        max-width: 35%;
    }
`
export const Image5 = styled.img`
    display: flex;
    max-width: 50%;
    justify-content: center;
    margin: auto;
    @media (max-width: 768px) {
        max-width: 40%;
    }
`
export const NameMoney = styled.span`
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14pt;
    margin-top: 3pt;
    color: rgb(0, 0, 117);
`
export const Money = styled.span`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 20pt;
    position: absolute;
    margin-bottom: -65px;
    color: rgb(0, 0, 117);
    @media (max-width: 768px) {
        font-size: 17pt;
        margin-bottom: -52px;
    }
`

export const UserName = styled.span`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: 33pt;
    color: rgb(0, 0, 117);
    @media (max-width: 768px) {
        font-size: 22pt;
    }
`
export const BlockFlexRow = styled.div`
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    div:last-child {
        border-right: 2px solid rgb(216, 208, 225);
    }
`
export const BlockFlexColumn = styled.div`
    display: flex;
    flex-direction: column;
    border-left: 2px solid rgb(216, 208, 225);
    padding: 0px 30px;
    @media (max-width: 768px) {
        padding: 0px 15px;
    }
`
export const BlockFlexItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`
export const ItemButton = styled.button`
    width: 56pt;
    height: 56pt;
    border: none;
    outline: none;
    margin: auto;
    margin-top: 50px;
    width: 100%;
    padding: 0;
    :hover {
        transform: scale(1.1);
    }
    cursor: pointer;
    background: none;
    @media (max-width: 768px) {
        margin-top: 35px;
    }
`
