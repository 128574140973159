import styled from 'styled-components/macro'
import '@fontsource/roboto/900.css'
import '@fontsource/roboto/300.css'

export const GeneralContainer = styled.div`
    display: flex;
    z-index: 100;
    position: absolute;
    top: 0;
    flex-direction: column;
    max-width: 535px;
    margin: auto;
    width: 100%;
    height: 100%;
`

export const TopButtons = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 5% 0px;
`
export const BlockScroll = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
`
export const BlockPay = styled.div`
    display: flex;
    flex-direction: column;
`
export const BlockStore = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    width: 80%;
    margin: auto;
    margin-bottom: 26%;

    .vip-offer-all {
        display: flex;
        position: relative;
        align-items: center;
        flex-direction: column;

        .vip-offer-all__text {
            position: absolute;
            font-size: 22px;
            font-weight: 800;
            text-align: center;
            padding-top: 5px;

            div:nth-child(1) {
                -webkit-text-stroke: 1px #5c1890;
                color: #fff;
            }

            div:nth-child(2) {
                -webkit-text-stroke: 1px #5c1890;
                color: #ffff81;
            }
        }

        .vip-offer-all__button {
            position: absolute;
            width: 30%;
            bottom: 5%;
            right: 10%;
        }

        .vip-offer-all__image {
            top: 0;
            width: 100%;
        }
    }
`
export const BlockBandl = styled.div`
    display: flex;
    position: relative;
`
export const BlockBandl3 = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
`
export const BlockBandl2 = styled.div`
    display: flex;
    position: relative;
    width: 80%;
    margin: auto;
`
export const BlockBandlInfo = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    cursor: pointer;
`

export const BlockBandlInfoText2 = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    color: #ff5166;
    font-size: 20px;
    text-align: end;
    position: absolute;
    right: 7%;
    top: 9%;
    span {
        font-size: 20px;
    }
    @media (max-width: 768px) {
        font-size: 14px;
        span {
            font-size: 15px;
        }
    }
`
export const BlockBandlInfoText = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    color: white;
    font-size: 20px;
    text-shadow:
        -0 -2px 0 #7a19bf,
        0 -2px 0 #7a19bf,
        -0 2px 0 #7a19bf,
        0 2px 0 #7a19bf,
        -2px -0 0 #7a19bf,
        2px -0 0 #7a19bf,
        -2px 0 0 #7a19bf,
        2px 0 0 #7a19bf,
        -1px -2px 0 #7a19bf,
        1px -2px 0 #7a19bf,
        -1px 2px 0 #7a19bf,
        1px 2px 0 #7a19bf,
        -2px -1px 0 #7a19bf,
        2px -1px 0 #7a19bf,
        -2px 1px 0 #7a19bf,
        2px 1px 0 #7a19bf,
        -2px -2px 0 #7a19bf,
        2px -2px 0 #7a19bf,
        -2px 2px 0 #7a19bf,
        2px 2px 0 #7a19bf,
        -2px -2px 0 #7a19bf,
        2px -2px 0 #7a19bf,
        -2px 2px 0 #7a19bf,
        2px 2px 0 #7a19bf;
    padding: 10px 10px 10px 20px;
    span {
        font-size: 15px;
    }
    @media (max-width: 768px) {
        font-size: 14px;
        span {
            font-size: 11px;
        }
    }
`

export const BlockBandlInfoVitMoney = styled.div`
    background: #f900ff;
    position: absolute;
    width: 42%;
    right: 6%;
    top: 38%;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 23px;
    border-radius: 20px;

    @media (max-width: 768px) {
        font-size: 18px;
        right: 6%;
        top: 38%;
    }
`
export const BlockBandlInfoVitMoney2 = styled.div`
    background: #f900ff;
    position: absolute;
    width: 42%;
    right: 6%;
    top: 38%;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 23px;
    border-radius: 20px;

    @media (max-width: 768px) {
        font-size: 18px;
        right: 6%;
        top: 38%;
    }
`
export const BlockBandlInfoVitMoney3 = styled.div`
    background: #f79d2c;
    position: absolute;
    width: 42%;
    right: 8%;
    top: 29%;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 23px;
    border-radius: 20px;

    @media (max-width: 768px) {
        font-size: 15px;
        right: 8%;
        top: 27%;
    }
`
export const BlockBandlInfoVitMoneyCoin = styled.span`
    padding: 3px 5% 3px 19%;
`
export const BlockBandlInfoVitMoneyGift = styled.span`
    padding: 3px 0% 3px 21%;
`
export const BlockBandlInfoPrice = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 18px;
    position: absolute;
    right: 19%;
    top: 57%;
    color: #ffffffa8;
    text-shadow:
        -0 -2px 0 #d02832,
        0 -2px 0 #d02832,
        -0 2px 0 #d02832,
        0 2px 0 #d02832,
        -2px -0 0 #d02832,
        2px -0 0 #d02832,
        -2px 0 0 #d02832,
        2px 0 0 #d02832,
        -1px -2px 0 #d02832,
        1px -2px 0 #d02832,
        -1px 2px 0 #d02832,
        1px 2px 0 #d02832,
        -2px -1px 0 #d02832,
        2px -1px 0 #d02832,
        -2px 1px 0 #d02832,
        2px 1px 0 #d02832,
        -2px -2px 0 #d02832,
        2px -2px 0 #d02832,
        -2px 2px 0 #d02832,
        2px 2px 0 #d02832,
        -2px -2px 0 #d02832,
        2px -2px 0 #d02832,
        -2px 2px 0 #d02832,
        2px 2px 0 #d02832;
    @media (max-width: 768px) {
        font-size: 13px;
    }
`
export const BlockBandlInfoPrice2 = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 18px;
    position: absolute;
    right: 21%;
    top: 50.5%;
    color: #ffffffa8;
    text-shadow:
        -0 -2px 0 #d02832,
        0 -2px 0 #d02832,
        -0 2px 0 #d02832,
        0 2px 0 #d02832,
        -2px -0 0 #d02832,
        2px -0 0 #d02832,
        -2px 0 0 #d02832,
        2px 0 0 #d02832,
        -1px -2px 0 #d02832,
        1px -2px 0 #d02832,
        -1px 2px 0 #d02832,
        1px 2px 0 #d02832,
        -2px -1px 0 #d02832,
        2px -1px 0 #d02832,
        -2px 1px 0 #d02832,
        2px 1px 0 #d02832,
        -2px -2px 0 #d02832,
        2px -2px 0 #d02832,
        -2px 2px 0 #d02832,
        2px 2px 0 #d02832,
        -2px -2px 0 #d02832,
        2px -2px 0 #d02832,
        -2px 2px 0 #d02832,
        2px 2px 0 #d02832;
    @media (max-width: 768px) {
        font-size: 13px;
    }
`
export const BlockBandlInfoPay = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 24px;
    position: absolute;
    right: 16%;
    top: 71%;
    color: #ffffff;
    text-shadow:
        0px 1px 0 rgb(35, 136, 0),
        0px -1px 0 rgb(35, 136, 0),
        1px 0px 0 rgb(35, 136, 0),
        -1px 0px 0 rgb(35, 136, 0),
        1px 1px 0 rgb(35, 136, 0),
        1px -1px 0 rgb(35, 136, 0),
        -1px 1px 0 rgb(35, 136, 0),
        -1px -1px 0 rgb(35, 136, 0);
    @media (max-width: 768px) {
        font-size: 18px;
    }
`
export const BlockBandlInfoPay2 = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 24px;
    position: absolute;
    right: 17.5%;
    top: 66.2%;
    color: #ffffff;
    text-shadow:
        0px 1px 0 rgb(35, 136, 0),
        0px -1px 0 rgb(35, 136, 0),
        1px 0px 0 rgb(35, 136, 0),
        -1px 0px 0 rgb(35, 136, 0),
        1px 1px 0 rgb(35, 136, 0),
        1px -1px 0 rgb(35, 136, 0),
        -1px 1px 0 rgb(35, 136, 0),
        -1px -1px 0 rgb(35, 136, 0);
    @media (max-width: 768px) {
        font-size: 18px;
    }
`
export const BlockBandlImage = styled.img`
    max-width: 100%;
    max-width: 102.5%;
    margin-left: -1.5%;
`

export const Space = styled.span`
    display: flex;
    flex: 1;
`
export const ButtonMoneyCoin = styled.button`
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    position: relative;
    background: rgb(0 16 89 / 51%);
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 23px;
    color: white;
    width: 150px;
    border: 2px solid rgb(255 181 0);
    border-radius: 24px;
    margin-right: 15px;
    padding: 1px 0px;
    padding-left: 10px;
    @media (max-width: 768px) {
        font-size: 18px;
        width: 110px;
    }
`
export const ButtonMoneyAdd = styled.img`
    position: absolute;
    width: 21px;
    left: 7px;
    bottom: -9px;
`
export const ButtonMoneyGift = styled.button`
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    position: relative;
    background: rgb(0 16 89 / 51%);
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 23px;
    color: white;
    width: 150px;
    padding: 1px 0px;
    border: 2px solid rgb(255 181 0);
    margin-right: 15px;
    border-radius: 24px;
    padding-left: 10px;
    @media (max-width: 768px) {
        font-size: 18px;
        width: 110px;
    }
`

export const ButtonVipOffers = styled.button`
    align-self: flex-end;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    position: relative;
    width: 320px;
    margin-right: 15px;
    background: none;
    margin-top: 15px;
    @media (max-width: 768px) {
        max-width: 220px;
    }
`

export const ButtonVipOffersImage = styled.img`
    max-width: 320px;
    @media (max-width: 768px) {
        max-width: 220px;
    }
`

export const VipOffersContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    height: 100%;
    overflow-y: auto;
`

export const BlockStoreItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 47.3%;
    box-sizing: border-box;
    margin-bottom: 5%;
    position: relative;
    background: rgb(255 246 223);
    border-radius: 10px;
    padding: 3% 8%;
    border: 0.7px solid rgb(233 220 226);
    box-shadow:
        0px 4.7px 4.6px 0.7px rgba(46 0 94, 0.24),
        inset 0px 1.3px 0.3px 0px rgba(98 19 154, 0.11),
        inset -0.1px 3.3px 0.3px 0px rgba(98 19 154, 0.11),
        inset 0px 1.3px 0.3px 0px rgb(255 219 137);
`
export const BlockPriceStoreCoin = styled.div`
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 22px;
    text-align: center;
    border-radius: 25px;
    color: rgb(190 89 22);
    background: rgb(250 231 197);
    @media (max-width: 768px) {
        font-size: 18px;
    }
`
export const BlockPriceStoreGift = styled.div`
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    font-size: 22px;
    text-align: center;
    border-radius: 25px;
    color: rgb(164 45 22);
    background: rgb(250 231 197);
    @media (max-width: 768px) {
        font-size: 18px;
    }
`
export const BlockPriceStoreImg = styled.img`
    left: -10%;
    position: absolute;
    max-width: 30px;
    bottom: 0px;
    @media (max-width: 768px) {
        max-width: 23px;
    }
`
export const BlockButtonStoreImgB = styled.img`
    left: -10%;
    position: absolute;
    max-width: 40px;
    bottom: -5px;
    @media (max-width: 768px) {
        max-width: 35px;
    }
`
export const BlockButtonStoreImgBBland = styled.img`
    position: absolute;
    max-width: 18%;
    bottom: -1px;
    margin-left: -2px;
    @media (max-width: 768px) {
        max-width: 18%;
    }
`
export const BlockStoreImg = styled.img`
    position: relative;
    margin: auto;
    max-width: 100%;
`
export const BlockProrgess = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`
export const BlockButtonPrice = styled.button`
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: 100%;
    border-radius: 0 !important;
    :hover {
        transform: scale(1.1);
    }
    img {
        pointer-events: none;
        position: relative;
        max-width: 100%;
    }
    span {
        position: absolute;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin-top: -11px;
        font-size: 22px;
        text-shadow:
            0px 1px 0 rgb(35, 136, 0),
            0px -1px 0 rgb(35, 136, 0),
            1px 0px 0 rgb(35, 136, 0),
            -1px 0px 0 rgb(35, 136, 0),
            1px 1px 0 rgb(35, 136, 0),
            1px -1px 0 rgb(35, 136, 0),
            -1px 1px 0 rgb(35, 136, 0),
            -1px -1px 0 rgb(35, 136, 0);
        @media (max-width: 768px) {
            font-size: 14px;
            margin-top: -7px;
        }
    }
`

export const Button = styled.button`
    width: 56pt;
    border: none;
    outline: none;
    padding: 0;
    :hover {
        transform: scale(1.1);
    }
    img {
        max-width: 34pt;
    }
    cursor: pointer;
    background: none;
`
