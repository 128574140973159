import {
    BlockButtonCollect2,
    BlockButtonCollect2Text1,
    BlockButtonCollect2Text2,
    BlockImage2,
    BlockImage3,
    ImageCoinBandle,
} from '../../page/profile/settings/style'
import {
    BlockBandl,
    BlockBandlImage,
    BlockBandlInfo,
    BlockBandlInfoPay,
    BlockBandlInfoPrice,
    BlockBandlInfoText,
    BlockBandlInfoVitMoney,
    BlockBandlInfoVitMoney2,
    BlockBandlInfoVitMoneyCoin,
    BlockBandlInfoVitMoneyGift,
    BlockButtonStoreImgBBland,
} from '../../page/store/style'
import unlimitedProfilesImage from './img/UnlimitedProfiles.png'
import unlimitedProfilesDialog from './img/UnlimProfArt.png'
import coinIcon from '../../page/store/image/smallCoinIcon@3x.png'
import giftIcon from '../../page/store/image/giftSmallIcon@3x.png'
import redBackground from '../../dashboards/menu/image/priceBae@3x.png'
import buttonBackgrount from '../../page/store/image/layer702@3x.png'
import { useXsollaPayments } from '../../hooks/useXsollaPayments'

export const UnlimitedProfiles = ({ isDialog = false }: { isDialog?: boolean }) => {
    const { getXsollaItem, purchaseXsollaItem } = useXsollaPayments()
    const unlimitedProfiles = getXsollaItem('unlimitedprofiles')

    return isDialog ? (
        <>
            <ImageCoinBandle src={unlimitedProfilesDialog} />
            <BlockBandlInfo>
                <BlockBandlInfoText>
                    SPECIAL OFFER + <span>UNLIMITED PROFILES FOR FREE!</span>
                </BlockBandlInfoText>
                <BlockBandlInfoVitMoney2>
                    <BlockButtonStoreImgBBland src={coinIcon} />
                    <BlockBandlInfoVitMoneyCoin>
                        {unlimitedProfiles.content[0].content[0].quantity}
                    </BlockBandlInfoVitMoneyCoin>
                    <BlockButtonStoreImgBBland src={giftIcon} />
                    <BlockBandlInfoVitMoneyGift>
                        {unlimitedProfiles.content[1].content[0].quantity}
                    </BlockBandlInfoVitMoneyGift>
                </BlockBandlInfoVitMoney2>
            </BlockBandlInfo>
            <BlockButtonCollect2
                onClick={() => {
                    purchaseXsollaItem(unlimitedProfiles.sku, unlimitedProfiles.price.amount)
                }}
            >
                <BlockImage3 src={redBackground} />
                <BlockImage2 src={buttonBackgrount} />
                <BlockButtonCollect2Text1>Buy now</BlockButtonCollect2Text1>
                <BlockButtonCollect2Text2>$ {unlimitedProfiles.price.amount}</BlockButtonCollect2Text2>
            </BlockButtonCollect2>
        </>
    ) : (
        <BlockBandl
            onClick={() => {
                purchaseXsollaItem(unlimitedProfiles.sku, unlimitedProfiles.price.amount)
            }}
        >
            <BlockBandlImage src={unlimitedProfilesImage} />
            <BlockBandlInfo>
                <BlockBandlInfoText>
                    SPECIAL OFFER + <span>UNLIMITED PROFILES FOR FREE!</span>
                </BlockBandlInfoText>
                <BlockBandlInfoVitMoney>
                    <BlockButtonStoreImgBBland src={coinIcon} />
                    <BlockBandlInfoVitMoneyCoin>
                        {unlimitedProfiles.content[0].content[0].quantity}
                    </BlockBandlInfoVitMoneyCoin>
                    <BlockButtonStoreImgBBland src={giftIcon} />
                    <BlockBandlInfoVitMoneyGift>
                        {unlimitedProfiles.content[1].content[0].quantity}
                    </BlockBandlInfoVitMoneyGift>
                </BlockBandlInfoVitMoney>
                <BlockBandlInfoPrice>{unlimitedProfiles.price.amount} $</BlockBandlInfoPrice>
                <BlockBandlInfoPay>Buy now</BlockBandlInfoPay>
            </BlockBandlInfo>
        </BlockBandl>
    )
}
