import { CircularProgress, Fade } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../hooks/hooks'
import { isNoCards, selectIsNoCards, selectPage } from '../../store/page/pageSlice'
import { isPaidStatus, isReloadCharacters } from '../../store/swipe/swipeSlice'

import { BlockPAy, BlockClose, BlockLine1, BlockLine2 } from '../../page/profile/settings/style'
import { useEffect, useState } from 'react'
import {
    getCurrentUser,
    getItemsSubscriptionsR,
    getItemsXsollaR,
    getOrderPayR,
    selectFirstRegistrationFlag,
    selectUserInfo,
    selectOrderStatus,
    selectUserUid,
    selectXsollaDate,
    setXsollaClear,
    selectBuyingSubscriptionId,
} from '../../store/authToken/auth'
import { BlockProrgess } from '../../page/store/style'
import { selectIsXsolaOpen, setOpenLoginInfo, setXsolaOpen } from '../../store/storeShop/shop'
import { useXsollaPayments } from '../../hooks/useXsollaPayments'

const styleHieght = {
    height: '100%',
    width: '100%',
    border: '0',
}

export const XsollaPayment = () => {
    const page = useAppSelector(selectPage)
    const xsollaDate = useAppSelector(selectXsollaDate)
    const payOpen = useAppSelector(selectIsXsolaOpen)
    const firstRegistrationFlag = useAppSelector(selectFirstRegistrationFlag)
    const noCards = useAppSelector(selectIsNoCards)
    const orderStatus = useAppSelector(selectOrderStatus)
    const dispatch = useAppDispatch()
    const userInfo = useAppSelector(selectUserInfo)
    const buyingSubscriptionId = useAppSelector(selectBuyingSubscriptionId)
    const [poolXsollaInterval, setPoolXsollaInterval] = useState<any>(null)
    const { finishXsollaPurchase } = useXsollaPayments()
    const userId = useAppSelector(selectUserUid)

    useEffect(() => {
        if (userId) {
            dispatch(getItemsXsollaR())
            dispatch(getItemsSubscriptionsR())
        }
    }, [userId])

    useEffect(() => {
        if (xsollaDate && payOpen) {
            if (xsollaDate.order_id) {
                setPoolXsollaInterval(
                    setInterval(() => {
                        dispatch(getOrderPayR(xsollaDate.order_id))
                    }, 2000),
                )
            } else if (buyingSubscriptionId) {
                setPoolXsollaInterval(
                    setInterval(async () => {
                        await dispatch(getCurrentUser())
                    }, 2000),
                )
            }
        }
    }, [xsollaDate, buyingSubscriptionId])

    useEffect(() => {
        if (!payOpen) {
            return
        }

        const isSubscriptionBought = buyingSubscriptionId && userInfo?.subscriptions[buyingSubscriptionId]
        const isItemBought = orderStatus?.status === 'paid' && xsollaDate

        if (isItemBought || isSubscriptionBought) {
            finishXsollaPurchase(orderStatus?.order_id || buyingSubscriptionId)
            clearInterval(poolXsollaInterval)
            if (firstRegistrationFlag) {
                dispatch(setOpenLoginInfo(true))
            }
            if (noCards && page === 'slider') {
                dispatch(isReloadCharacters(true))
                dispatch(isNoCards(false))
            }

            dispatch(isPaidStatus(false))
        }
    }, [orderStatus, userInfo])

    return (
        <Fade in={payOpen} mountOnEnter unmountOnExit>
            <BlockPAy>
                {xsollaDate !== null && (
                    <>
                        {window.location.origin === 'https://loverzgame.com' && (
                            <iframe
                                title="pay"
                                style={styleHieght}
                                src={'https://secure.xsolla.com/paystation4/?token=' + xsollaDate.token}
                            ></iframe>
                        )}
                        {window.location.origin !== 'https://loverzgame.com' && (
                            <iframe
                                title="pay"
                                style={styleHieght}
                                src={'https://sandbox-secure.xsolla.com/paystation4/?token=' + xsollaDate.token}
                            ></iframe>
                        )}
                        <BlockClose
                            onClick={() => {
                                dispatch(setXsolaOpen(false))
                                dispatch(setXsollaClear())
                                clearInterval(poolXsollaInterval)
                            }}
                        >
                            <BlockLine1></BlockLine1>
                            <BlockLine2></BlockLine2>
                        </BlockClose>
                    </>
                )}
                {xsollaDate == null && (
                    <BlockProrgess>
                        <CircularProgress />
                    </BlockProrgess>
                )}
            </BlockPAy>
        </Fade>
    )
}
