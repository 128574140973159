import styled from 'styled-components/macro'
interface BackgroundMenuI {
    zIndex?: number
    height?: number | null
}
export const GeneralContainer = styled.div`
    width: 100%;
    position: absolute;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    z-index: 100;
    left: 0;
    background: white;
    overflow-y: auto;
`
export const BackgroundMenu2 = styled.div<BackgroundMenuI>`
    width: 100%;
    background: rgb(255, 246, 223);
    height: 65pt;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    z-index: ${(props) => props.zIndex || 1};
    border-bottom: 5px solid rgb(255, 184, 0);
    box-shadow: 0pt 4.7pt 7pt 1pt rgba(46, 0, 94, 0.24);
    @media (max-width: 768px) {
        height: 50pt;
        font-size: 23px;
    }
`
export const TextInterest = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    z-index: 10;
    min-height: 65pt;
    color: rgb(0 0 117);
    display: flex;
    font-size: 23pt;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        height: 50pt;
        min-height: 43pt;
        font-size: 23px;
    }
`
export const BlockRes = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    z-index: 10;
    height: 30pt;
    color: rgb(0 0 117);
    display: flex;
    font-size: 23pt;
    align-items: center;
    margin-bottom: 1%;
    justify-content: center;
    @media (max-width: 768px) {
        font-size: 23px;
        margin-bottom: 0%;
        align-items: flex-start;
    }
`
export const BlockNoAnswer = styled.div`
    font-family: 'Roboto', sans-serif;
    font-weight: 900;
    z-index: 10;
    height: 30pt;
    margin-top: 1%;
    color: rgb(0 0 117);
    display: flex;
    font-size: 23pt;
    cursor: pointer;
    align-items: center;
    text-decoration: underline;
    justify-content: center;
    margin-bottom: 1%;
    @media (max-width: 768px) {
        font-size: 23px;
    }
`
export const BlockInterest = styled.ul`
    padding: 0;
    column-count: 2;
    column-gap: 1em;
    margin: 0;
    width: 95%;
    margin-top: 10px;
`
export const BlockInterestCenter = styled.div`
    max-width: 600px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
    @media (max-width: 768px) {
        margin-top: 15px;
    }
`
export const ButtonCustomChoes = styled.li`
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    display: flex;
    background: rgb(255 251 240);
    font-size: 16pt;
    cursor: pointer;
    margin-bottom: 10px;
    position: relative;
    padding: 10pt 13pt;
    align-items: center;
    color: rgb(0 0 117);
    border: 0.7pt solid rgb(233 220 226);
    border-radius: 20px;
    box-shadow:
        inset 0px -1.3pt 0.3pt 0pt rgba(43 170 209, 0.27),
        0pt 2.3pt 0.3pt 0pt rgba(199 93 41, 0.1);
    img {
        max-width: 11%;
        position: absolute;
        top: 18%;
    }
    span {
        margin-left: 17%;
    }
    @media (max-width: 768px) {
        font-size: 15px;
        padding: 8px 16px;
    }
`
export const ButtonCustomChoesSel = styled.li`
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    display: flex;
    background: rgb(130 226 255);
    font-size: 16pt;
    cursor: pointer;
    margin-bottom: 10px;
    padding: 10pt 13pt;
    position: relative;
    align-items: center;
    color: rgb(0 0 117);
    border: 0.7pt solid rgb(233 220 226);
    border-radius: 20px;
    box-shadow:
        inset 0px -1.3pt 0.3pt 0pt rgba(43 170 209, 0.27),
        0pt 2.3pt 0.3pt 0pt rgba(199 93 41, 0.1);
    img {
        max-width: 11%;
        position: absolute;
        top: 18%;
    }
    span {
        margin-left: 17%;
    }
    @media (max-width: 768px) {
        font-size: 15px;
        padding: 8px 16px;
    }
`
export const ContainerInfo = styled.div`
    max-width: 600px;
    position: absolute;
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`
export const BlockButtonContinue = styled.button`
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19%;
    max-width: 226px;
    border-radius: 0 !important;
    :hover {
        transform: scale(1.1);
    }
    img {
        pointer-events: none;
        position: relative;
        max-width: 100%;
    }
    span {
        position: absolute;
        color: white;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin-top: -11px;
        font-size: 23px;
        text-shadow:
            0px 1px 0 rgb(35, 136, 0),
            0px -1px 0 rgb(35, 136, 0),
            1px 0px 0 rgb(35, 136, 0),
            -1px 0px 0 rgb(35, 136, 0),
            1px 1px 0 rgb(35, 136, 0),
            1px -1px 0 rgb(35, 136, 0),
            -1px 1px 0 rgb(35, 136, 0),
            -1px -1px 0 rgb(35, 136, 0);
        @media (max-width: 768px) {
            font-size: 23px;
            margin-top: -7px;
        }
    }
    @media (max-width: 768px) {
        width: 44%;
    }
`
export const BlockButtonContinueGrey = styled.button`
    border: none;
    outline: none;
    padding: 0;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 19%;
    max-width: 226px;
    border-radius: 0 !important;
    :hover {
        transform: scale(1.1);
    }
    img {
        pointer-events: none;
        position: relative;
        max-width: 100%;
    }
    span {
        position: absolute;
        color: #adadad;
        font-family: 'Roboto', sans-serif;
        font-weight: 900;
        margin-top: -11px;
        font-size: 23px;
        text-shadow:
            0px 1px 0 rgb(255, 255, 255),
            0px -1px 0 rgb(255, 255, 255),
            1px 0px 0 rgb(255, 255, 255),
            -1px 0px 0 rgb(255, 255, 255),
            1px 1px 0 rgb(255, 255, 255),
            1px -1px 0 rgb(255, 255, 255),
            -1px 1px 0 rgb(255, 255, 255),
            -1px -1px 0 rgb(255, 255, 255);
        @media (max-width: 768px) {
            font-size: 23px;
            margin-top: -7px;
        }
    }
    @media (max-width: 768px) {
        width: 45%;
    }
`
